import React from 'react'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'

const lightTheme = createMuiTheme({
  palette: {
    type: 'light',
  }
})

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
  }
})

const BlockTheme = (props) => {
  const { data, children } = props
  const theme = data.color === 'dark' ? darkTheme : lightTheme
  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  )
}

export default BlockTheme
