import React from 'react'
import { Editor } from '@tinymce/tinymce-react'

const mceConfig = {
  height: 320,
  width: '100%',
  menubar: false,
  //inline: true
}


const TextBlockEdit = (props) => {
  const { data, onSave } = props

  const handleChange = event => {
    const val = event.target.getContent()
    onSave({
      ...data,
      content: val
    })
  }

  console.log(data.content)

  return (
    <Editor
      init={mceConfig}
      initialValue={data.content}
      onChange={handleChange}
      toolbar="formatselect | align | bold italic | forecolor backcolor"
    />
  )
}

export default TextBlockEdit
