import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import InputBase from '@material-ui/core/InputBase'
import Typography from '@material-ui/core/Typography'
import TablePagination from '@material-ui/core/TablePagination'
import { makeStyles } from '@material-ui/styles'
import { useRouteMatch, Link } from 'react-router-dom'
import moment from 'moment-timezone'
import Layout from '../components/Layout'
import { fetchCueStatusList } from '../api'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
  },
  input: {
    border: '1px solid rgba(0,0,0,0.23)',
    borderRadius: '5px',
    margin: '0 12px 6px 0',
    padding: '1px 12px',
  }
}))

const CueStatusList = () => {
  const [data, setData] = useState({count:0, results:[]})
  const [query,setQuery] = useState({})
  const [filter, setFilter] = useState('')
  const [page, setPage] = useState(0)
  let { url } = useRouteMatch()
  const limit = 12
  const classes = useStyles()

  useEffect(()=>{
    const fetchData = async() => {
      try {
        const res = await fetchCueStatusList(page, limit, filter)
        setData(res)
      } catch (error) {
        console.log('error')
      }
    }

    fetchData()
  }, [page, filter])

  const onChangePage = (event, newpage) => {
    setPage(newpage)
  }

  const handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    setQuery({...query, [name]:value})
  }

  const handleFilter = () => {
    const keys = Object.keys(query)
    let queryString = ''
    let i
    for(i=0; i<keys.length; i++) {
      if(query[keys[i]] !== undefined && query[keys[i]] !== '') {
        queryString += keys[i] + '=' + query[keys[i]] + '&'
      }
    }
    setFilter(queryString)
  }


  const breadcrumbs = [{name:"线索状态管理"}]

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Box display="flex" mb={2} justifyContent="space-between">
        <Typography variant="h6" noWrap component="h2">线索状态管理</Typography>
        <Button
          variant="contained"
          size="small"
          color="primary"
          component={Link}
          to={`${url}/create`}
        >添加</Button>
      </Box>
      <Box mb={2}>
        <InputBase
          name="search"
          className={classes.input}
          style={{width:'280px',maxWidth:'100%'}}
          placeholder="请输入名称"
          value={query.search}
          onChange={handleChange}
        />
        <Button
          variant="outlined"
          style={{marginRight:'6px'}}
          onClick={handleFilter}
        >查询</Button>
        <Button
          variant="outlined"
          onClick={()=>window.location.reload()}
        >重置</Button>
      </Box>
      <TableContainer>
        <Table className={classes.root}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>名称</TableCell>
              <TableCell>创建时间</TableCell>
              <TableCell align="center">操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.results.map(row => (
              <TableRow key={row.id}>
                <TableCell>#{row.id}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{moment.tz(row.created, 'Asia/Shanghai').format('YYYY/MM/DD HH:mm')}</TableCell>
                <TableCell align="center">
                  <Button
                    color="primary"
                    component={Link}
                    to={`${url}/${row.id}`}>编辑</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[limit]}
          component="div"
          count={data.count}
          rowsPerPage={limit}
          page={page}
          onChangePage={onChangePage}
        />
      </TableContainer>
    </Layout>
  )
}

export default CueStatusList
