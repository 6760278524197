import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import UserForm from '../components/UserForm'
import { fetchUserDelete, fetchUserDetail, fetchUserUpdate } from '../api'
import Filtrate from '../components/Filtrate'
import DeleteButton from '../components/DeleteButton'
import { makeStyles } from '@material-ui/styles'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  actions: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row-reverse',
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },
}))

const UserUpdate = () => {
  const { id } = useParams()
  const currentUser = localStorage.getItem('user')
  const [data, setData] = useState()
  const history = useHistory()
  const classes = useStyles()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchUserDetail(id)
        setData(res)
      } catch (error) {
        console.log('error')
      }
    }

    fetchData()
  }, [id])

  const handleChange = async (e) => {
    setData(e)
  }

  const handleSubmit = async () => {
    try {
      await fetchUserUpdate(id, data)
      alert('保存成功')
    } catch (error) {
      alert(error)
    }
  }

  const handleDelete = async () => {
    try {
      await fetchUserDelete(id)
      history.push('/user')
    } catch (error) {
      console.log(error)
      alert('操作失败')
    }
  }

  const Actions = () => (
    <Filtrate title='用户管理' otherSub={<div className={classes.actions}>
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={handleSubmit}>保存</Button>
      <Button
        component={Link}
        to={currentUser === data.username ? '/users/password' : `/password_reset/${data.id}`}
        variant="outlined"
        size="small">{currentUser === data.username ? '修改密码' : '重置密码'}</Button>
      <DeleteButton
        variant="outlined"
        onClick={handleDelete}>
        删除
		</DeleteButton></div>}></Filtrate>
  )
  return (
    <div>
      {data && (
        <UserForm
          data={data}
          formActions={<Actions />}
          onChange={handleChange} />
      )}
    </div>
  )
}

export default UserUpdate
