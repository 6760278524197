import React from 'react'
import { useRouteMatch, Link, Switch  } from 'react-router-dom'
import PrivateRoute from '../components/PrivateRoute'

import CityList from './CityList'
import CityCreate from './CityCreate'
import CityUpdate from './CityUpdate'

const City = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <CityList />
      </PrivateRoute>
      <PrivateRoute path={`${path}/create`}>
        <CityCreate />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:id`}>
        <CityUpdate />
      </PrivateRoute>
    </Switch>
  )
}

export default City
