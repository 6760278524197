import React, { useState, useEffect } from 'react'
import { fetchThemeSummary } from '../api'
import SummaryCard from './SummaryCard'
import { useHistory } from 'react-router-dom'

const ThemeSummaryCard = () => {
  const history = useHistory()
  const [data, setData] = useState()
  useEffect(() => {
    const fetchData = async() => {
      try {
        const res = await fetchThemeSummary()
        setData(res)
      } catch {
      }
    }
    fetchData()
  }, [])

  const handleClick = () => {
    history.push('/templates')
  }

  return (
    <SummaryCard
      title='模板'
      total={data ? data.total : 0}
      icon={require('../images/total_template.png')}
      color='#FFDEEC'
      onClick={handleClick}
    />
  )
}

export default ThemeSummaryCard
