import React, { useState } from 'react'
import { TextField, Button } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { fetchTemplateCreate } from '../api'
import { useHistory } from 'react-router-dom'

const TemplateCreate = () => {
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const [data, setData] = useState()

  const handleChange = event => {
    setData(event.target.value)
    //setData({...data, [event.target.name]:event.target.value})
  }
  const handleSubmit = async () => {
    try {
      const res = await fetchTemplateCreate({ 'name': data })
      history.push(`/frame-choice/${res.id}`)
    } catch {
      console.log('create templat error')
    }
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <React.Fragment>
      <Button
        variant='contained'
        disableElevation
        color="primary" onClick={handleOpen}>
        添加模板
      </Button>
      <Dialog
        fullWidth
        maxWidth="xs"
        PaperProps={{ elevation: 0 }}
        open={open}
        onClose={handleClose}>
        <DialogTitle id="form-dialog-title">新建模板</DialogTitle>
        <DialogContent>
          <DialogContentText>
            请输入模板名称, 如"2020春节"
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="name"
            fullWidth
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">取消</Button>
          <Button
            disabled={!data}
            onClick={handleSubmit}>提交</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default TemplateCreate
