import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import BlockCreate from './BlockCreate'



const useStyles = makeStyles(theme => ({
  root: {
    outlineStyle: 'dashed',
    outlineColor: theme.palette.grey[300],
    padding: theme.spacing(4),
    textAlign: 'center',
    margin: theme.spacing(2, 0)
  },
  svg: {
    width: 120,
    height: 60,
    margin: theme.spacing(0, 2),
    fill: theme.palette.grey[300],
    '&:hover': {
      fill: theme.palette.grey[400]
    }
  }
}))

const NewBlock = (props) => {
  const classes = useStyles()
  const { id, mode, onAdd, onSave, ...other } = props

  const layoutList = {
    '12': {
      'layout':'12', 'elements':[{}], 'width':'default'
    },
    '6-6': {
      'layout':'6-6', 'elements':[{}, {}], 'width':'default'
    }
  }


  const handleChoice = (layout) => {
    console.log('layoutlist', layoutList)
    if(id !== undefined) {
      // 如果是普通添加的空白区块
      console.log({id:id, ...layoutList[layout]})
      onSave({id:id, ...layoutList[layout]})
    } else{
      // 如果是底部
      onAdd('down', layoutList[layout])
    }
  }


  return (
    <Container
      maxWidth={mode === 'pc'? 'md':'sm'}
      style={{padding:0}}
      {...other}>
      <div className={classes.root}>
        <Typography
          variant="body1"
          component="h5"
          gutterBottom>添加区块</Typography>
        <Box
          display="flex"
          justifyContent="center"
          mt={3}
          mb={2}>
          <svg
            className={classes.svg}
            onClick={()=>handleChoice('12')}
            viewBox="0 0 100 50">
            <path d="M100,0V50H0V0Z"></path></svg>
          <svg
            className={classes.svg}
            onClick={()=>handleChoice('6-6')}
            viewBox="0 0 100 50">
            <path d="M49,0V50H0V0Z M100,0V50H51V0Z"></path></svg>
        </Box>
      </div>
    </Container>
  )
}

export default NewBlock
