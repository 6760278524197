import React from 'react'
import Layout from '../components/Layout'
import { useRouteMatch, Switch } from 'react-router-dom'
import PrivateRoute from '../components/PrivateRoute'

import MediumList from './MediumList'
import MediumCreate from './MediumCreate'
import MediumUpdate from './MediumUpdate'

const Medium = () => {
  const { path } = useRouteMatch()
  const breadcrumbs = {
    list: [
      { name: '媒体管理' }
    ],
    create: [
      { name: '媒体管理', link: `${path}` },
      { name: '添加' },
    ],
    update: [
      { name: '媒体管理', link: `${path}` },
      { name: '详情' },
    ]
  }
  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <MediumList />
      </PrivateRoute>
      <PrivateRoute path={`${path}/create`}>
        <Layout breadcrumbs={breadcrumbs.create}>
          <MediumCreate />
        </Layout>
      </PrivateRoute>
      <PrivateRoute path={`${path}/:id`}>
        <MediumUpdate />
      </PrivateRoute>
    </Switch>
  )
}

export default Medium
