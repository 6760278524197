import React, { useEffect, useState } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TablePagination from '@material-ui/core/TablePagination'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import InputBase from '@material-ui/core/InputBase'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/styles'
import { Link, useRouteMatch } from 'react-router-dom';

import Layout from '../components/Layout'
import { fetchCities, fetchDealerList } from '../api';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
  },
  input: {
    // border: '1px solid rgba(0,0,0,0.23)',
    borderRadius: '5px',
    margin: '0 12px 6px 0',
    padding: '1px 12px',
  },
  search: {
    backgroundColor: '#3859FF',
    borderColor: '#3859FF',
    color: '#FEFEFF',
    width: '280px',
    maxWidth: '100%'
  },
  city: {
    backgroundColor: '#FFEBF3',
    borderColor: '#FFEBF3',
    color: '#FF3B7C'
  },
  button: {
    borderWidth:1,
    padding: '4px 12px'
  }
}))


const DealerList = () => {
  const [data, setData] = useState({ count: 0, results: []})
  const [query,setQuery] = useState({})
  const [filter, setFilter] = useState('')
  const [page,setPage] = useState(0)
  const [cities, setCities] = useState([])
  const classes = useStyles()
  const { url } = useRouteMatch()
  const limit = 10

  useEffect(() => {
    const fetchData = async() => {
      try {
        const res = await fetchCities(0, 50)
        setCities(res.results)
      } catch {}
    }
    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchDealerList(page, limit, filter)
        setData(res)
      } catch (error) {
        console.log('error', error)
      }
    }
    fetchData()
  }, [page, limit, filter])

  const onChangePage = (event, newpage) => {
    setPage(newpage)
  }

  const handleChange = (event, name=null) => {
    if(!name) {
      name = event.target.name
    }
    const value = event.target.value
    setQuery({...query, [name]:value})
  }

  const handleFilter = () => {
    const keys = Object.keys(query)
    let queryString = ''
    let i
    for(i=0; i<keys.length; i++) {
      if(query[keys[i]] !== undefined && query[keys[i]] !== '') {
        queryString += keys[i] + '=' + query[keys[i]] + '&'
      }
    }
    setFilter(queryString)
  }

  const breadcrumbs = [
    { name: '经销商' },
  ]

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Box display="flex" mb={2} justifyContent="space-between">
        <Typography variant="h6" noWrap component="h2">经销商</Typography>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={`${url}/create`}
        >添加经销商</Button>
      </Box>
      <Box mb={2}>
        <InputBase
          name="search"
          className={`${classes.input} ${classes.search}`}
          placeholder="请输入名称"
          value={query.search}
          onChange={handleChange}
        />
        <Select
          displayEmpty
          value={query.city}
          onChange={(event)=>handleChange(event, "city")}
          input={<InputBase 
            className={`${classes.input} ${classes.city}`} />}>
          <MenuItem>全部城市</MenuItem>
          {cities.map(row => (
            <MenuItem value={row.id} key={row.id}>{row.name}</MenuItem>
          ))}
        </Select>

        <Button
          variant="outlined"
          size='small'
          className={`${classes.button}`}
          variant="outlined"
          color="primary"
          style={{marginRight:'6px'}}
          onClick={handleFilter}
        >查询</Button>
        <Button
          variant="outlined"
          size='small'
          className={`${classes.button}`}
          variant="outlined"
          color="primary"
          onClick={()=>window.location.reload()}
        >重置</Button>
      </Box>

		  <TableContainer className={classes.root}>
        <Table>
          <TableHead>
            <TableRow>
              {/* <TableCell>ID</TableCell> */}
              <TableCell>用户信息</TableCell>
              <TableCell>登录账户</TableCell>
              <TableCell>城市信息</TableCell>
              <TableCell>电话</TableCell>
              <TableCell>单位信息</TableCell>
              <TableCell align="center">操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.results.map(row => (
              <TableRow key={row.id}>
                {/* <TableCell>#{row.id}</TableCell> */}
                <TableCell>
                  <div>{row.name}</div>
                  <div>{row.number}</div>
                </TableCell>
                <TableCell>{row.user.username}</TableCell>
                <TableCell>
                  <div>{row.city}</div>
                  <div>{row.zone}</div>
                </TableCell>
                <TableCell>{row.phone}</TableCell>
                <TableCell>
                  <div>{row.company}</div>
                  <div>{row.address}</div>
                  </TableCell>
                <TableCell align="center">
                  <Button
                    color="primary"
                    size="small"
                    component={Link}
                    to={`${url}/${row.id}`}
                  >编辑</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={data.count}
          rowsPerPage={10}
          page={page}
          onChangePage={onChangePage}
        />
		  </TableContainer>
    </Layout>
  )
}

export default DealerList
