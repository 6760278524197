import React from 'react'
import { makeStyles, Button, Typography, IconButton, Input, CardMedia, Popover } from '@material-ui/core'
import QRCode from 'qrcode.react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
const useStyles = makeStyles(theme => ({
	box: {
		overflow: 'hidden',
		display: 'flex',
		flexDirection: 'row',
		marginBottom: 20,
		flexWrap:'wrap',
		paddingTop:5
	},
	search: {
		width: 200,
		height: 30,
		margin: 5,
		marginRight: 15,
		marginLeft:0,
		display: 'flex',
		flexDirection: 'row',
		borderRadius: 10,
		overflow: 'hidden',
		backgroundColor: theme.palette.primary.main,
		'& input': {
			paddingLeft: 19,
			paddingRight: 19,
			color: '#ffffff',
			flex: 1,
			fontSize: 14,
			'& placholder': {
				color: 'red'
			}
		}
	},
	button: {
		height: 30,
		borderRadius: 10,
		padding: '0 10px',
		backgroundColor: theme.palette.primary.light
	},
	title: {
		fontSize: 18,
		lineHeight: 2.25,
		marginRight:40
	},
	rightBtn: {
		margin: 5,
		borderRadius: 5,
		lineHeight: 1.25,
		padding: 6,
		paddingLeft:20,
		paddingRight:20
	},
	codeImg: {
		margin: 20,
		marginLeft:40,
		marginRight:40,
		border:'10px solid #263b59',
		padding:10
	},
	codeBtn:{
		display:"block",
		margin:'auto',
		height:30,
		width:100,
		backgroundColor:'#BCF3FF',
		color:'#16B6FF',
	},
	codeText:{
		width:210,
		margin:20,
		marginTop:30,
		overflow:'hidden',
		textDecoration:'none',
		display:'block',
	}
}))
const Filtrate = (props) => {
	const classes = useStyles()
	const [data, setData] = React.useState()
	const [codeEl, setCodeEl] = React.useState()
	let { title, 
			handleSearch, 
			screening, 
			create, 
			sumbitText,
			otherSub, 
			addTitle, code } = props
	const onChange = (e) => {
		if (e.target.name === 'search') {
			setData(e.target.value)
		} else {
			handleSearch(data)
		}
	}
	const handleClick = event => {
		setCodeEl(event.currentTarget);
	};

	const handleClose = () => {
		setCodeEl(null);
	};
	const open = Boolean(codeEl);
	const id = 'simple-popovers'
	const downLoad=()=>{
		console.log(window.origin + code);
		var Qr=document.getElementById('qrcode');  
		let image = new Image();
		image.src = Qr.toDataURL("image/png");
		let a = document.createElement('a')
    let event = new MouseEvent('click')
    a.download = 'code'
    a.href = image.src 
    
    a.dispatchEvent(event)
	}
	return (
		<div className={classes.box}>
			<Typography className={classes.title}>{title}</Typography>
			{handleSearch ? <div
				className={classes.search}>
				<Input
					name={'search'}
					disableUnderline
					fullWidth
					size={'small'}
					onChange={onChange}
					placeholder={'搜索'}
					variant="outlined" />
				<IconButton onClick={onChange} className={classes.button}>
					<CardMedia
						style={{ height: 20, width: 20 }}
						image={require('../images/search.png')} />
				</IconButton>
			</div> : null}
			{screening}
			<div style={{ flex: 1 }}></div>
			{create ?
				<Button
					// size='small'
					className={classes.rightBtn}
					variant="contained"
					disableElevation
					color="primary"
					onClick={create}>{addTitle}
					{/* <CardMedia
						style={{ height: 16, width: 16, marginTop: -2, marginLeft: 5 }}
						image={require('../images/add.png')} /> */}
				</Button> : null}
			{code ?
				<div>
					<Button
						size='small'
						aria-describedby={id}
						onClick={handleClick}
						style={{ padding: 5, width: 30, height: 30, minWidth: 0, margin: 5 }}>
						<CardMedia style={{ height: 20, width: 20 }} image={require('../images/code.png')}></CardMedia>
					</Button>
					<Popover
						id={id}
						open={open}
						anchorEl={codeEl}
						onClose={handleClose}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
					>
						<QRCode id='qrcode' className={classes.codeImg} value={window.origin + code}></QRCode>
						<Button className={classes.codeBtn} onClick={downLoad}>下载二维码</Button>
						<Typography className={classes.codeText} 
							component={Link} 
							to={code}>{window.origin + code}</Typography>
					</Popover></div> : null}
			{otherSub}
			{sumbitText ?
				<Button
					type='submit'
					size='small'
					className={classes.rightBtn}
					variant="contained"
					color="primary"
					disableElevation
					onClick={create}>
					{sumbitText}
				</Button> : null}
		</div>
	)
}

export default Filtrate
