import React, { useState, useEffect } from 'react'
import { fetchCueSummary } from '../api'
import SummaryCard from './SummaryCard'
import { useHistory } from 'react-router-dom'

const CueSummaryCard = () => {
  const history = useHistory()
  const [data, setData] = useState()
  useEffect(() => {
    const fetchData = async() => {
      try {
        const res = await fetchCueSummary()
        setData(res)
      } catch {
      }
    }
    fetchData()
  }, [])

  const handleClick = () => {
    history.push('/cue')
  }

  return (
    <SummaryCard
      title='本月线索'
      total={data ? data.this_month : 0}
      icon={require('../images/total_cue.png')}
      extra={data && `上月${data.last_month}, 增幅${data.rise}`}
      color='#D4F4EF'
      onClick={handleClick}
    />
  )
}

export default CueSummaryCard
