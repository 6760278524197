import React from 'react'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'

const EmptyBlockEdit = (props) => {
  const { data, onSave } = props

  const handleChange = (event) => {
    let styles = {}
    if(data.styles) {
      styles = {...data.styles}
    }
    styles[event.target.name] = event.target.value
    onSave({...data, styles})
  }

  return (
    <Box flex={1}>
      <TextField
        fullWidth
        margin="normal"
        name="height"
        value={data.styles ? data.styles.height : "0"}
        label="高度"
        onChange={handleChange}
      />
    </Box>
  )
}

export default EmptyBlockEdit
