import React, { useState } from 'react'
import { Button, Box, FormGroup, FormControlLabel, Switch, TextField, ExpansionPanel, ExpansionPanelSummary, Typography, Grid } from '@material-ui/core'

const UserForm = (props) => {
  const [data, setData] = useState(props.data)
  const { onChange, formActions } = props
  const handleChange = event => {
    const name = event.target.name
    let value
    if(name === 'is_active' || name === 'is_staff') {
      value = event.target.checked
    } else {
      value = event.target.value
    }
    setData({...data, [name]:value})
    onChange({...data, [name]:value})
  }
  return (
    <div>
      {formActions}
      <ExpansionPanel elevation={0} expanded={true}>
        <ExpansionPanelSummary>
          <Typography style={{ fontSize: 18 }}>基础信息</Typography>
        </ExpansionPanelSummary>
        <Box px={4} py={2}>
          <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <TextField
              name="username"
              variant="outlined"
              size="small"
              label="用户名"
              value={data ? data.username : ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="邮箱"
              name="email"
              type="email"
              value={data ? data.email : ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              label="真实姓名"
              name="first_name"
              value={data ? data.first_name : ''}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormGroup>
              <FormControlLabel
                label="激活状态"
                control={
                  <Switch
                    name="is_active"
                    checked={data ? data.is_active : false}
                    onChange={handleChange}
                    value={data ? data.is_active : false}
                  />
                }
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={12}>
            <FormGroup>
              <FormControlLabel
                label="内部员工"
                control={
                  <Switch
                    name="is_staff"
                    checked={data ? data.is_staff : false}
                    onChange={handleChange}
                    value={data ? data.is_staff : false}
                  />
                }
              />
            </FormGroup>
          </Grid>
          {/**<Grid item xs={12} sm={12}>
            <TextField
              margin="normal"
              variant="outlined"
              size="small"
              name="groups"
              label="分组"
              value={data ? data.groups : ''}
              onChange={handleChange}
            />
            </Grid>**/}
        </Grid>
      {/* <Button
        color="primary"
        variant="contained"
        onClick={()=>onSubmit(data)}>保存</Button> */}
      </Box>
      </ExpansionPanel>
    </div>
  )
}

export default UserForm
