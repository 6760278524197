import React, { useState } from 'react'
import { Grid, Checkbox, TableSortLabel, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Typography, TablePagination, Card, makeStyles } from '@material-ui/core'
import { useRouteMatch, Link } from 'react-router-dom'
import moment from 'moment-timezone'

const useStyles = makeStyles(theme => ({
	tableBox: {
		marginBottom: 20,
		borderRadius: 10
	},
	tableHeadText: {
		textAlign: 'center',
		fontSize: 14,
		color: '#666666',
		height: 60,
		padding: 0,
		lineHeight: 2.4
	},
	tableBodyText: {
		position: 'relative',
		fontSize: 14,
		height: 60,
		textAlign: 'center',
		padding: 0,
		minWidth: 100
	},
	tableBodya: {
		color: theme.palette.primary.main,
		textDecoration: 'none',
		margin: '0 5px',
		padding: 5
	},
	dataStyle: {
		fontSize: 10,
		textAlign: 'center',
		flex: 1,
		borderRight: '0.02rem solid rgb(128, 128, 128)',
		position: 'relative',
		height: '100%',
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center'
	},
	submitBtn: {
		height: 30,
		marginTop: 15,
		marginBottom: 15,
		borderRadius: 10,
		color: theme.palette.blue.main,
		backgroundColor: theme.palette.blue.back,
		marginLeft: 20,
		fontSize: 16,
		padding: 0,
		paddingLeft: 20,
		paddingRight: 20
	}
}))
const Tables = (props) => {
	const { exportLink } = props
	const [page, setPage] = useState(0)
	const [selected, setSelected] = useState([])
	const [ordering, setOrdering] = useState('-created')
	const classes = useStyles()
	const limit = 10
	let { url } = useRouteMatch()
	const {
		data = { count: 0, results: [] },
		headCells,
		rightBtn = null,
		handle = {},
		setPages = () => { },
		setOrderings = null,
		noHandle = false,
		exports = false
	} = props
	const onChangePage = (event, newpage) => {
		setPages(newpage)
		setPage(newpage)
	}

	const handleSelectAll = event => {
		if (event.target.checked) {
			setSelected(data.results.map(item => item.id))
			return
		}
		setSelected([])
	}

	const handleClick = (event, id) => {
		if (selected.includes(id)) {
			setSelected(selected.filter(item => item !== id))
		} else {
			setSelected([...selected, id])
		}
	}

	const handleChangeOrdering = () => {
		setSelected([])
		if (ordering === '-created') {
			setOrdering('created')
			setOrderings('created')
		} else {
			setOrdering('-created')
			setOrderings('-created')
		}
	}
	const getTimeLength = (item) => {
		let month = item.label.substring(2, item.label.length - 1)
		var date = new Date();
		var year = date.getFullYear();
		var d = new Date(year, month, 0);
		let list = []
		let length = d.getDate()
		for (let i = 0; i < length; i++) {
			let tim = d.setDate(i + 1)
			let weeks = new Date(tim).getDay()
			item.begin > i ? list.push([null, weeks]) : list.push([item.begin + i, weeks])
		}
		return list
	}
	const forItem = (item) => {
		let list = getTimeLength(item)
		return (
			<div style={{ display: 'flex', flexDirection: 'row', borderTop: '1px solid rgb(128, 128, 128)', borderRight: '1px solid rgb(128, 128, 128)', width: 15 * list.length }}>
				{list.map((v, i) => (
					<span style={{
						fontSize: 10, textAlign: 'center', flex: 1, borderLeft: '1px solid rgb(128, 128, 128)', height: 26,
						backgroundColor: (v[1] === 6 || v[1] === 0) && '#f7f7fa'
					}}
						key={i}>{i + item.begin}</span>
				))}
			</div>
		)
	}

	const Ite = ({ tab, v }) => {
		let it = null
		tab.map((vals, j) => {
			if (parseFloat(vals.date.substring(6, 8)) === v[0]) {
				it = <span key={j} style={vals.type === 1 ? { color: 'red' } : { color: 'blue' }}>1</span>
			}
			return j
		})
		return it
	}

	return (
		<Card elevation={0} className={classes.tableBox}>
			<TableContainer>
				<Table>
					{/* 表格头部 */}
					<TableHead>
						<TableRow>
							<TableCell padding="checkbox" className={classes.tableHeadText}>
								<Checkbox
									color="primary"
									onClick={handleSelectAll}
								/>
							</TableCell>
							{headCells && headCells.map((item) => (
								<TableCell key={item.id} className={classes.tableHeadText}>
									{item.id === 'created' && setOrderings ?
										<TableSortLabel active
											direction={ordering === '-created' ? 'desc' : 'asc'}
											onClick={handleChangeOrdering}
										>
											{item.label}
										</TableSortLabel> : item.label}
									{item.begin ? forItem(item) : null}
								</TableCell>
							))}
							{noHandle ? null : <TableCell className={classes.tableHeadText}>操作</TableCell>}
						</TableRow>
					</TableHead>
					{/* 数据展示部分 */}
					<TableBody>
						{data.results.map((row, index) => (
							<TableRow hover
								key={row.id}
								onClick={event => handleClick(event, row.id)}
								aria-checked={selected.includes(row.id)}
								selected={selected.includes(row.id)}
								role="checkbox">
								<TableCell padding="checkbox" className={classes.tableBodyText}>
									<Checkbox
										color="primary"
										onClick={event => handleClick(event, row.id)}
										checked={selected.includes(row.id)} />
								</TableCell>
								{headCells.map((items) => {
									let tab = []
									// 点位计划时间显示
									if (items.id === 'row' || items.id === 'row2') {
										tab = items.id === 'row' ? row.dates[0] : row.dates[1]
										let list = getTimeLength(items)

										return (
											<TableCell key={items.id} className={classes.tableBodyText}>
												<div style={{
													display: 'flex', flexDirection: 'row',
													position: 'absolute', width: '100%', height: '100%', top: 0
												}}>
													{list.map((v, i) => {
														return (
															<span className={classes.dataStyle}
																style={{
																	borderLeft: !i && '1px solid rgb(128, 128, 128)',
																	backgroundColor: (v[1] === 6 || v[1] === 0) && '#f7f7fa'
																}}
																key={i}>
																<Ite tab={tab} v={v} />
															</span>
														)
													})}
												</div>
											</TableCell>
										)
									} else {
										return (
											<TableCell
												className={classes.tableBodyText}
												key={items.id}>{
													// 时间
													items.id === 'created' || items.id === 'date_joined' ? moment.tz(row[items.id], 'Asia/Shanghai').format('YYYY/MM/DD HH:mm') :
														// 车型
														items.id === 'cars' || items.id === 'groups' ? row[items.id].map((it, i) => (
															<span key={i}>{it}{i + 1 === row[items.id].length ? '' : '、'}</span>
														)) :
															// 报名信息
															items.id === 'message' ?
																<div style={{ padding: '5px 0' }}>
																	<div>{row.name}</div>
																	<div>{row.city}</div>
																	<div>{row.mobile}</div>
																</div> :
																// 投放计划
																items.id === 'plans' ?
																	<div style={{ padding: '5px 0' }}>
																		<div>{row.medium}</div>
																		<div>{row.medium_style}</div>
																	</div>
																	// { id: 'mobile', label: '手机号' },
																	// { id: 'city', label: '城市' },
																	: `${(items.child ?
																		(row[items.id] ? row[items.id][items.child] : row[items.id]) :
																		row[items.id] ? row[items.id] : '---'
																	)}`
												}</TableCell>
										)
									}
								})}
								{noHandle ? null : <TableCell className={classes.tableBodyText}>
									<Typography
										className={classes.tableBodya}
										onClick={
											rightBtn ?
												(e) => { rightBtn(row.id); e.stopPropagation(); } :
												null
										}
										component={rightBtn ? 'span' : Link}
										to={rightBtn ? null : `${url}/${row.id}`}>查看</Typography>
									{handle.title && (handle.url || row.theme) && <Typography
										className={classes.tableBodya}
										component={Link}
										to={handle.path ? row.link : `${handle.url}/${row.id}`}>{handle.title}</Typography>}
								</TableCell>}
							</TableRow>
						))}
					</TableBody>
				</Table>
				{/* 操作按钮部分 */}
				<Grid container justify="space-between" alignItems="center">
					<Grid item xs style={{ height: 60 }}>
						{selected.length ? (
							<form method="POST" action={exportLink}>
								<input name="ids" type="hidden" value={selected.join(',')} />
								{/* <Button className={classes.submitBtn}>删除</Button> */}
								{exports && <Button
									type="submit"
									className={classes.submitBtn}
								>导出</Button>}
							</form>
						) : null}
					</Grid>
					<Grid item xs>
						<TablePagination
							rowsPerPageOptions={[limit]}
							component="div"
							count={data.count}
							rowsPerPage={limit}
							page={page}
							onChangePage={onChangePage}
						/>
					</Grid>
				</Grid>
			</TableContainer >
		</Card>
	)
}

export default Tables
