/**
 * 列表相关
 */

/**
 * 1维数据拆分为2维
 */
export const sliceArray = (data, length=7) => {
  let x, y
  let results = []
  for(x=0, y=data.length;x<y;x+=length) {
    results.push(data.slice(x, x+length))
  }
  return results
}
