import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useParams, useRouteMatch, useHistory } from 'react-router-dom'
import { fetchPlanDetail, fetchPlanItemCreate, fetchPlanItemUpdate, fetchPlanItemDetail, fetchPlanItemDelete } from '../api'
import { useForm } from 'react-hook-form'
import Filtrate from '../components/Filtrate';
import { RenderBasic, RenderArea, RenderSuperior, RenderTime } from '../components/PointForm'
import DeleteButton from '../components/DeleteButton'


const RenderHead = ({ code, handleDelete }) => {
  return (
    <Filtrate title='点位计划' code={code}
      otherSub={<>
        <DeleteButton
          variant="outlined"
          style={{
            margin: 5,
          }}
          onClick={handleDelete}>
          删除
		</DeleteButton></>} sumbitText='保存'></Filtrate>
  )
}
const PointUpdate = () => {
  const { register, errors, handleSubmit, control } = useForm()
  const [detail, setDetail] = useState(null)
  const [planDetail, setPlanDetail] = useState(null)
  const [selects, setSelects] = useState({})
  const { id } = useParams()
  const history = useHistory()
  const { path } = useRouteMatch()
  let planId = id ? path.slice(12, path.length - 4) : path.slice(12, path.length - 7)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchPlanDetail(planId)
        setDetail(res)
        if (id) {
          const planDetail = await fetchPlanItemDetail(id)
          setPlanDetail(planDetail)
        }
      } catch (error) {
        setPlanDetail({})
        console.error(error)
      }
    }

    fetchData()
  }, [id, planId])

  const onSubmit = async (data) => {
    let extra = { ...data }
    console.log(detail);
    const form = {
      plan: detail.number,
      position: selects.position,
      style: selects.style,
      cars: selects.cars,
      extra: extra
    }
    if (id) {      //更新
      try {
        await fetchPlanItemUpdate(id, form)
        history.goBack()
      } catch (error) {
        console.error(error)
      }
    } else {      //新建
      try {
        await fetchPlanItemCreate(form)
      } catch (error) {
        console.error(error)
      }
    }

  }
  const getTimeData = (times) => {
    console.log(times);
  }
  const handleDelete =async () => {
    try {
      await fetchPlanItemDelete(id)
      history.goBack()
    } catch (error) {
      console.error(error)
    }
  }
  console.log(planDetail);
  return (
    <Grid container>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <RenderHead code={planDetail && planDetail.theme ? planDetail.link : ''}
          handleDelete={handleDelete} />
        {planDetail ? <>
          <RenderBasic
            detail={detail}
            planDetail={planDetail}
            setPlanDetail={setPlanDetail}
            control={control}
            selects={selects}
            register={register}
            errors={errors}
            setSelects={setSelects} />
          {detail ? (detail.is_zone ? <RenderArea register={register} errors={errors} planDetail={planDetail} /> : <RenderSuperior register={register} errors={errors} planDetail={planDetail} />) : null}
          {/* <RenderTime getTimeData={getTimeData} /> */}
        </> : null}
      </form>
    </Grid>
  )
}

export default PointUpdate
