import React from 'react'
import { useRouteMatch, Link, Switch  } from 'react-router-dom'
import PrivateRoute from '../components/PrivateRoute'

import TipList from './TipList'
import TipCreate from './TipCreate'
import TipUpdate from './TipUpdate'

const Tip = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <TipList />
      </PrivateRoute>
      <PrivateRoute path={`${path}/create`}>
        <TipCreate />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:id`}>
        <TipUpdate />
      </PrivateRoute>
    </Switch>
  )
}

export default Tip
