import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import { withStyles } from '@material-ui/core/styles'

const ExpansionPanel = withStyles({
  root: {
    boxShadow: 'none',
    borderBottom: '1px solid rgba(0,0,0,0.18)',
    '&$expanded': {
      marginTop: 0,
      marginBottom: 0
    },
    '&:before': {
      display: 'none'
    }
  },
  expanded: {
  }
})(MuiExpansionPanel)

export default ExpansionPanel
