import React from 'react'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Radio from '@material-ui/core/Radio'

const PlanTypeField = (props) => {
  const { disabled, value } = props
  if (disabled) {
    return (
      <TextField
        disabled
        size='small'
        label="投放类型"
        variant='outlined'
        value={value === 'true' ? '区域计划' : '总部计划'} />
    )
  }
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">投放类型</FormLabel>
      <RadioGroup
        row
        aria-label="plantype"
        name="is_zone"
        {...props}>
        <FormControlLabel
          value="true"
          control={<Radio />}
          label="区域计划" />
        <FormControlLabel
          value="false"
          control={<Radio />}
          label="总部计划" />
      </RadioGroup>
    </FormControl>
  )
}

export default PlanTypeField
