import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import moment from 'moment-timezone'

const FrameForm = (props) => {
  const { data, errors, onChange } = props
  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          name="name"
          variant="outlined"
          size="small"
          disabled
          value={data.name ? data.name : ""}
          onChange={onChange}
          margin="normal"
          label="名称"
          error={errors.name}
          helperText={errors.name ? errors.name.message : null}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          rows={3}
          variant="outlined"
          size="small"
          disabled
          value={data.description ? data.description : ""}
          margin="normal"
          label="描述"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          name="tags"
          multiline
          rows={3}
          variant="outlined"
          size="small"
          disabled
          value={data.tags ? data.tags.join(",") : ""}
          onChange={onChange}
          margin="normal"
          label="标签"
          error={errors.tags}
          helperText={errors.tags? errors.tags.message : null}
        />
      </Grid>
      <Grid item xs={7}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          disabled
          value={data.created ? moment(data.created).tz("Asia/Shanghai").format("YYYY-MM-DD HH:mm") : "-"}
          margin="normal"
          label="创建时间"
        />
      </Grid>
      <Grid item xs={7}>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          disabled
          value={data.updated ? moment(data.updated).tz("Asia/Shanghai").format("YYYY-MM-DD HH:mm") : "-"}
          margin="normal"
          label="更新时间"
        />
      </Grid>
    </Grid>
  )
}

export default FrameForm
