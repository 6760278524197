import React, { useState, useEffect } from 'react'
import { Typography, TextField, Grid, ExpansionPanelSummary, ExpansionPanel, FormControl, InputLabel, Select, MenuItem, Button } from '@material-ui/core'
import { fetchCities } from '../api'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
const Inputs = ({ type, name, initData, register, helperText = null, errors, multiline = false, xs = [12, 12], disabled = false ,required}) => (
  <Grid item xs={xs[1]} sm={xs[0]} style={{ padding: 20, paddingTop: 0 }}>
    <TextField
      name={type}
      label={name}
      disabled={disabled}
      multiline={multiline}
      rows={multiline ? 4 : 1}
      size='small'
      fullWidth
      variant="outlined"
      required={required}
      defaultValue={initData && (
        initData[type] ? (
          type === 'user' ?
            initData[type].username :
            initData[type]
        ) : (initData.extra[type]?initData.extra[type]:''))}
      inputRef={register({})}
      error={Boolean(errors[type])}
      helperText={errors[type] ? errors[type].message : helperText} />
  </Grid>
)
const Selects = ({ type, label, useName = false, defaultValue = [], multiple = false, id, name, options = [], onChange }) => {
  const [personName, setPersonName] = useState('');
  const handleChange = event => {
    setPersonName(event.target.value)
    onChange(type, event.target.value)
  };
  return (
    <FormControl
      fullWidth
      variant="outlined"
      size='small'>
      <InputLabel style={{ background: 'white', padding: '0 2px' }}>{label}</InputLabel>
      <Select
        multiple={multiple}
        style={{ height: 37.28 }}
        value={personName ? personName : defaultValue}
        onChange={handleChange}
      >
        {options.map(item => (
          <MenuItem key={item[id]} value={useName ? item[name] : item[id]}>
            {item[name]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

const UserFrom = ({ initData, register, errors }) => {
  if (!initData) {
    return (
      <Grid container item xs={12}>
        <Inputs type='username' name='用户名' required xs={[4, 12]} initData={initData} register={register} errors={errors}></Inputs>
        <Inputs type='password' name='密码' required xs={[4, 12]} initData={initData} register={register} errors={errors}></Inputs>
      </Grid>
    )
  }
  else {
    return (
      <Grid container item xs={12}>
        <Inputs type='user' name='用户' disabled={true} xs={[4, 12]} initData={initData} register={register} errors={errors}></Inputs>
        <Button component={Link} to={`/password_reset/${initData.user.id}`} variant='text' color="primary" style={{ height: 37.28 }}>重置密码</Button>
      </Grid>
    )
  }
}

export const DealerForm = (props) => {
  const { initData, onSubmit, onChangeText, formActions } = props
  const { register, errors, handleSubmit } = useForm()
  const [citiesItem, setCities] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const cities = await fetchCities(0, null)
        console.log(cities);
        setCities(cities.results)
      } catch (error) {
        console.log(error);
      }
    }
    fetchData()
  }, [])
  const onChangeSelect = (e, v) => {
    onChangeText(e, v);
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {formActions}
      <ExpansionPanel expanded={true} elevation={0}>
        <ExpansionPanelSummary>
          <Typography style={{ fontSize: 18 }}>账号信息</Typography>
        </ExpansionPanelSummary>
        <Grid container item xs={12} sm={10}>
          <UserFrom initData={initData} register={register} errors={errors} />
        </Grid>
      </ExpansionPanel>
      <ExpansionPanel expanded={true} elevation={0} style={{ marginBottom: 20 }}>
        <ExpansionPanelSummary>
          <Typography style={{ fontSize: 18 }}>基础信息</Typography>
        </ExpansionPanelSummary>
        <Grid container item xs={12} sm={10}>
          <Inputs type='name' name='名称' required xs={[4, 12]} initData={initData} register={register} errors={errors}></Inputs>
          <Grid item xs={12} sm={4} style={{ padding: 20, paddingTop: 0 }}>
            <Selects
              type={'city'}
              label='城市*'
              id='id'
              name='name'
              useName={true}
              defaultValue={initData && initData.city}
              onChange={onChangeSelect}
              options={citiesItem}></Selects>
          </Grid>
          <Inputs type='zone' name='区域' xs={[4, 12]} initData={initData} register={register} errors={errors}></Inputs>
          <Inputs type='phone' name='电话号码' xs={[4, 12]} initData={initData} register={register} errors={errors}></Inputs>
          <Inputs type='address' name='详细地址' xs={[4, 12]} initData={initData} register={register} errors={errors}></Inputs>
          <Inputs type='company' name='单位信息' xs={[4, 12]} initData={initData} register={register} errors={errors}></Inputs>
          <Inputs type='contact1' name='第一联系人' xs={[4, 12]} initData={initData} register={register} errors={errors}></Inputs>
          <Inputs type='contact2' name='第二联系人' xs={[4, 12]} initData={initData} register={register} errors={errors}></Inputs>
          <Inputs type='contact3' name='第三联系人' xs={[4, 12]} initData={initData} register={register} errors={errors}></Inputs>
          <Inputs type='number' name='网络代码' xs={[4, 12]} initData={initData} register={register} errors={errors}></Inputs>
          <Inputs type='sort' name='排序' xs={[4, 12]} initData={initData} register={register} errors={errors}></Inputs>
        </Grid>
      </ExpansionPanel>
    </form>
  )
}
