import React from 'react'
import Typography from '@material-ui/core/Typography'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'

const themes = [
  ["default", "默认"],
  ["redone", "Redone"],
]

const FormBlockEdit = (props) => {
  const { data, onSave } = props

  const handleChange = event => {
    let styles = {}
    if(data.styles) {
      styles = {...data.styles}
    }
    styles[event.target.name] = event.target.value
    onSave({...data, styles})
  }

  return (
    <div>
      <Typography
        variant="h5"
        component="h5"
        gutterBottom>报名表单</Typography>
      <FormControl component="fieldset" margin="normal">
        <FormLabel component="legend">主题</FormLabel>
        <RadioGroup
          row
          name="theme"
          aria-label="style-theme"
          value={data && data.styles && data.styles.theme ? data.styles.theme : "default"}
          onChange={handleChange}>
          {themes.map(row => (
            <FormControlLabel
              key={row[0]}
              value={row[0]}
              control={<Radio />}
              label={row[1]} />
          ))}
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset" margin="normal">
        <FormLabel component="legend">悬浮导航按钮</FormLabel>
        <RadioGroup
          row
          aria-label="speedbutton"
          name="speedbutton"
          value={data && data.styles && data.styles.speedbutton ? data.styles.speedbutton : "null"}
          onChange={handleChange}>
          <FormControlLabel
            value="null"
            control={<Radio />}
            label="无" />
          <FormControlLabel
            value="white01"
            control={<Radio />}
            label="样式一" />
        </RadioGroup>
      </FormControl>
    </div>
  )
}

export default FormBlockEdit
