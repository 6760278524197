import React, { useState, useEffect } from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { fetchCueLogList } from '../api'
import moment from 'moment-timezone'

const CueLogs = (props) => {
  const { id } = props
  const [data, setData] = useState([])

  useEffect(() => {
    const fetchData = async() => {
      const res = await fetchCueLogList(0, 50, 'cue='+id)
      setData(res.results)
    }
    fetchData()
  }, [id])

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>名称</TableCell>
          <TableCell>时间</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(row => (
          <TableRow key={row.id}>
            <TableCell>{row.name}</TableCell>
            <TableCell>{moment(row.created).tz('Asia/Shanghai').format('YYYY/MM/DD HH:mm')}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default CueLogs
