import React from 'react'

const GridBlockEdit = (props) => {
  const { data } = props
  return (
    <div>
      <p>2列</p>
    </div>
  )
}

export default GridBlockEdit
