import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import { fetchPlanItemDelete } from '../api'

const PlanItemDelete = (props) => {
  const { id, open, onClose } = props
  const handleDelete = async() => {
    try {
      await fetchPlanItemDelete(id)
      onClose()
    } catch {
      alert('删除失败')
    }
  }
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      PaperProps={{ elevation: 0 }}
      onClose={onClose}>
      <DialogTitle>确定删除?</DialogTitle>
      <DialogContent>
        <DialogContentText>删除后无法恢复</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
        >
          取消
        </Button>
        <Button
          color="secondary"
          onClick={handleDelete}
        >删除</Button>
      </DialogActions>
    </Dialog>
  )
}

export default PlanItemDelete
