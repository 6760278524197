import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

const CarForm = (props) => {
  const { data, errors, onChange } = props
  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          name="name"
          value={data.name ? data.name : ""}
          onChange={onChange}
          margin="normal"
          variant="outlined"
          size="small"
          label="名称"
          error={errors.name}
          helperText={errors.name ? errors.name.message : null}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="sort"
          value={data.sort ? data.sort : 0}
          onChange={onChange}
          margin="normal"
          variant="outlined"
          size="small"
          label="排序"
          type="number"
          error={errors.sort}
          helperText={errors.sort ? errors.sort.message : null}
        />
      </Grid>
    </Grid>
  )
}

export default CarForm
