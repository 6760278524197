import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useRouteMatch, useHistory } from 'react-router-dom'
import Layout from '../components/Layout'
import FrameGrid from '../components/FrameGrid'

const FrameList = () => {
  let { url } = useRouteMatch()
  const history = useHistory()
  const breadcrumbs = [{name:"框架管理"}]

  const handleClick = (row) => {
    history.push(`${url}/${row.id}`)
  }

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Box display="flex" mb={2} justifyContent="space-between">
        <Typography variant="h6" noWrap component="h2">框架管理</Typography>
      </Box>
      <FrameGrid onClickItem={handleClick} />
    </Layout>
  )
}

export default FrameList
