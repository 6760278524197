import React from 'react'
import Layout from '../components/Layout'
import { useRouteMatch, Switch } from 'react-router-dom'
import PrivateRoute from '../components/PrivateRoute'

import CueList from './CueList'
import CueCreate from './CueCreate'
import CueDetails from './CueDetails'

const Cue = () => {
  const { path } = useRouteMatch()
  const breadcrumbs = {
    list: [
      { name: '线索管理' }
    ],
    create: [
      { name: '线索管理', link: `${path}` },
      { name: '添加' },
    ],
    update: [
      { name: '线索管理', link: `${path}` },
      { name: '详情' },
    ]
  }
  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <CueList />
      </PrivateRoute>
      <PrivateRoute path={`${path}/create`}>
        <Layout breadcrumbs={breadcrumbs.create}>
          <CueCreate />
        </Layout>
      </PrivateRoute>
      <PrivateRoute path={`${path}/:id`}>
        <Layout breadcrumbs={breadcrumbs.update}>
          <CueDetails />
        </Layout>
      </PrivateRoute>
    </Switch>
  )
}

export default Cue
