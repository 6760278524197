import React from 'react'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/styles'
import {getCalendar} from '../utils/calendar'
import Calendar from '../components/Calendar'
import { sliceArray } from '../utils/list'

const PlanDateField = (props) => {
  const { value, onChange, startDate } = props
  const nextDate = new Date(startDate.getFullYear(), startDate.getMonth()+1, 1)
  const thisMonth = sliceArray(getCalendar(startDate))
  const nextMonth = sliceArray(getCalendar(nextDate))

  /**
   * 点击事件
   * 首次点击为1, 再次点击为2, 再次则删除
   */
  const handleClick = (dateString) => {
    const res = value.find(i => i[0] === dateString)
    if(res && res[1] === 1) {
      onChange(null, 'dates', value.map(i => i[0] === dateString ? [dateString, 2] : i))
    } else if (res && res[1] === 2) {
      onChange(null, 'dates', value.filter(i => i[0] !== dateString))
    } else {
      onChange(null, 'dates', [...value, [dateString, 1]])
    }
  }

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">排期</FormLabel>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Calendar
            value={value}
            date={startDate}
            onClick={handleClick}
            days={thisMonth}  />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Calendar
            value={value}
            date={nextDate}
            onClick={handleClick}
            days={nextMonth}  />
        </Grid>
      </Grid>
      <FormHelperText style={{lineHeight:1.2}}>点击一次为蓝色, 再次点击为红色. 蓝色代表正常投放, 红色代表赠送.</FormHelperText>
    </FormControl>
  )
}

export default PlanDateField
