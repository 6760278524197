import React, { useState, useEffect } from 'react'
import { Button, Grid, makeStyles, Input } from '@material-ui/core'
import MediumItem from './MediumItem'
import { fetchMediumPositionCreate, fetchMediumPositionUpdate, fetchMediumPositionDelete } from '../api'
import DeleteButton from '../components/DeleteButton'
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles(theme => ({
  rightBtn: {
    fontSize: 10,
    width:22,
    minWidth:0,
    lineHeight: 1,
    cursor: 'pointer',
    textAlign: 'center',
    borderRadius: 10,
    padding: 0,
    margin:2
  },
  flexBox: {
    display: 'flex',
    flexDirection: 'row',
    padding: '5px 10px'
  }
}))

const MediumPosition = (props) => {
  let { mediumId, index, data, onDelete } = props
  const [form, setForm] = useState(data)
  const [items, setItems] = useState(data.item_set ? data.item_set : [])
  const classes = useStyles()
  const handleAddMore = () => {
    setItems([...items, {}])
  }

  useEffect(() => {
    setForm(data)
  }, [data, index])

  const handleAutoSave = async (event) => {
    const value = event.target.value
    if (!value) {
      return
    }
    if (form.id) {
      // update
      try {
        await fetchMediumPositionUpdate(form.id, { medium: mediumId, name: value })
      } catch {
        alert('操作失败')
      }
    } else {
      // create
      try {
        const res = await fetchMediumPositionCreate({ medium: mediumId, name: value })
        setForm({ ...form, id: res.id })
      } catch {
        alert('操作失败')
      }
    }
  }
  const handleChangeForm = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const handleDelete = async () => {
    try {
      await fetchMediumPositionDelete(form.id)
      onDelete(index)
    } catch {
      alert('操作失败')
    }
  }

  const handleItemDelete = (index) => {
    setItems(items.filter((item, i) => index !== i))
  }


  return (
    <Grid container>
      <Grid container item md={3}>
        <Grid item xs={12}>
          <div className={classes.flexBox}>
            <Input
              name="name"
              placeholder='位置名字'
              disableUnderline={true}
              placeholder={data.name}
              onChange={handleChangeForm}
              onBlur={handleAutoSave}
              style={{ flex: 1, backgroundColor: '#fafafa', borderRadius: 10, paddingLeft: 10 }}
            />
            {form.id && <Button
              onClick={handleAddMore}
              color="default"
              className={classes.rightBtn}>
              <AddIcon></AddIcon></Button>}
            {form.id && <DeleteButton
              onClick={handleDelete}
              color="default"
              className={classes.rightBtn}
              title={<DeleteIcon></DeleteIcon>}>
            </DeleteButton>}
          </div>
        </Grid>
      </Grid>
      <Grid item md={9} style={{ borderLeft: '1px solid #EBEEF5' }}>
        {items && items.map((item, index) => (
          <MediumItem
            onDelete={handleItemDelete}
            positionId={form.id}
            data={item}
            key={index}
            index={index} />
        ))}
      </Grid>
    </Grid>
  )
}

export default MediumPosition
