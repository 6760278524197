import React, { useState, useEffect } from 'react'
import { Button, Input } from '@material-ui/core'
import { fetchMediumItemCreate, fetchMediumItemUpdate, fetchMediumItemDelete } from '../api'
import DeleteButton from '../components/DeleteButton'

const MediumItem = (props) => {
  let { positionId, index, data, onDelete } = props
  const [form, setForm] = useState(data)

  useEffect(() => {
    setForm(data)
  }, [data, index])

  const handleAutoSave = async () => {
    if (form.id) {
      // update
      try {
        await fetchMediumItemUpdate(form.id, form)
      } catch(error) {
        alert(error)
      }
    } else {
      // create
      try {
        const res = await fetchMediumItemCreate({ ...form, page: positionId })
        setForm({ ...form, id: res.id })
      } catch(error) {
        alert(error)
      }
    }
  }
  const handleChangeForm = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }

  const handleDelete = async () => {
    try {
      await fetchMediumItemDelete(form.id)
      onDelete(index)
    } catch {
      alert('操作失败')
    }
  }

  return (
    <div style={{display:'flex',flexDirection:'row',width:'100%'}}>
      <Input
        name="name"
        placeholder='形式名称'
        disableUnderline={true}
        defaultValue={data.name}
        onChange={handleChangeForm}
        style={{ flex: 1, backgroundColor: '#fafafa', borderRadius: 10, margin:'5px 10px'}}
      />
      <Input
        name="size"
        placeholder='要求及尺寸'
        disableUnderline={true}
        defaultValue={data.size}
        onChange={handleChangeForm}
        style={{ flex: 1, backgroundColor: '#fafafa', borderRadius: 10, margin:'5px 10px' }}
      />
      {form.name&&<Button color='default' onClick={handleAutoSave}>保存</Button>}
      {form.id && <DeleteButton color='default' onClick={handleDelete}>删除</DeleteButton>}
    </div>
  )
}

export default MediumItem
