import React from 'react'
import { useRouteMatch, Link, Switch  } from 'react-router-dom'
import PrivateRoute from '../components/PrivateRoute'

import FrameList from './FrameList'
import FrameCreate from './FrameCreate'
import FrameUpdate from './FrameUpdate'

const Frame = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <FrameList />
      </PrivateRoute>
      <PrivateRoute path={`${path}/create`}>
        <FrameCreate />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:id`}>
        <FrameUpdate />
      </PrivateRoute>
    </Switch>
  )
}

export default Frame
