import React, { useState } from 'react'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpansionPanel from './ExpansionPanel'
import ImagePicker from './ImagePicker'
import DeleteButton from '../components/DeleteButton'

const TemplateForm = (props) => {
  const { data, onChange, onDelete } = props
  const [expanded, setExpanded] = useState('base')
  const handleChange = (event, name=null, value=null) => {
    if(name === null) {
      name = event.target.name
    }
    if(value === null) {
      value = event.target.value
    }
    onChange({...data, [name]:value})
  }
  const handleToggle = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }
  return (
    <div>
      <ExpansionPanel
        expanded={expanded === 'base'}
        onChange={handleToggle('base')}
      >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="template-form-base"
        id="template-form-base"
      >
        <Typography>基础信息</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div>
        <TextField
          fullWidth
          margin="normal"
          name="name"
          value={data.name}
          onChange={handleChange}
          label="名称" />
        <Typography
          component="legend"
          variant="body2"
          color="textSecondary"
          gutterBottom>封面</Typography>
        {data.cover && (
          <img src={data.cover} width="100%" alt="cover" />
        )}
        <ImagePicker
          value={data.cover}
          onChange={(value)=>handleChange(null, "cover", value)} />

        <TextField
          fullWidth
          multiline
          rows={3}
          margin="normal"
          label="点位计划"
          value={data.plans.map(plan => plan.name).join(',')}
          disabled
          helperText="已关联的点位计划"
        />
        <TextField fullWidth
          margin="normal"
          name="tags"
          label="标签"
          value={data.tags}
          onChange={handleChange}
          helperText="多个标签用英文逗号分开"
        />
        <TextField fullWidth
          margin="normal"
          name="description"
          label="描述"
          value={data.description ? data.description : ''}
          onChange={handleChange}
          multiline
          rows="4" />
      </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
    <ExpansionPanel
        expanded={expanded === 'style'}
        onChange={handleToggle('style')}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="template-form-style"
        id="template-form-style"
      >
        <Typography>样式</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div style={{width:"100%"}}>
          {/**<RadioGroup
            aria-label="theme"
            name="color"
            value={data.color}
            onChange={handleChange}>
            <FormControlLabel
              value="light"
              control={<Radio />}
              label="亮色" />
            <FormControlLabel
              value="dark"
              control={<Radio />}
              label="暗色" />
              </RadioGroup>**/}
        <TextField fullWidth
          name="bgcolor"
          margin="normal"
          helperText="示例: #ffffff"
          label="背景色"
          type="color"
          value={data.bgcolor ? data.bgcolor : ''}
          onChange={handleChange}
          />
          <Typography
            gutterBottom
            component="legend"
            variant="body2"
            color="textSecondary">背景图片</Typography>
          {data.bgimage && (
            <img
              src={data.bgimage}
              width="100%"
              alt="bgimage" />
          )}
          <ImagePicker
            value={data.bgimage}
            onChange={(value)=>handleChange(null, "bgimage", value)} />
        {/**<TextField fullWidth
          margin="normal"
          name="css"
          label="额外CSS"
          multiline
          rows="6"
          />**/}
      </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
    <ExpansionPanel
      expanded={expanded === 'more'}
      onChange={handleToggle('more')}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="template-form-more"
        id="template-form-more"
      >
        <Typography>更多</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <div>
          <DeleteButton
            variant='outlined'
            onClick={onDelete} />
          </div>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  </div>
  )
}

export default TemplateForm
