import React from 'react'
import Box from '@material-ui/core/Box'
import { styled } from '@material-ui/core/styles'
import ImageBlock from './ImageBlock'
import VideoBlock from './VideoBlock'
import TabsBlock from './TabsBlock'
import TextBlock from './TextBlock'
import FormBlock from './FormBlock'
import GridBlock from './GridBlock'
import CarouselBlock from './CarouselBlock'
import EmptyBlock from './EmptyBlock'

const Cover = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 99,
})

const ElementShow = ({data, ...other}) => {
  let inner = null
  if (data.type === 'text') {
    inner = <TextBlock data={data} />
  }

  if (data.type === 'image') {
    inner = <ImageBlock data={data} />
  }

  if (data.type === 'video') {
    inner = <VideoBlock data={data} />
  }

  if(data.type === 'carousel') {
    inner = <CarouselBlock data={data} />
  }

  if(data.type === 'empty') {
    inner = <EmptyBlock data={data} />
  }

  if(data.type === 'form') {
    inner = <FormBlock data={data} />
  }

  if(data.type === 'tabs') {
    inner = <TabsBlock data={data} />
  }

  return (
    <Box position="relative" {...other}>
      {inner}
      <Cover onClick={()=>null} />
    </Box>
  )
}

export default ElementShow
