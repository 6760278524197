import React from 'react'
import { Switch, useRouteMatch } from 'react-router-dom'
import PrivateRoute from '../components/PrivateRoute'
import TemplateList from './TemplateList'
import TemplateEdit from './TemplateEdit'

const Templates = () => {
  const { path } = useRouteMatch()

  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <TemplateList />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:id`}>
        <TemplateEdit />
      </PrivateRoute>
    </Switch>
  )
}

export default Templates
