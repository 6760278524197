import React from 'react'
import BaseChip from '@material-ui/core/Chip'

const Chip = (props) => {
  const { color, ...other } = props
  let style = {}
  style['marginRight'] = '6px'
  style['fontSize'] = '0.65rem'
  style['backgroundColor'] = '#F2F4FF'
  style['color'] = '#7F95FF'
  if (props.color === 'primary'){
    style['backgroundColor'] = '#D5F3EB'
    style['color'] = '#00BA83'
  } else if (props.color === 'secondary') {
    style['backgroundColor'] = '#FFEBF3'
    style['color'] = '#FF3B7C'
  }
  return <BaseChip style={style} size="small" {...other} />
}

export default Chip
