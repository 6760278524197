import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import PointList from './PointList';
import PointCreate from './PointCreate';
import PrivateRoute from '../components/PrivateRoute';
import { useRouteMatch, Switch,useParams } from 'react-router-dom'
import PointUpdate from './PointUpdate';
import { fetchPlanDetail } from '../api';

const Point = () => {
  const { path, url } = useRouteMatch()
  const [number,setNumber] = useState('投放计划')
  const { id } = useParams()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchPlanDetail(id)
        console.log(res);
        setNumber(res.number)
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
  }, [id])
  const breadcrumbs = {
    list: [
      { name: '投放计划', link: '/plan' },
      { name: number, link: `/plan/${id}` },
      { name: '点位计划' }
    ],
    create: [
      { name: '投放计划', link: '/plan' },
      { name: number, link: `/plan/${id}` },
      //{ name: '点位计划', link: `${url}` },
      { name: '添加点位' }
    ],
    update: [
      { name: '投放计划', link: '/plan' },
      { name: number, link: `/plan/${id}` },
      //{ name: '点位计划', link: `${url}` },
      { name: '点位' },
    ]
  }
  return (
    <>
      <Switch>
        <PrivateRoute exact path={path}>
          <Layout breadcrumbs={breadcrumbs.list}>
            <PointList />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path={`${url}/create`}>
          <Layout breadcrumbs={breadcrumbs.create}>
            <PointCreate />
          </Layout>
        </PrivateRoute>
        <PrivateRoute path={`${url}/:id`}>
          <Layout breadcrumbs={breadcrumbs.update}>
            <PointUpdate />
          </Layout>
        </PrivateRoute>
      </Switch>
    </>
  )
}

export default Point
