import React, { useState, useEffect } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import Grid from '@material-ui/core/Grid'
import DesktopMacIcon from '@material-ui/icons/DesktopMac'
import SmartphoneIcon from '@material-ui/icons/Smartphone'
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import ToggleOffIcon from '@material-ui/icons/ToggleOff';
import ToggleOnIcon from '@material-ui/icons/ToggleOn';
import MenuIcon from '@material-ui/icons/Menu'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useBeforeunload } from 'react-beforeunload'
import { makeStyles } from '@material-ui/styles'
import { Prompt, useHistory, Link, useParams } from 'react-router-dom'
import { fetchTemplateDelete, fetchTemplateDetail, fetchTemplateUpdate } from '../api'

import Layout from '../components/Layout'
import TabPanel from '../components/TabPanel'
import Editor2 from '../components/Editor2'
import BlockEditWrap from '../components/BlockEditWrap'
import TemplateForm from '../components/TemplateForm'
import TemplatePublish from './TemplatePublish'
import DeleteButton from '../components/DeleteButton'
import BlockTheme from '../components/BlockTheme'
import LayoutEdit from '../components/LayoutEdit.js'

import blocks from '../blocks' // 区块初始数据

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(4)
  },
  actions: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row-reverse',
    overflow: 'auto',
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },
  preview: {
    backgroundColor: theme.palette.common.white,
    overflow: 'auto',
    boxShadow: theme.shadows[2],
    height: 800,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(5),
    boxSizing: 'border-box'
  },
  properties: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[2],
    borderRadius: theme.shape.borderRadius,
    overflow: 'auto',
    maxHeight: 800
  },
  root2: {
    display: 'flex'
  },
  main: {
    flex: 1,
    maxHeight: '100vh',
    overflow: 'auto',
  },
  tools: {
    position: 'relative',
    boxShadow: theme.shadows[2],
    height: '100vh',
    width: 320,
    marginLeft: props => props.toggle ? 0 : -320,
    transition: 'all 0.3s ease'
  },
  quick: {
    position:'absolute',
    left:'100%',
    marginLeft:16,
    bottom:16,
    zIndex:99,
    backgroundColor:'white',
    boxShadow: theme.shadows[2],
    borderRadius:2,
    width:'fit-content',
    padding: theme.spacing(0, 0.5),
    '& hr': {
      margin: theme.spacing(0, 0.5),
      color: 'black',
      height: 20,
    }
  },
  tab: {
    minWidth: 'initial !important',
  }
}))

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const TemplateEdit = () => {
  const { id } = useParams()
  const [toggle, setToggle] = useState(true) // tools 显示状态
  const [data, setData] = useState() // 模板数据
  const [value, setValue] = useState(0) // 当前TAB
  const [edit, setEdit] = useState(-1) // 当前选中区块编号
  const [element, setElement] = useState(0) // 当前操作元素
  const [text, setText] = useState([]) // 区块json数据
  const [refresh, setRefresh] = useState(0) // 主动刷新数据
  const [changed, setChanged] = useState(false) // 是否有编辑数据
  const classes = useStyles({toggle:toggle})
  const history = useHistory()

  const sm = useMediaQuery('(max-width:960px)')

  // 刷新, 关闭窗口
  useBeforeunload(()=>changed ? "warning" : null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchTemplateDetail(id)
        handleSetData(res)
      } catch (error) {
        console.log(error)
      }
    }
    fetchData()
  }, [id, refresh])

  // 预览
  const handlePreview = () => {
    if(!changed) {
      return window.open(`/preview/${id}`)
    }
    if(window.confirm("您有尚未保存的更改, 是否继续?")) {
      return window.open(`/preview/${id}`)
    }
  }

  // 设置模板类型(pc/phone)
  const handleSetMode = (value) => {
    setData({...data, mode:value})
    setChanged(true)
  }

  // 更改form数据
  const handleSetForm = (value) => {
    setData(value)
    setChanged(true)
  }

  // 设置模板数据
  const handleSetData = (res) => {
    setData(res)
    let content = []
    if (res.content) {
      content = JSON.parse(res.content)
    }
    // 定义0号空白区块
    //const empty = {
    //  id: content.length,
    //}
    //setText(content.concat([empty]))
    setText(content)
  }

  const handleChangeTab = (event, newValue) => {
    setValue(newValue)
  }

  // 保存模板
  const handleSave = async () => {
    try {
      //let content = text.filter(item => item.elements !== undefined)
      let content = text
      content = JSON.stringify(content)
      const req = { ...data, content }
      const res = await fetchTemplateUpdate(id, req)
      handleSetData(res)
      setChanged(false)
      alert('操作成功')
    } catch {
      alert('操作失败')
    }
  }

  // 删除模板
  const handleDelete = async () => {
    try {
      await fetchTemplateDelete(data.id)
      setChanged(false)
      history.goBack()
    } catch {
      console.log('删除失败')
    }
  }

  // 移动区块
  const handleMove = (direction) => {
    if (text.length <= 1) {
      return
    }
    if (direction === 'up' && edit !== 0) {
      const newText = text.map((item, i) => {
        if (i === edit) {
          item.id = edit - 1
        } else if (i === edit - 1) {
          item.id = edit
        }
        return item
      })
      setText(newText.sort((a, b) => (a.id > b.id) ? 1 : -1))
      setEdit(edit - 1)
    } else {
      const newText = text.map((item, i) => {
        if (i === edit) {
          item.id = edit + 1
        } else if (i === edit + 1) {
          item.id = edit
        }
        return item
      })
      setText(newText.sort((a, b) => (a.id > b.id) ? 1 : -1))
      setEdit(edit + 1)
    }
    setChanged(true)
  }

  // 选中区块
  const handleEdit = (id, index) => {
    console.log('handleEdit', id, index)
    setEdit(id)
    setElement(index)
    if(index !== undefined) {
      setValue(2)
    } else {
      setValue(1)
    }
  }

  // 插入区块
  //const handleBlockAdd = (direction = 'up', type = 'text', index) => {
  //  let list = []
  //  let current = edit
  //  if (direction === 'down') {
  //    current += 1
  //  }
  //  let newone = { ...blocks[type], id: current }
  //  list = text.map((item, i) => {
  //    if (i < current) {
  //      return item
  //    } else {
  //      return {
  //        ...item,
  //        id: item.id + 1
  //      }
  //    }
  //  })
  //  list = list.slice(0, current).concat(newone).concat(list.slice(current))
  //  setText(list)
  //  setChanged(true)
  //}
  const handleBlockAdd = (direction = 'up', data={}, index) => {
    setEdit(-1)
    setElement(-1)
    console.log('handle block add')
    console.log('edit', edit)
    console.log('element', element)
    let list = []
    let current = edit
    if (direction === 'down') {
      //current += 1
      current = text.length
    }
    // 如果从blockwrap头部添加
    if (index !== undefined) {
      current = index
    }
    let newone = { ...data, id: current }
    list = text.map((item, i) => {
      if (i < current) {
        return item
      } else {
        return {
          ...item,
          id: item.id + 1
        }
      }
    })
    list = list.slice(0, current).concat(newone).concat(list.slice(current))
    setText(list)
    setChanged(true)
  }

  // 保存区块元数据
  const handleBlockSave = (data) => {
    console.log('saved block', data)
    let copy = [...text]
    copy.map((item,i) => {
      if(item.id === data.id) {
        copy[i] = data
      }
    })
    //copy[edit] = data
    //copy[edit]['elements'][element] = data
    setText([...copy])
    //setText(text.map(item => {
    //  if (item.id === data.id) {
    //    return {...item, elements:[]}
    //  } else {
    //    return item
    //  }
    //}))
    setChanged(true)
  }


  // 保存元素数据
  const handleElementSave = (data) => {
    console.log(data)
    let copy = [...text]
    copy[edit]['elements'][element] = data
    setText([...copy])
    setChanged(true)
  }

  // 删除区块
  const handleBlockRemove = (event) => {
    setValue(0) //
    //setText(text.filter((item, index) => index !== edit))
    const newText = text.filter((item,i)=>i!==edit).map((item, i) => {
      if (i >= edit) {
        item.id -= 1
      }
      return item
    })
    setText(newText)
    setEdit(-1) // 清除选中
    setChanged(true)
  }

  if (!data) {
    return null
  }

  const breadcrumbs = [
    { name: '模板管理', link: '/templates/' },
    { name: data.name },
  ]

  return (
    <div className={classes.root2}>
      <Prompt when={changed} message="系统可能不会保存您所做的更改。" />
      <div className={classes.tools}>
        <AppBar color="primary" position="static">
          <Toolbar style={{display:'flex',justifyContent:'space-between'}}>
            <Tooltip title="返回模板列表">
            <IconButton
              onClick={()=>history.push('/templates/')}
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="close">
              <CloseIcon />
            </IconButton>
          </Tooltip>
            <ButtonGroup size="small" color="inherit">
              <Button
                disabled={data.content === null || data.content === "[]"}
                onClick={handlePreview}
              >预览</Button>
              <Button
                onClick={handleSave}
              >保存</Button>
              <TemplatePublish
                disabled={data.content === null || data.content === "[]"}
                data={data}
                onSuccess={()=>setRefresh(refresh+1)}
              />
            </ButtonGroup>
          </Toolbar>
        </AppBar>
        <div style={{flex:1}}>
          <Tabs
            indicatorColor="primary"
            variant="fullWidth"
            aria-label="tabs"
            value={value}
            onChange={handleChangeTab}>
            <Tab className={classes.tab} label="模板" {...a11yProps(0)} />
            <Tab className={classes.tab} label="区块" disabled={edit < 0} {...a11yProps(1)} />
            <Tab className={classes.tab} label="元素" disabled={edit < 0} {...a11yProps(2)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <TemplateForm
              data={data}
              onChange={handleSetForm}
              onDelete={handleDelete}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            {edit !== -1 && (
              <LayoutEdit
                onRemove={handleBlockRemove}
                onSave={(data) => handleBlockSave(data)}
                data={text[edit]}
              />
            )}
          </TabPanel>
          <TabPanel value={value} index={2}>
            {edit !== -1 && element !== undefined && (
              <BlockEditWrap
                onRemove={handleBlockRemove}
                onSave={(data) => handleElementSave(data)}
                data={text[edit].elements[element]} />
            )}
          </TabPanel>
        </div>
        <Grid container
          alignItems="center"
          className={classes.quick}>
          <Tooltip title="折叠工具栏">
            <IconButton
              onClick={()=>setToggle(!toggle)}
            >
              {toggle ? <ToggleOnIcon color="secondary" /> : <ToggleOffIcon />}
            </IconButton>
          </Tooltip>
          <Divider orientation="vertical" />
          <Tooltip title="电脑端">
          <IconButton
            color={data.mode === "pc" ? "secondary" : "default"}
            onClick={()=>handleSetMode("pc")}>
            <DesktopMacIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="手机端">
          <IconButton
            color={data.mode === "phone" ? "secondary" : "default"}
            onClick={()=>handleSetMode("phone")}>
            <SmartphoneIcon />
          </IconButton>
        </Tooltip>
        </Grid>
      </div>
      <div className={classes.main}>
        <BlockTheme data={data}>
          <Editor2
            data={data}
            blocklist={text}
            edit={edit}
            onEdit={handleEdit}
            onAdd={handleBlockAdd}
            onSave={handleBlockSave}
            onMove={handleMove}
          />
        </BlockTheme>
      </div>
    </div>
  )
}

export default TemplateEdit
