import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import TablePagination from '@material-ui/core/TablePagination'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import InputBase from '@material-ui/core/InputBase'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/styles'
import { Link as BaseLink, useRouteMatch } from 'react-router-dom';
import moment from 'moment-timezone'
import { DatePicker } from '@material-ui/pickers'

import Layout from '../components/Layout'
import { fetchCueExport, fetchCities, fetchCueList, fetchCueStatusList, fetchMediumList } from '../api'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
  },
  input: {
    border: '1px solid rgba(0,0,0,0.23)',
    borderRadius: '5px',
    margin: '0 12px 6px 0',
    padding: '1px 12px',
  },
  search: {
    backgroundColor: '#3859FF',
    borderColor: '#3859FF',
    color: '#FEFEFF',
    width: '280px',
    maxWidth: '100%'
  },
  month: {
    backgroundColor: '#D5F3EB',
    borderColor: '#D5F3EB',
    color: '#00BA83'
  },
  city: {
    backgroundColor: '#FFEBF3',
    borderColor: '#FFEBF3',
    color: '#FF3B7C'
  },
  classify: {
    backgroundColor: '#E9ECFC',
    borderColor: '#E9ECFC',
    color: '#5D78FF'
  },
  time:{
    backgroundColor: '#D5ECFF',
    borderColor: '#D5ECFF',
      color: '#3859FF',
      width: 160,
  },
  button: {
    borderWidth: 1,
    padding: '4px 12px'
  }
}))

const CueList = () => {
  const [data, setData] = useState({ count: 0, results: [] })
  const [statuses, setStatuses] = useState([])
  const [mediums, setMediums] = useState([])
  const [cities, setCities] = useState([])
  const classes = useStyles()

  const [focus, setFocus] = useState()
  const [selected, setSelected] = useState([])
  const [selectAll, setSelectAll] = useState(false) // 选中全部(不止本页)
  const [query, setQuery] = useState({})
  const [filter, setFilter] = useState('')
  const [page, setPage] = useState(0)
  const [ordering, setOrdering] = useState({ name: 'created', direction: 'desc' })
  const { url } = useRouteMatch()
  const limit = 10

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchCities(0, 50)
        setCities(res.results)
      } catch { }
    }
    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchCueStatusList(0, 50)
        setStatuses(res.results)
      } catch { }
    }
    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchMediumList(0, 50)
        setMediums(res.results)
      } catch { }
    }
    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      const sort = ordering.direction === 'asc' ? ordering.name : '-' + ordering.name
      try {
        const res = await fetchCueList(page, limit, filter, sort)
        setData(res)
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
  }, [page, filter, ordering])

  const onChangePage = (event, newpage) => {
    setPage(newpage)
  }

  /**
   * 搜索+筛选
   */
  const handleChange = (event, name = null, value=null) => {
    if (!name) {
      name = event.target.name
    }
      if(!value) {
        value = event.target.value
      }
    setQuery({ ...query, [name]: value })
  }

  /**
   * 排序
   */
  const handleOrdering = (name, direction = 'desc') => {
    setOrdering({ name, direction })
  }

  /**
   * 更新搜索字符串
   */
  const handleFilter = () => {
    const keys = Object.keys(query)
    let queryString = ''
    let i
    for (i = 0; i < keys.length; i++) {
      if (query[keys[i]] !== undefined && query[keys[i]] !== '') {
        queryString += keys[i] + '=' + query[keys[i]] + '&'
      }
    }
    setFilter(queryString)
  }

  /**
   * 全选本页
   */
  const handleSelectAll = event => {
    if (event.target.checked) {
      setSelected(data.results.map(item => item.id))
      return
    }
    setSelected([])
  }

  /**
   * 全选所有
   */
  const handleSelectRealAll = () => {
    setSelected(data.results.map(item => item.id))
    setSelectAll(true)
  }

  /**
   * 清除全选
   */
  const clearSelectRealAll = () => {
    setSelected([])
    setSelectAll(false)
  }

  /**
   * 选择单个
   */
  const handleSelect = (id) => {
    setSelectAll(false)
    if (selected.includes(id)) {
      setSelected(selected.filter(item => item !== id))
    } else {
      setSelected([...selected, id])
    }
  }

  const breadcrumbs = [
    { name: '线索' },
  ]

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Box display="flex" mb={2} justifyContent="space-between">
        <Typography variant="h6" noWrap component="h2">线索</Typography>
        <Button
          variant="contained"
          color="primary"
          component={BaseLink}
          to={`${url}/create`}
        >添加线索</Button>
      </Box>
      <Box mb={2}>
        <InputBase
          name="search"
          className={`${classes.input} ${classes.search}`}
          placeholder="请输入名称"
          value={query.search}
          onChange={handleChange}
        />
        <Select
          displayEmpty
          value={query.mediums}
          onChange={(event) => handleChange(event, "medium")}
          input={<InputBase
            className={`${classes.input} ${classes.month}`} />}>
          <MenuItem>全部媒体</MenuItem>
          {mediums.map(row => (
            <MenuItem value={row.id} key={row.id}>{row.name}</MenuItem>
          ))}
        </Select>
        <Select
          displayEmpty
          value={query.city}
          onChange={(event) => handleChange(event, "city")}
          input={<InputBase
            className={`${classes.input} ${classes.city}`} />}>
          <MenuItem>全部城市</MenuItem>
          {cities.map(row => (
            <MenuItem value={row.id} key={row.id}>{row.name}</MenuItem>
          ))}
        </Select>
        <Select
          displayEmpty
          value={query.status}
          onChange={(event) => handleChange(event, "status")}
          input={<InputBase
            className={`${classes.input} ${classes.classify}`} />}>
          <MenuItem>全部状态</MenuItem>
          {statuses.map(row => (
            <MenuItem value={row.id} key={row.id}>{row.name}</MenuItem>
          ))}
        </Select>

        <DatePicker
            emptyLabel="开始日期"
            TextFieldComponent={InputBase}
            className={`${classes.input} ${classes.time}`}
            value={query.date_start ? moment(query.date_start, "YYYY-MM-DD") : null}
            onChange={date=>handleChange(null, "date_start", date.format("YYYY-MM-DD"))}
            cancelLabel="取消"
            okLabel="确定"
        />
        <DatePicker
            emptyLabel="结束日期"
            TextFieldComponent={InputBase}
            className={`${classes.input} ${classes.time}`}
            value={query.date_end ? moment(query.date_end, "YYYY-MM-DD") : null}
            onChange={date=>handleChange(null, "date_end", date.format("YYYY-MM-DD"))}
            cancelLabel="取消"
            okLabel="确定"
        />
        {/*
        <InputBase
          name="date_start"
          type={focus === 'date_start' ? 'date' : 'text'}
          className={`${classes.input} ${classes.time}`}
          value={query.date_start}
          onChange={handleChange}
          onFocus={()=>setFocus('date_start')}
          placeholder='开始时间'
        />
        <InputBase
          name="date_end"
          type={focus === 'date_end' ? 'date' : 'text'}
          className={`${classes.input} ${classes.time}`}
          value={query.date_end}
          onFocus={()=>setFocus('date_end')}
          onChange={handleChange}
          placeholder='结束时间'
        />
        */}
        <Button
          style={{ marginRight: '6px' }}
          className={classes.button}
          size='small'
          variant="outlined"
          color="primary"
          onClick={handleFilter}
        >查询</Button>
        <Button
          size='small'
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={() => window.location.reload()}
        >重置</Button>
      </Box>

      <TableContainer className={classes.root}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                padding="checkbox"
                className={classes.tableHeadText}>
                <Checkbox
                  color="primary"
                  onClick={handleSelectAll}
                />
              </TableCell>
              <TableCell>ID</TableCell>
              <TableCell>报名信息</TableCell>
              <TableCell>投放计划</TableCell>
              <TableCell>意向车型</TableCell>
              <TableCell>当前经销商</TableCell>
              <TableCell>状态</TableCell>
              <TableCell sortDirection={ordering.name === 'created'}>
                <TableSortLabel
                  active={ordering.name === 'created'}
                  direction={ordering.direction}
                  onClick={() => handleOrdering('created',
                    ordering.direction === 'asc' ? 'desc' : 'asc')}
                >
                  报名时间
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.results.map(row => (
              <TableRow
                key={row.id}
                onClick={() => handleSelect(row.id)}
                aria-checked={selected.includes(row.id)}
                selected={selected.includes(row.id)}
                role="checkbox">
                <TableCell
                  padding="checkbox">
                  <Checkbox
                    color="primary"
                    onClick={() => handleSelect(row.id)}
                    checked={selected.includes(row.id)} />
                </TableCell>
                <TableCell>#{row.id}</TableCell>
                <TableCell>{row.name}<br />{row.city}<br />{row.mobile}</TableCell>
                <TableCell>{row.medium}<br />{row.medium_position}</TableCell>
                <TableCell>{row.car}</TableCell>
                <TableCell>{row.store}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{moment(row.created).tz('Asia/Shanghai').format('YYYY/MM/DD HH:mm')}</TableCell>
                <TableCell align="center">
                  <Button
                    color="primary"
                    size="small"
                    component={BaseLink}
                    to={`${url}/${row.id}`}
                  >编辑</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box
          mb={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center">
          <form method="POST" action={fetchCueExport}>
            <input name="is_all" type="hidden" value={selectAll ? 1 : 0} />
            <input name="filter" type="hidden" value={filter} />
            <input name="ids" type="hidden" value={selected.join(',')} />
            <Button
              size="small"
              type="submit"
              style={{marginLeft:10,marginRight:10}}
              color="secondary"
              variant="contained"
              disabled={!selected.length}>导出</Button>
            <Typography variant="body2" component="span" color="textSecondary">
              {'  '}选中了{data.count}个中的{selectAll ? data.count : selected.length}个{'  '}
            </Typography>
            {selectAll ? (
              <Link variant="body2" onClick={clearSelectRealAll}>
                取消全选
              </Link>
            ) : (
                <Link variant="body2" onClick={handleSelectRealAll}>
                  全选
              </Link>
              )}
          </form>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={data.count}
            rowsPerPage={10}
            page={page}
            onChangePage={onChangePage}
          />
        </Box>
      </TableContainer>
    </Layout>
  )
}

export default CueList
