import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { fetchPreview } from '../api'
import BlockShow from '../components/BlockShow'
import BlockTheme from '../components/BlockTheme'

const Preview = () => {
  const { id } = useParams()
  const [data, setData] = useState()
  const [blocks, setBlocks] = useState([])
  useEffect(()=>{
    const fetchData = async() => {
      try {
        const res = await fetchPreview(id)
        setData(res)
        setBlocks(JSON.parse(res.content))
      } catch {
        console.log('fetch template api error')
      }
    }
    fetchData()
  }, [id])

  if(!data) {
    return null
  }

  return (
    <BlockTheme data={data}>
      <BlockShow
        data={data}
        blocklist={blocks}
        onSubmit={()=>null} />
    </BlockTheme>
  )
}

export default Preview
