import React, { useState, useEffect } from 'react'
import { Box, Button, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'
import { fetchPlanItemList } from '../api'
import { getDaysOfMonth } from '../utils/calendar'
import moment from 'moment-timezone'

import PlanItemUpdate from './PlanItemUpdate'
import PlanItemDelete from './PlanItemDelete'
import PlanItemQrcode from '../components/PlanItemQrcode'

/**
 * 日期状态展示
 */
const DateCell = (props) => {
  const { day, value, ...other } = props
  const week = day.getDay()
  let style = {padding:0}
  const  dateString = moment(day).tz('Asia/Shanghai').format('YYYYMMDD')
  const res = value.find(i => i[0] === dateString)
  if(week === 6 || week === 0) {
    style['background'] = '#f4f4f4'
  }
  if(res && res[1] === 1) {
      //style = {color:'#74b9ff'}
    style['background'] = '#74b9ff'
    style['color'] = 'white'
  } else if(res && res[1] === 2) {
      //style = {color:'#fab1a0'}
    style['background'] = '#fab1a0'
    style['color'] = 'white'
  }
  style['fontSize'] = 12
  style['display'] = 'inline-block'
  style['minWidth'] = '20px'
  style['textAlign'] = 'center'
  style['height'] = '38px'
  style['lineHeight'] = '38px'
  style['padding'] = '0'
  return <span {...other} style={style}>{day.getDate()}</span>
}

/**
 * 排期表格
 */
const DatesTable = ({data, monthes}) => (
  <div style={{width:'100%',overflowX:'scroll'}}>
  <Table size="small">
    <TableHead>
      <TableRow>
        {monthes.map((month, index) => (
          <TableCell key={index}>{moment(month).tz('Asia/Shanghai').format('YYYY/MM')}排期</TableCell>
        ))}
      </TableRow>
    </TableHead>
    <TableBody>
      {data.map(row => (
        <TableRow key={row.iid}>
          {monthes.map((month,monthIndex) => (
            <TableCell
              key={monthIndex}
              style={{paddingTop:0,paddingBottom:0,whiteSpace:'nowrap'}}>
              {getDaysOfMonth(month).map((day, index) => (
                <DateCell
                  key={index}
                  value={row.dates ? row.dates : []}
                  day={day}
                />
              ))}
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
   </Table>
  </div>
)

const PlanItemList = (props) => {
  const [data, setData] = useState([])
  const [itemEdit, setItemEdit] = useState(0)
  const [itemDelete, setItemDelete] = useState(0)
  const [itemPreview, setItemPreview] = useState(0)
  const { plan } = props
  const [refresh, setRefresh] = useState(0)

  useEffect(() => {
    const fetchData = async() => {
      const query = `plan=${plan.id}`
      try {
        const res = await fetchPlanItemList(0, 50, query)
        setData(res.results)
      } catch {
      }
    }
    fetchData()
  }, [plan, refresh])

  const handleClose = () => {
    setItemEdit(0)
    setItemDelete(0)
    setRefresh(refresh+1)
  }

  if(!data.length) {
    return (
      <Box fontSize={16} width="100%" align="center" py={2} color="darkgrey">暂无数据</Box>
    )
  }

  // 日历
  const firstMonth = new Date(plan.start)
  const nextMonth = new Date(
    firstMonth.getFullYear(),
    firstMonth.getMonth() + 1)
  //const days = getDaysOfMonth(today).concat(getDaysOfMonth(next))
  const monthes = [firstMonth, nextMonth]

  return (
    <React.Fragment>
      <PlanItemUpdate
        plan={plan}
        id={itemEdit}
        open={Boolean(itemEdit)}
        onClose={handleClose}
      />
      <PlanItemDelete
        id={itemDelete}
        open={Boolean(itemDelete)}
        onClose={handleClose}
      />
      <PlanItemQrcode
        data={data.find(i=>i.id === itemPreview)}
        open={Boolean(itemPreview)}
        onClose={()=>setItemPreview(0)}
      />
    <TableContainer style={{display:'flex',overflowX:'scroll'}}>
      <Table size="small" style={{width:'initial'}}>
        <TableHead>
          <TableRow>
            <TableCell style={{whiteSpace:'nowrap'}}>编号</TableCell>
            <TableCell style={{whiteSpace:'nowrap'}}>广告形式</TableCell>
            <TableCell style={{whiteSpace:'nowrap'}}>车型</TableCell>
            <TableCell style={{whiteSpace:'nowrap'}}>模板</TableCell>
            <TableCell align="center">操作</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableRow key={row.iid}>
              <TableCell style={{whiteSpace:'nowrap'}}>{row.number}</TableCell>
              <TableCell style={{whiteSpace:'nowrap'}}>{row.position_name}</TableCell>
              <TableCell
                style={{maxWidth:120,overflow:'hidden',whiteSpace:'nowrap'}}
              >{row.cars.join(',')}</TableCell>
              <TableCell  style={{whiteSpace:'nowrap'}}>{row.theme ? row.theme_name : '-'}</TableCell>
              <TableCell align="center"  style={{whiteSpace:'nowrap'}}>
                <Button
                  onClick={()=>setItemEdit(row.id)}
                  size="small">编辑</Button>
                <Button
                  disabled={!row.theme}
                  color="primary"
                  size="small"
                  onClick={()=>setItemPreview(row.id)}
                >预览</Button>
                {/**<Button
                  disabled={!row.theme}
                  color="primary"
                  component={Link}
                  to={row.link}
                  size="small">预览</Button>**/}
                <Button
                  onClick={()=>setItemDelete(row.id)}
                  color="secondary"
                  size="small">删除</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <DatesTable data={data} monthes={monthes} />
    </TableContainer>
  </React.Fragment>
  )
}

export default PlanItemList
