import React, { useState, useEffect } from 'react'
import { Grid, ExpansionPanel, Typography, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import { fetchCueDetail, fetchCueDelete, fetchCueUpdate } from '../api'
import { useHistory, useParams } from 'react-router-dom'
import DeleteButton from '../components/DeleteButton'
import { CueForm } from '../components/CueForm'
import Filtrate from '../components/Filtrate'
import CueLogs from './CueLogs'

const RenderBtn = ({ onRemove }) => {
	return (
		<Filtrate title='线索管理' otherSub={<>
			<DeleteButton
				variant="outlined"
				style={{
					margin: 5,
				}}
				onClick={onRemove}>
				删除
		</DeleteButton></>}
			sumbitText='保存'></Filtrate>
	)
}

const CueDetails = (props) => {
	const [oldData, setData] = useState(null)
	const [select, setSelect] = useState({})
	const history = useHistory()
	const { id } = useParams()
	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await fetchCueDetail(id)
				console.log(res);
				setData(res)
			} catch (error) {
				console.error(error)
			}
		}
		fetchData()
	}, [id])

	const handleChange = (res) => {
		setSelect(res)
	}

	const onRemove = async () => {
		try {
			await fetchCueDelete(id)
			history.goBack()
		} catch (error) {
			console.error(error)
		}
	}
	const handleSubmit = async (e) => {
		const data = {
			...e,
			car: select.car ? select.car : oldData.car,
			status: select.status ? select.status : oldData.status,
			city: select.city ? select.city : oldData.city,
			store: select.store ? select.store : oldData.store,
			original: select.original ? select.original : oldData.original,
		}
		try {
			await fetchCueUpdate(id, data)
			window.location.reload()
		} catch (error) {
			alert(error.toString())
			console.error(error)
		}
	}

	return (
		<Grid>
			<CueForm
				formActions={<RenderBtn onRemove={onRemove} />}
				initData={oldData}
				onSubmit={handleSubmit}
				onChange={handleChange} />

			<ExpansionPanel expanded={true} elevation={0} style={{ marginBottom: 16 }}>
				<ExpansionPanelSummary>
					<Typography style={{ fontSize: 18 }}>状态跟踪</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>
					<CueLogs id={id} />
				</ExpansionPanelDetails>
			</ExpansionPanel>

		</Grid>
	)
	return (
		<Grid>
			<ExpansionPanel expanded={true} elevation={0}>
				<ExpansionPanelSummary>
					<Typography style={{ fontSize: 18 }}>报名信息</Typography>
					{/* <Typography>待处理</Typography> */}
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>
					{/* <form>
						{
							data && <Grid container>
								<Inputs xs={[4, 12]} name='姓名' value={data.name} />
								<Inputs xs={[4, 12]} name='手机号' value={data.mobile} />
								<Inputs xs={[4, 12]} name='城市' value={data.city} />
								<Inputs xs={[4, 12]} name='时间' value={data.created} />
								<Inputs xs={[4, 12]} name='状态' value={data.status} />
								<Inputs xs={[4, 12]} name='车型' value={data.car} />
								<Inputs xs={[4, 12]} name='经销商' value={data.store_name} />
								<Grid item xs={12} style={{ paddingLeft: 20 }}>
									<DeleteButton
										variant="outlined"
										color="secondary"
										onClick={onRemove}>
										删除
              		</DeleteButton>
									<Button
										variant="outlined"
										style={{marginLeft:20}}
										color="primary"
										type={'submit'}>保存</Button>
								</Grid>
							</Grid>
						}
					</form> */}
				</ExpansionPanelDetails>
			</ExpansionPanel>

			{/* <ExpansionPanel expanded={true} elevation={0}>
				<ExpansionPanelSummary>
					<Typography style={{ fontSize: 18 }}>状态跟踪</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>
					<Grid container>
						<Grid item xs={12}>
							<span>2019-12-5</span>
							<span>生成线索</span>
						</Grid>
						<Grid item xs={12}>
							<span>2019-12-5</span>
							<span>生成线索</span>
						</Grid>
						<Grid item xs={12}>
							<span>2019-12-5</span>
							<span>生成线索</span>
						</Grid>
						<Grid item xs={12}>
							<span>2019-12-5</span>
							<span>生成线索</span>
						</Grid>
					</Grid>
				</ExpansionPanelDetails>
			</ExpansionPanel> */}
		</Grid>
	)
}

export default CueDetails
