import React, { useState } from 'react'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import BaseChip from '@material-ui/core/Chip'
import IconButton from '@material-ui/core/IconButton'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import TemplateCopy from '../containers/TemplateCopy'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

const Chip = (props) => {
  const { color, ...other } = props
  let style = {}
  style['marginRight'] = '6px'
  style['fontSize'] = '0.65rem'
  style['backgroundColor'] = '#F2F4FF'
  style['color'] = '#7F95FF'
  if (props.color === 'primary'){
    style['backgroundColor'] = '#D5F3EB'
    style['color'] = '#00BA83'
  } else if (props.color === 'secondary') {
    style['backgroundColor'] = '#FFEBF3'
    style['color'] = '#FF3B7C'
  }
  return <BaseChip style={style} size="small" {...other} />
}

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: 345
  },
  media: {
    height: 320
  },
  chips: {
    height: '55px',
    overflow: 'hidden',
    display: 'flex',
    flexWrap: 'wrap',
    '& > *': {
      marginBottom: theme.spacing(0.5)
    }
  }
}))


const TemplateCard = (props) => {
  const { data } = props
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()
  const history = useHistory()

  const handleEdit = (id) => {
    history.push(`/templates/${id}`)
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Card elevation={0}>
      <CardActionArea
        onClick={()=>handleEdit(data.id)}>
        <CardMedia
          className={classes.media}
          image={data.cover ? data.cover :
              require('../images/default_template.jpg')}
          title={data.name} />
        </CardActionArea>
        <CardContent>
          <Box
            display='flex'
            alignItems='center'
            mb={1}
            justifyContent='space-between'>
            <div>
          <Box display='flex' alignItems='center' mb={1}>
          {data.mode === 'pc' && <Chip color="primary" label="PC" />}
          {data.mode === 'phone' && <Chip color="secondary" label="手机" />}
          <Typography
            variant="h6"
            component="h4">
            {data.name}
          </Typography>
          </Box>
          <Typography
            gutterBottom
            variant="body2"
            color="textSecondary"
            component="p">关联数量: {data.plans.length}</Typography>
          </div>
          <IconButton
            aria-label="actions"
            color="secondary"
            aria-haspopup="true"
            aria-controls={`actions-menu-${data.id}`}
            onClick={handleClick}>
            <MoreVertIcon />
          </IconButton>
          <Menu
            id={`actions-menu-${data.id}`}
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}>
            <MenuItem onClick={()=>handleEdit(data.id)}>编辑</MenuItem>
            <TemplateCopy id={data.id} />
          </Menu>
          </Box>
          <div className={classes.chips}>
            {data.tags && data.tags.split(',').map((tag, index) => (
              <Chip
                key={index}
                size="small"
                label={tag} />
            ))}
          </div>
        </CardContent>
      {/**<CardActions>
        <Button
          size="small"
          color="primary"
          onClick={()=>handleEdit(data.id)}
        >编辑</Button>
        <TemplateCopy id={data.id} />
        </CardActions>**/}
    </Card>
  )
}

export default TemplateCard
