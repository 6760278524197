import React, { useState, useEffect } from 'react'
import { TablePagination, Typography, Grid } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import InputBase from '@material-ui/core/InputBase'
import Button from '@material-ui/core/Button'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/styles'
import { fetchTemplates } from '../api'
import TemplateCreate from './TemplateCreate'
import TemplateCard from '../components/TemplateCard'
import Layout from '../components/Layout'

const useStyles = makeStyles(theme => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom:10
  },
  container: {
    marginBottom: 16
  },
  card: {
    maxWidth: 345
  },
  input: {
    borderRadius: '5px',
    margin: '0 12px 6px 0',
    padding: '1px 12px',
  },
  search: {
    backgroundColor: '#3859FF',
    borderColor: '#3859FF',
    color: '#FEFEFF',
    width: '280px',
    maxWidth: '100%'
  },
  classify: {
    backgroundColor: '#E9ECFC',
    borderColor: '#E9ECFC',
    color: '#5D78FF'
  },
  button: {
    borderWidth: 1,
    padding: '4px 12px'
  },
  time:{
    backgroundColor: '#D5ECFF',
    borderColor: '#D5ECFF',
    color: '#3859FF'
  },
  pagination: {
    marginBottom: theme.spacing(1)
  }
}))

const TemplateList = () => {
  const classes = useStyles()
  const [page, setPage] = useState(0)
  const [data, setData] = useState({count:0,results:[]})
  const [query, setQuery] = useState({})
  const [filter, setFilter] = useState()
  const limit = 12

  useEffect(()=>{
    const fetchData = async() => {
      try {
        const res = await fetchTemplates(page, limit, filter)
        setData(res)
      } catch (error) {
        console.log('error')
      }
    }

    fetchData()
  }, [filter, page])

  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChange = (event, name=null) => {
    if(!name) {
      name = event.target.name
    }
    const value = event.target.value
    setQuery({...query, [name]:value})
  }

  const handleFilter = () => {
    const keys = Object.keys(query)
    let queryString = ''
    let i
    for(i=0; i<keys.length; i++) {
      if(query[keys[i]] !== undefined && query[keys[i]] !== '') {
        queryString += keys[i] + '=' + query[keys[i]] + '&'
      }
    }
    setFilter(queryString)
  }

  const breadcrumbs = [
    {name: '模板管理', link:'/templates/'}
  ]

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Box mb={2} display="flex" justifyContent="space-between">
        <Typography component="h2" variant="h6">模板管理</Typography>
        <TemplateCreate />
      </Box>
      <Box mb={2}>
        <InputBase
          name="search"
          className={`${classes.input} ${classes.search}`}
          style={{width:'280px',maxWidth:'100%'}}
          placeholder="请输入模板名称"
          value={query.search}
          onChange={handleChange}
        />
        <Select
          displayEmpty
          value={query.is_zone}
          onChange={(event)=>handleChange(event, "mode")}
          input={<InputBase className={`${classes.input} ${classes.classify}`} />}>
          <MenuItem>全部类型</MenuItem>
          <MenuItem value="pc">PC</MenuItem>
          <MenuItem value="phone">手机</MenuItem>
        </Select>
        <Button
          variant="outlined"
          className={classes.button}
          style={{marginRight:'6px'}}
          size='small'
          color="primary"
          onClick={handleFilter}
        >查询</Button>
        <Button
          size='small'
          variant="outlined"
          color="primary"
          className={classes.button}
          onClick={()=>window.location.reload()}
        >重置</Button>
      </Box>
      <Grid
        container
        spacing={1}
        className={classes.container}>
        {data.results.map(item => (
          <Grid item xs={12} sm={4} md={3} key={item.id}>
            <TemplateCard data={item} />
          </Grid>
        ))}
      </Grid>
      <TablePagination
        className={classes.pagination}
        rowsPerPageOptions={[limit]}
        component="div"
        count={data.count}
        rowsPerPage={limit}
        page={page}
        onChangePage={handleChangePage}
      />
    </Layout>
  )
}

export default TemplateList
