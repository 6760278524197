import React from 'react'
import { Redirect, Route } from 'react-router-dom'

const PrivateRoute = ({children, ...rest}) => (
  <Route
    {...rest}
      render={({location}) => localStorage.getItem('access') ? (
        children
      ) : (
        <Redirect
          to={{pathname:"/login", state:{from:location}}}
        />
      )}
  />
)

export default PrivateRoute
