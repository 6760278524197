import React from 'react'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import { fetchTemplateUpdate } from '../api'
import { useHistory, useParams } from 'react-router-dom'
import FrameGrid from '../components/FrameGrid'

const FrameChoice = () => {
  const { id } = useParams()
  const history = useHistory()

  const handleChoice = async(item=null) => {
    if(item === null) {
      return history.push(`/templates/${id}`)
    }
    const req = {
      mode: item.mode,
      content: item.content,
      bgimage: item.bgimage
    }
    try {
      await fetchTemplateUpdate(id, req)
      return history.push(`/templates/${id}`)
    } catch {
      alert('选择失败')
    }
  }

  return (
    <Container maxWidth="lg">
      <Typography
        variant="h4"
        component="h2"
        style={{margin:"16px 0"}}>选择框架</Typography>
      <FrameGrid choice onClickItem={handleChoice} />
    </Container>
  )
}

export default FrameChoice
