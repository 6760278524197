import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { fetchXPage, fetchOrder } from '../api'
import BlockShow from '../components/BlockShow'
import BlockTheme from '../components/BlockTheme'

/**
 * 落地页
 */
const XPage = () => {
  const { zone, medium, month, id } = useParams()
  const plan = `${zone}${medium}${month}${id}`
  const [data, setData] = useState()
  const [blocks, setBlocks] = useState([])
  const history = useHistory()
  useEffect(() => {
    const fetchData = async() => {
      try {
        const res = await fetchXPage(plan)
        setData(res)
        if(res.theme) {
          setBlocks(JSON.parse(res.theme.content))
        }
      } catch(error) {
        console.log('fetch xpage error')
        if(error.message === '404') {
          history.push('/404')
        }
      }
    }
    fetchData()
  }, [zone, medium, month, id, history, plan])
  if(!data) {
    return null
  }
  if(!data.theme) {
    return <h1>未关联模板</h1>
  }

  const handleSubmit = async(req) => {
    try {
      await fetchOrder({...req, plan:plan})
      alert('报名成功')
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <BlockTheme data={data.theme}>
    <BlockShow
      cars={data.cars}
      data={data.theme}
      blocklist={blocks}
      onSubmit={handleSubmit}
    />
    <div dangerouslySetInnerHTML={{__html:data.statistics}} />
  </BlockTheme>
  )
}

export default XPage
