import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { DatePicker } from '@material-ui/pickers'
import { fetchPlanCreate } from '../api'
import { useHistory } from 'react-router-dom'
import MediumSelectField from '../components/MediumSelectField'
import PlanTypeField from '../components/PlanTypeField'

const PlanCreate = ({open, setOpen}) => {
  const history = useHistory()
  const [data, setData] = useState({medium:'', start:'', is_zone:'true'})
  const [errors, setErrors] = useState({medium:false, start:false, is_zone:false})

  const handleChange = (event, name=null, value=null) => {
    let error = false
      if(name !== "start") {
        name = event.target.name
        value = event.target.value
      }
    if(name === 'medium' && value === '') {
      error = true
    } else if (name === 'start' && value === '') {
      error = true
    }
    setData({...data, [name]:value})
    setErrors({...errors, [name]:error})
  }

  const handleSubmit = async() => {
    try {
      const res = await fetchPlanCreate(data)
      history.push(`/plan/${res.id}`)
    } catch (error) {
      alert(error)
      console.log('create plan error')
    }
  }
  const handleClose = () => {
    setOpen(false)
  }

  const checkForm = () => {
    let check = true
    if(!data.medium || errors.medium) {
      check = false
    } else if (!data.start || errors.start){
      check = false
    }
    return check
  }

  return (
    <React.Fragment>
      <Dialog
        fullWidth
        maxWidth="xs"
        PaperProps={{elevation:0}}
        open={open}
        onClose={handleClose}>
        <DialogTitle>添加投放计划</DialogTitle>
        <DialogContent>
          <PlanTypeField
            defaultValue="true"
            onChange={handleChange}
          />
          <MediumSelectField
            fullWidth
            required
            margin="normal"
            onChange={handleChange}
          />
          <DatePicker
            label="开始日期"
            fullWidth
            required
            margin="normal"
            format="YYYY-MM-DD"
            value={data.start ? data.start : null}
            onChange={date=>handleChange(null, "start", date.format("YYYY-MM-DD"))}
            cancelLabel="取消"
            okLabel="确定"
          />
          {/*<TextField
            fullWidth
            required
            margin="normal"
            label="开始时间"
            name="start"
            type="date"
            InputLabelProps={{ shrink: true }}
            onChange={handleChange}
            />*/}
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleClose}
          >取消</Button>
          <Button
            disabled={!checkForm()}
            onClick={handleSubmit}
          >提交</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default PlanCreate
