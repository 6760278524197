import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
  inner: {
    height: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderBottomWidth: 5,
    borderBottomStyle: 'solid',
    borderBottomColor: props => props.color
  },
  icon: {
    textAlign: 'center',
    '& > img': {
      width: '35%',
    }
  }
}))

const SummaryCard = (props) => {
  const { title, total, icon, color, extra, onClick } = props
  const classes = useStyles({color:color})
  return (
    <Card className={classes.root} elevation={0}>
      <CardActionArea onClick={onClick} className={classes.root}>
      <CardContent className={classes.inner}>
        <Typography
          gutterBottom
          variant="body1"
          component="h6"
          align="left">{title}</Typography>
        <Box
          py={2}
          flex={1}
          display="flex"
          flexDirection="column"
          justifyContent="space-around">
        <div className={classes.icon}>
          <img src={icon} alt={title} />
        </div>
        <Typography
          variant="h5"
          align="center"
          compoent="h3">{total}</Typography>
        <Typography
          variant="body2"
          align="center"
          component="p">{extra}</Typography>
        </Box>
      </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default SummaryCard
