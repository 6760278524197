export const base_url = process.env.REACT_APP_API

/**
 * 刷新TOKEN
 */
export const refreshToken = async () => {
  const url = base_url + 'auth/token/refresh/'
  const res = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ refresh: localStorage.getItem('refresh') })
  })
  if (res.status !== 200) {
    window.location.href = "/login/"
  }
  const resJson = await res.json()
  localStorage.setItem('access', resJson.access)
  return resJson.access
}

export const jFetch = async (url, data = null, method = 'GET', auth = true) => {
  const request = () => {
    let options = {}
    let headers = {}
    if (auth) {
      headers['Authorization'] = 'Bearer ' + localStorage.getItem('access')
    }
    options['mode'] = 'cors'
    options['method'] = method
    //if(method in ['POST', 'PUT', 'PATCH']) {
    if (data) {
      options['body'] = JSON.stringify(data)
    }
    headers['Content-Type'] = 'application/json'
    options['headers'] = headers
    return fetch(base_url + url, options)
  }

  let res = await request()
  if (res.status === 401) {
    if (url === 'auth/token/') {
      throw new Error("登录失败")
    }
    await refreshToken()
    res = await request()
  } else if (res.status === 500) {
    throw new Error("服务器异常")
  } else if (res.status === 403) {
    throw new Error("方法不允许")
  } else if (res.status === 404) {
    throw new Error(404)
  } else if (res.status === 400) {
    const resJson = await res.json()
    let message = ''
    if (resJson.detail) {
      message = resJson.detail
    } else {
      const values = Object.values(resJson)
      if (typeof (message[0]) === 'object') {
        message = values[0][0]
      } else {
        message = values[0]
      }
    }

    throw new Error(message[Object.keys(message)[0]])
  } else if (res.status === 204) {
    return res
  }
  const resJson = await res.json()
  return resJson
}

export const fetchUsers = () => jFetch('auth/user/')
export const fetchUser = (id, data = {}, methods = 'get') => { return jFetch(`auth/user/${id}/`, data, methods) }
export const fetchProfile = () => jFetch('auth/profile/')
export const fetchWallet = () => jFetch('wallet/')
export const fetchWalletLog = (page) => {
  const limit = page.perPage
  const offset = limit * page.page
  return jFetch('wallet/log/?limit=' + limit + '&offset=' + offset)
}
export const fetchPassword = (data) => jFetch('auth/password/', data, 'POST')
export const fetchPasswordReset = (data) => jFetch('auth/password/reset/', data, 'POST')

export const fetchTemplates = (page, limit = 12, query) => {
  const offset = limit * page
  return jFetch(`themes/?offset=${offset}&limit=${limit}&${query}`)
}
export const fetchTemplateCreate = (data) => jFetch('themes/', data, 'POST')
export const fetchTemplateDetail = (id) => jFetch(`themes/${id}/`)
export const fetchTemplateUpdate = (id, data) => {
  let cleaned = Object.assign(data)
  //if (!cleaned.cover || cleaned.cover.match(/^http/)) {
  //  delete cleaned['cover']
  //}
  //if (!cleaned.bgimage || cleaned.bgimage.match(/^http/)) {
  //  delete cleaned['bgimage']
  //}
  return jFetch(`themes/${id}/`, cleaned, 'PATCH')
}
export const fetchTemplateDelete = (id) => jFetch(`themes/${id}/`, null, 'DELETE')
export const fetchTemplateCopy = (id) => jFetch(`themes/copy/${id}/`)
export const fetchTemplatePublish = (data) => jFetch('themes/publish/', data, 'POST')

//export const fetchCities = (page) => jFetch('cities/?page=' + page)
export const fetchCities = (page, limit = 12) => {
  const offset = limit * page
  return jFetch(`cities/?offset=${offset}&limit=${limit}`, null, 'GET', false)
}
export const fetchMediumList = (page, limit=12, filter=null) => {
  const offset = limit * page

  return jFetch(`mediums/?limit=${limit}&offset=${offset}&${filter}`)
}
export const fetchMediumDetail = (id) => jFetch(`mediums/${id}/`)
export const fetchMediumCreate = (data) => jFetch('mediums/', data, 'POST')
export const fetchMediumUpdate = (id, data) => jFetch(`mediums/${id}/`, data, 'PATCH')
export const fetchMediumDelete = (id) => jFetch(`mediums/${id}/`, null, 'DELETE')
export const fetchCueList = (page, limit = 12, query = null, ordering=null) => {
  const offset = limit * page
  return jFetch(`cues/?offset=${offset}&limit=${limit}&ordering=${ordering}&${query}`)
}
export const fetchCueDetail = (id) => jFetch(`cues/${id}/`)
export const fetchCueDelete = (id) => jFetch(`cues/${id}/`, null, 'DELETE')
export const fetchCueCreate = (data) => jFetch(`cues/`, data, 'POST')
export const fetchCueUpdate = (id, data) => jFetch(`cues/${id}/`, data, 'PATCH')
export const fetchCueExport = `${base_url}cues/export/`
export const fetchCueLogList = (page, limit=12, query=null) => {
  const offset = limit * page
  return jFetch(`cues/logs/?offset=${offset}&limit=${limit}&${query}`)
}

export const fetchMediumPositionList = (query) => {
  return jFetch(`mediums/page/?${query}`)
}
export const fetchMediumPositionCreate = (data) => jFetch('mediums/page/', data, 'POST')
export const fetchMediumPositionUpdate = (id, data) => jFetch(`mediums/page/${id}/`, data, 'PATCH')
export const fetchMediumPositionDelete = (id) => jFetch(`mediums/page/${id}/`, null, 'DELETE')
export const fetchMediumItemList = (page) => {
  return jFetch(`mediums/item/?page=${page}`)
}
export const fetchMediumItemCreate = (data) => jFetch('mediums/item/', data, 'POST')
export const fetchMediumItemUpdate = (id, data) => jFetch(`mediums/item/${id}/`, data, 'PATCH')
export const fetchMediumItemDelete = (id) => jFetch(`mediums/item/${id}/`, null, 'DELETE')


export const fetchDealerList = (page, limit = 12, query = null) => {
  const offset = limit * page
  return jFetch(`stores/?offset=${offset}&limit=${limit}&${query}`, null, 'GET', false)
}
export const fetchDealerDetail = (id) => jFetch(`stores/${id}/`)
export const fetchDealerCreate = (data) => jFetch('stores/', data, 'POST')
export const fetchDealerUpdate = (id, data) => jFetch(`stores/${id}/`, data, 'PATCH')
export const fetchDealerDelete = (id) => jFetch(`stores/${id}/`, null, 'DELETE')
export const fetchDealerLogList = (page, limit=12, query=null) => {
  const offset = limit * page
  return jFetch(`stores/logs/?offset=${offset}&limit=${limit}&${query}`)
}

// 投放计划
export const fetchPlanList = (page, limit = 12, query = null) => {
  const offset = limit * page
  return jFetch(`plans/?offset=${offset}&limit=${limit}&${query}`)
}
export const fetchPlanCreate = (data) => jFetch('plans/', data, 'POST')
export const fetchPlanUpdate = (id, data) => jFetch(`plans/${id}/`, data, 'PATCH')
export const fetchPlanDetail = (id) => jFetch(`plans/${id}/`)
export const fetchPlanDelete = (id) => jFetch(`plans/${id}/`, null, 'DELETE')
// 点位计划
export const fetchPlanItemList = (page, limit = 12, query = '') => {
  const offset = limit * page
  return jFetch(`plans/item/?offset=${offset}&limit=${limit}&${query}`)
}
export const fetchPlanItemCreate = (data) => jFetch('plans/item/', data, 'POST')
export const fetchPlanItemDelete = (id) => jFetch(`plans/item/${id}`, null, 'DELETE')
export const fetchPlanItemUpdate = (id, data) => jFetch(`plans/item/${id}/`, data, 'PATCH')
export const fetchPlanItemDetail = (id) => jFetch(`plans/item/${id}/`)

// 落地页
export const fetchPreview = (id) => jFetch(`themes/preview/${id}/`, null, 'GET', false)
export const fetchXPage = (plan) => jFetch(`plans/xpage/${plan}/`, null, 'GET', false)
export const fetchOrder = (data) => jFetch('cues/order/', data, 'POST', false)

export const fetchLogin = (data) => jFetch('auth/token/', data, 'POST', false)

/**
 * chart, summary
 */
export const fetchCueChart = () => jFetch('cues/data/')
export const fetchCueSummary = () => jFetch('cues/summary/')
export const fetchPlanSummary = () => jFetch('plans/summary/')
export const fetchDealerSummary = () => jFetch('stores/summary/')
export const fetchThemeSummary = () => jFetch('themes/summary/')
export const fetchMediumRank = () => jFetch('cues/medium_rank/')

export const fetchUserList = (page, limit=12, filter=null) => {
  const offset = limit * page

  return jFetch(`auth/user/?limit=${limit}&offset=${offset}&${filter}`)
}
export const fetchUserDetail = (id) => jFetch(`auth/user/${id}/`)
export const fetchUserCreate = (data) => jFetch('auth/user/', data, 'POST')
export const fetchUserUpdate = (id, data) => jFetch(`auth/user/${id}/`, data, 'PATCH')
export const fetchUserDelete = (id) => jFetch(`auth/user/${id}/`, null, 'DELETE')

export const fetchAttach = (data) => jFetch(`attachments/`, data, 'POST')

export const fetchGroupList = (page, limit=12, filter=null) => {
  const offset = limit * page

  return jFetch(`auth/group/?limit=${limit}&offset=${offset}&${filter}`)
}
export const fetchGroupDetail = (id) => jFetch(`auth/group/${id}/`)
export const fetchGroupCreate = (data) => jFetch('auth/group/', data, 'POST')
export const fetchGroupUpdate = (id, data) => jFetch(`auth/group/${id}/`, data, 'PATCH')
export const fetchGroupDelete = (id) => jFetch(`auth/group/${id}/`, null, 'DELETE')

export const fetchCityList = (page, limit=12, filter) => {
  const offset = limit * page

  return jFetch(`cities/?limit=${limit}&offset=${offset}&${filter}`)
}
export const fetchCityDetail = (id) => jFetch(`cities/${id}/`)
export const fetchCityCreate = (data) => jFetch('cities/', data, 'POST')
export const fetchCityUpdate = (id, data) => jFetch(`cities/${id}/`, data, 'PATCH')
export const fetchCityDelete = (id) => jFetch(`cities/${id}/`, null, 'DELETE')



export const fetchCarList = (page, limit=12, filter) => {
  const offset = limit * page

  return jFetch(`cars/?limit=${limit}&offset=${offset}&${filter}`)
}
export const fetchCarDetail = (id) => jFetch(`cars/${id}/`)
export const fetchCarCreate = (data) => jFetch('cars/', data, 'POST')
export const fetchCarUpdate = (id, data) => jFetch(`cars/${id}/`, data, 'PATCH')
export const fetchCarDelete = (id) => jFetch(`cars/${id}/`, null, 'DELETE')



export const fetchTipList = (page, limit=12, filter) => {
  const offset = limit * page

  return jFetch(`tips/?limit=${limit}&offset=${offset}&${filter}`)
}
export const fetchTipDetail = (id) => jFetch(`tips/${id}/`)
export const fetchTipCreate = (data) => jFetch('tips/', data, 'POST')
export const fetchTipUpdate = (id, data) => jFetch(`tips/${id}/`, data, 'PATCH')
export const fetchTipDelete = (id) => jFetch(`tips/${id}/`, null, 'DELETE')



export const fetchFeedbackList = (page, limit=12, filter) => {
  const offset = limit * page

  return jFetch(`feedback/?limit=${limit}&offset=${offset}&${filter}`)
}
export const fetchFeedbackDetail = (id) => jFetch(`feedback/${id}/`)
export const fetchFeedbackCreate = (data) => jFetch('feedback/', data, 'POST')
export const fetchFeedbackUpdate = (id, data) => jFetch(`feedback/${id}/`, data, 'PATCH')
export const fetchFeedbackDelete = (id) => jFetch(`feedback/${id}/`, null, 'DELETE')



export const fetchCueStatusList = (page, limit=12, filter) => {
  const offset = limit * page

  return jFetch(`cues/status/?limit=${limit}&offset=${offset}&${filter}`)
}
export const fetchCueStatusDetail = (id) => jFetch(`cues/status/${id}/`)
export const fetchCueStatusCreate = (data) => jFetch('cues/status/', data, 'POST')
export const fetchCueStatusUpdate = (id, data) => jFetch(`cues/status/${id}/`, data, 'PATCH')
export const fetchCueStatusDelete = (id) => jFetch(`cues/status/${id}/`, null, 'DELETE')



export const fetchFrameList = (page, limit=12, filter) => {
  const offset = limit * page

  return jFetch(`themes/frame/?limit=${limit}&offset=${offset}&${filter}`)
}
export const fetchFrameDetail = (id) => jFetch(`themes/frame/${id}/`)
export const fetchFrameCreate = (data) => jFetch('themes/frame/', data, 'POST')
export const fetchFrameUpdate = (id, data) => jFetch(`themes/frame/${id}/`, data, 'PATCH')
export const fetchFrameDelete = (id) => jFetch(`themes/frame/${id}/`, null, 'DELETE')


