import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { useParams, useRouteMatch } from 'react-router-dom'
import { fetchPlanDetail, fetchPlanItemCreate, fetchPlanItemUpdate, fetchPlanItemDetail } from '../api'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import Filtrate from '../components/Filtrate';
import { RenderBasic, RenderArea, RenderSuperior, RenderTime } from '../components/PointForm'

const RenderHead = () => {
  return (
    <Filtrate title='点位计划' sumbitText='保存'></Filtrate>
  )
}
const PointCreate = () => {
  const { register, errors, handleSubmit, control } = useForm()
  const [detail, setDetail] = useState(null)
  const [planDetail, setPlanDetail] = useState({})
  const [selects, setSelects] = useState({})
  const history = useHistory()
  const { id } = useParams()
  const { path } = useRouteMatch()
  let planId = id ? path.slice(12, path.length - 4) : path.slice(12, path.length - 7)
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchPlanDetail(planId)
        setDetail(res)
        if (id) {
          const planDetail = await fetchPlanItemDetail(id)
          setPlanDetail(planDetail)
        }
      } catch (error) {
        setPlanDetail({})
        console.error(error)
      }
    }

    fetchData()
  }, [id, planId])
  const onSubmit = async (data) => {
    let extra = { ...data }
    const form = {
      plan: detail.number,
      position: selects.position,
      style: selects.style,
      cars: selects.cars,
      extra: extra
    }
    if (id) {      //更新
      try {
        await fetchPlanItemUpdate(id, form)
        history.goBack()
      } catch (error) {
        console.error(error)
      }
    } else {      //新建
      try {
        await fetchPlanItemCreate(form)
        history.goBack()
      } catch (error) {
        console.error(error)
      }
    }

  }
  const getTimeData = (e) => {
    console.log('123er');
  }

  return (
    <Grid container>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <RenderHead />
        <RenderBasic
          detail={detail}
          planDetail={planDetail}
          control={control}
          selects={selects}
          register={register}
          errors={errors}
          setSelects={setSelects} />
        {detail ? (detail.is_zone ? <RenderArea register={register} errors={errors} planDetail={planDetail} /> : <RenderSuperior register={register} errors={errors} planDetail={planDetail} />) : null}
        {/* <RenderTime getTimeData={getTimeData}/> */}
      </form>
    </Grid>
  )
}

export default PointCreate
