import React from 'react'
import { useRouteMatch, Link, Switch  } from 'react-router-dom'
import PrivateRoute from '../components/PrivateRoute'

import GroupList from './GroupList'
import GroupCreate from './GroupCreate'
import GroupUpdate from './GroupUpdate'

const Group = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <GroupList />
      </PrivateRoute>
      <PrivateRoute path={`${path}/create`}>
        <GroupCreate />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:id`}>
        <GroupUpdate />
      </PrivateRoute>
    </Switch>
  )
}

export default Group
