import React, { useState, useEffect } from 'react'
import { Typography, Grid, Card, makeStyles } from '@material-ui/core'
import { useParams, useHistory } from 'react-router-dom'
import MediumForm from '../components/MediumForm'
import { fetchMediumDelete, fetchMediumDetail, fetchMediumUpdate } from '../api'
import MediumPosition from './MediumPosition'
import DeleteButton from '../components/DeleteButton'
import AddIcon from '@material-ui/icons/Add';
import Filtrate from '../components/Filtrate'
import Layout from '../components/Layout'

const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: 20
  },
  head: {
    height: 60,
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #EBEEF5'
  },
  title: {
    fontSize: 18,
    lineHeight: 3.2,
    paddingLeft: 20,
    flex: 1
  },
  rightBtn: {
    height: 20,
    width: 20,
    fontSize: 15,
    lineHeight: 1.26,
    backgroundColor: theme.palette.orange.light,
    color: theme.palette.orange.dark,
    cursor: 'pointer',
    textAlign: 'center',
    borderRadius: 10,
    margin: 20
  },
  mainBox: {
    padding: '20px 0'
  }
}))
const MediumUpdate = () => {
  const { id } = useParams()
  const [data, setData] = useState()
  const [positions, setPositions] = useState([])
  const history = useHistory()
  const classes = useStyles()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchMediumDetail(id)
        setData(res)
        setPositions(res.page_set)
      } catch (error) {
        console.log('error')
      }
    }

    fetchData()
  }, [id, setData])

  const handleSubmit = async (data) => {
    try {
      await fetchMediumUpdate(id, data)
      history.push('/medium')
    } catch {
      alert('操作失败')
    }
  }

  const handleDelete = async () => {
    try {
      await fetchMediumDelete(id)
      history.push('/medium')
    } catch (error) {
      console.log(error)
      alert('操作失败')
    }
  }

  const handleAddMore = () => {
    setPositions([...positions, {}])
  }
  const handlePositionDelete = (index) => {
    setPositions(positions.filter((item, i) => index !== i))
  }

  const Actions = () => (
    <Filtrate title='媒体管理' otherSub={<>
      <DeleteButton
        variant="outlined"
        style={{
          margin: 5,
        }}
        onClick={handleDelete}>
        删除
    </DeleteButton></>} sumbitText='保存'></Filtrate>
  )
  if (!data) {
    return <></>
  }
  const breadcrumbs = [
    {name:'媒体管理',link:'/medium'},
    {name:data.name}
  ]
  return (
    <Layout breadcrumbs={breadcrumbs}>
      {data ? (
        <MediumForm
          initData={data}
          formActions={<Actions />}
          onSubmit={handleSubmit} />
      ) : null}
      <Card className={classes.card} elevation={0}>
        <Grid container>
          <Grid item xs={12} sm={3} className={classes.head}>
            <Typography className={classes.title}>广告位置</Typography>
            <Typography onClick={handleAddMore} className={classes.rightBtn} color="primary"><AddIcon></AddIcon></Typography>
          </Grid>
          <Grid item sm={9} className={classes.head} style={{ borderLeft: '1px solid #EBEEF5' }}>
            <Typography className={classes.title}>广告形式</Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.mainBox}>
          {positions.map((item, index) => (
            <MediumPosition
              key={index}
              index={index}
              mediumId={id}
              data={item}
              onDelete={handlePositionDelete}
            />
          ))}
        </Grid>
      </Card>
    </Layout>
  )
}

export default MediumUpdate
