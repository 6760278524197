import React, { useState, useEffect } from 'react'
import { Box, Button, Grid, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import { fetchPlanDetail, fetchPlanDelete, fetchPlanUpdate } from '../api';

import DeleteButton from '../components/DeleteButton';
import Layout from '../components/Layout'
import PlanForm from '../components/PlanForm'
import PlanItemList from './PlanItemList'
import PlanItemCreate from './PlanItemCreate'

const useStyles = makeStyles(theme => ({
  actions: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row-reverse',
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },
}))
const PlanDetail = () => {
  const [data, setData] = useState()
  const [open, setOpen] = useState(false)
  const [refresh, setRefresh] = useState(0)
  const { id } = useParams()
  const history = useHistory()
  const classes = useStyles()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchPlanDetail(id)
        setData({...res,
          is_zone:res.is_zone.toString()})
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
  }, [id, refresh])

  const handleChange = event => {
    let name = event.target.name
    let value
    value = event.target.value
    if (name === 'extra_inner') {
      name = 'inner'
      setData({...data, extra:{...data.extra, [name]:value}})
    } else {
      setData({...data, [name]:value})
    }
  }

  const handleDelete = async () => {
    try {
      await fetchPlanDelete(id)
      history.goBack()
    } catch (error) {
      console.error(error)
    }
  }

  const handleSubmit = async() => {
    const req = {
      medium: data.medium,
      is_zone: data.is_zone === "true" ? true : false,
      start: data.start,
      extra: data.extra
    }
    try {
      await fetchPlanUpdate(id, req)
      alert('保存成功')
    } catch {
      alert('保存失败')
    }
  }

  const handleClose = () => {
    setOpen(false)
    setRefresh(refresh+1)
  }

  if(!data) {
    return (
      <Layout />
    )
  }

  const breadcrumbs = [
    { name: '投放计划', link: '/plan' },
    { name: data.number },
  ]

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <div className={classes.actions}>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={handleSubmit}>保存</Button>
        <Button
          size="small"
          variant="outlined"
          color="primary"
          onClick={()=>setOpen(true)}
        >添加点位计划</Button>
        <DeleteButton
          variant='outlined'
          onClick={handleDelete} />
      </div>

      <Grid container>
        <Grid item xs={12}>
          <Box mb={1}>
          <ExpansionPanel elevation={0} expanded={true}>
            <ExpansionPanelSummary>
              <Typography variant="h6" component="h5">计划信息</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <PlanForm
                data={data}
                onChange={handleChange} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Box>
        </Grid>

        <Grid item xs={12}>
          <Box mb={3}>
          <ExpansionPanel elevation={0} expanded={true}>
            <ExpansionPanelSummary>
              <Typography variant="h6" component="h5">点位计划列表</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <PlanItemList plan={data} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Box>
        </Grid>
      </Grid>
      <PlanItemCreate
        plan={data}
        open={open}
        onClose={handleClose} />
    </Layout>
  )
}

export default PlanDetail
