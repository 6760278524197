import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { ThemeProvider } from '@material-ui/core'
import MomentUtils from '@date-io/moment';
import moment from "moment"
import "moment/locale/zh-cn"
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import Home from './Home'
import Plan from './Plan'
import Profile from './Profile'
import Login from './Login'
import PrivateRoute from '../components/PrivateRoute'
import theme from '../theme'

import Templates from './Templates'
import TemplateCreateFull from './TemplateCreateFull'
import City from './City'
import Dealers from './Dealers'
import Car from './Car'
import Tip from './Tip'
import Feedback from './Feedback'
import Medium from './Medium'
import Users from './Users'
import Cue from './Cue'
import Frame from './Frame'
import FrameChoice from './FrameChoice'
import CueStatus from './CueStatus'
import User from './User'
import Group from './Group'
import PasswordReset from './PasswordReset'

import Preview from './Preview'
import XPage from './XPage'
import PageNotFound from './PageNotFound'

moment.locale("zh-cn")

const Index = () => {
  return (
    <MuiPickersUtilsProvider
        libInstance={moment}
        utils={MomentUtils}
        locale="zh-cn">
    <ThemeProvider theme={theme}>
      <Router>
        <Switch>
          <PrivateRoute exact path="/">
            <Home />
          </PrivateRoute>
          <Route path="/preview/:id">
            <Preview />
          </Route>
          <PrivateRoute path="/plan">
            <Plan />
          </PrivateRoute>
          <PrivateRoute path="/profile">
            <Profile />
          </PrivateRoute>
          <PrivateRoute path="/template-create/:id">
            <TemplateCreateFull />
          </PrivateRoute>
          <PrivateRoute path="/templates">
            <Templates />
          </PrivateRoute>
          <PrivateRoute path="/dealers/">
            <Dealers />
          </PrivateRoute>
          <PrivateRoute path="/city">
            <City />
          </PrivateRoute>
          <PrivateRoute path="/medium">
            <Medium />
          </PrivateRoute>
          <PrivateRoute path="/feedback">
            <Feedback />
          </PrivateRoute>
          <PrivateRoute path="/car">
            <Car />
          </PrivateRoute>
          <PrivateRoute path="/tip">
            <Tip />
          </PrivateRoute>
          <PrivateRoute path="/cue">
            <Cue />
          </PrivateRoute>
          <PrivateRoute path="/frame">
            <Frame />
          </PrivateRoute>
          <PrivateRoute path="/frame-choice/:id">
            <FrameChoice />
          </PrivateRoute>
          <PrivateRoute path="/cuestatus">
            <CueStatus />
          </PrivateRoute>
          <PrivateRoute path="/password_reset/:user">
            <PasswordReset />
          </PrivateRoute>
          <PrivateRoute path="/users">
            <Users />
          </PrivateRoute>
          <PrivateRoute path="/user">
            <User />
          </PrivateRoute>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/group">
            <Group />
          </Route>
          <Route path="/:zone/:medium/:month/:id">
            <XPage />
          </Route>
          <Route path="*">
            <PageNotFound />
          </Route>
        </Switch>
      </Router>
    </ThemeProvider>
    </MuiPickersUtilsProvider>
  )
}

export default Index
