/**
 * 日历相关
 */

/**
 * 获取本月日期
 */
export const getDaysOfMonth = (date=new Date()) => {
  let days = []
  let i
  let d
  const year = date.getFullYear()
  const month = date.getMonth()

  for (i=1;i<32;i++) {
    d = new Date(year, month, i)
    if(d.getMonth() === month) {
      days.push(d)
    }
  }
  return days
}

/**
 * 获取日历数据
 */
export const getCalendar = (date = new Date()) => {
  const year = date.getFullYear()
  const month = date.getMonth()

  // 获取1号位移
  let offset = (new Date(year, month, 1)).getDay()
  if(offset === 0) {
    offset = 7
  }

  let days = []
  let i, j
  let c

  /**
   * 补全首行
   */
  for(j=offset-1; j>0; j--) {
    days.push(null)
  }

  /**
   * 补全剩余5行
   */
  for(i=1; i<=7*6-offset-1; i++) {
    c = new Date(year, month, i)
    if(c.getMonth() !== month) {
      c = null
    }
    days.push(c)
  }
  return days
}
