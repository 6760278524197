import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import { fetchTemplateCopy } from '../api'
import { useHistory } from 'react-router-dom'

const TemplateCopy = ({id}) => {
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const handleSubmit = async() => {
    try {
      const res = await fetchTemplateCopy(id)
      history.push(`/templates/${res.id}`)
    } catch {
      console.log('copy templat error')
    }
  }
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <React.Fragment>
      <MenuItem onClick={handleOpen}>复制</MenuItem>
      {/**<Button size="small" color="primary" onClick={handleOpen}>
        复制
        </Button>**/}
      <Dialog
        fullWidth
        maxWidth="xs"
        PaperProps={{ elevation: 0 }}
        open={open}
        onClose={handleClose}>
        <DialogTitle id="copy-dialog-title">复制模板?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            把当前模板复制为一个新模板
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">取消</Button>
          <Button onClick={handleSubmit} color="primary">确定</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default TemplateCopy
