import React, { useEffect, useState } from 'react'
import { TableContainer } from '@material-ui/core'
import Tables from '../components/Tables';
import { useRouteMatch, useHistory, useParams } from 'react-router-dom'
import { fetchPlanItemList, fetchPlanDetail } from '../api'
import Filtrate from '../components/Filtrate';
let headCells = [
  { id: 'number', label: '编号' },
  { id: 'style_name', label: '广告形式' },
  { id: 'cars', label: '车型' },
  { id: 'theme_name', label: '关联模板' }
];

const getItem = (a, b, c) => { return parseFloat(a.substring(b, c)) }
const getBegin = (arr, begin, end) => {
  let times
  arr.map((item1) => {
    times = getItem(item1.date, 6)
    if (times < begin) { begin = times }
    if (times > end) { end = times }
    return times
  })
  return [begin, end]
}
const getLangDate = (e, begin, end, label1, label2) => {
  label1 = `排期${getItem(e[0][0].date, 4, 6)}月`
  label2 = `排期${getItem(e[1][0].date, 4, 6)}月`
  let time1 = getBegin(e[0], begin, end)
  let time2 = getBegin(e[1], begin, end)
  console.log(label1, label2, time1, time2);
  return [
    { label: label1, id: 'row', begin: time1[0], end: time1[1] },
    { label: label2, id: 'row2', begin: time2[0], end: time2[1] },
  ]
}

const PointList = () => {
  const [data, setData] = useState({ count: 0, results: [] })
  const [page, setPage] = useState(0)
  const { url } = useRouteMatch()
  const history = useHistory()
  const [detail, setDetail] = useState({})
  const { id } = useParams()

  let limit = 10
  console.log(data);
  if (data.results.length) {
    let begin = 31, end = 0, label1, label2
    let val = data.results.map((item, index) => {
      if (item.dates) {
        let t = getLangDate(item.dates, begin, end, label1, label2)
        console.log('ttt', t);
        headCells = [...headCells, ...t]
        return headCells
      }
    })

    console.log(val);
  }
  useEffect(() => {
    const fetchData = async () => {
      const query = `plan=${id}`
      try {
        const res = await fetchPlanItemList(page, limit, query)
        const res3 = await fetchPlanDetail(id)
        setDetail(res3)
        setData(res)
      } catch (error) {
        console.error(error)
      }
    }

    fetchData()
  }, [page])
  return (
    <TableContainer>
      <Filtrate title='点位计划'
        addTitle='增加点位计划'
        create={() => history.push(`${url}/create`)}></Filtrate>
      <Tables headCells={headCells} data={data} setPages={(e) => setPage(e)} handle={{title:'预览',path:true}} />
    </TableContainer>
  )
}

export default PointList
