import React, { useEffect, useState } from 'react'
import { useTheme, makeStyles } from '@material-ui/styles'
import { Menu, MenuItem, Container, Button, Typography, AppBar, Toolbar } from '@material-ui/core'
import { useHistory, Link } from 'react-router-dom'
import { fetchProfile } from '../api'

const useStyles = makeStyles({
  barBack: {
    backgroundColor: '#242333'
  },
  barBox: {
    backgroundColor: '#1e1e29',
    display: 'flex'
  },
  barItem: {
    color: "#f1f1f1",
    padding: 3,
    flex: 1,
    paddingLeft: 15,
    paddingRight: 15,
    margin: '6.5px 5px'
  },
  toolbar: {
    padding: 0
  },
  title: {
    flex: 1,
    color: 'white',
    textDecoration: 'none',
    fontWeight: 'bold'
  },
  poporMenu: {
    '& .MuiPaper-root': {
      borderRadius: 2,
      overflow: 'hidden'
    }
  }
})

const Topbar = (props) => {
  const theme = useTheme()
  const classes = useStyles()
  let history = useHistory()
  const [user, setUser] = useState(null)

  const [anchorEl, setAnchorEl] = React.useState(null)
  const systemEl = anchorEl && anchorEl.systemEl ? anchorEl.systemEl : null
  const menuEl = anchorEl && anchorEl.menuEl ? anchorEl.menuEl : null
  const handleMenu = (type, event) => {
    setAnchorEl({ ...anchorEl, [type]: event.currentTarget })
  }
  const handleClose = (type) => {
    setAnchorEl({ ...anchorEl, [type]: null })
  }

  const handleLink = (path) => {
    history.push(path)
  }

  const handleLogout = () => {
    localStorage.clear()
    history.replace("/login")
  }
  useEffect(() => {
    const getProfile = async () => {
      try {
        const res = await fetchProfile()
        setUser(res.username)
      } catch (error) {
        console.error(error)
      }
    }
    getProfile()
  }, [])

  const links = [
    { name: '仪表盘', path: '/' },
    { name: '投放计划', path: '/plan' },
    { name: '框架管理', path: '/frame' },
    { name: '模板管理', path: '/templates' },
    { name: '线索管理', path: '/cue' },
    { name: '经销商管理', path: '/dealers' },
  ]
  const system = {
    name: '系统管理', children: [
      { name: '用户管理', path: '/user' },
      { name: '用户分组', path: '/group' },
      { name: '媒体管理', path: '/medium' },
      { name: '城市管理', path: '/city' },
      { name: '车型管理', path: '/car' },
      { name: '小贴士', path: '/tip' },
      { name: '线索状态', path: '/cuestatus' },
      { name: '反馈信息', path: '/feedback' }
    ]
  }

  /**
   * 判断是否active
   */
  const checkActive = (path) => {
    const current = history.location.pathname
    if (path === '/' && current === '/') {
      return true
    }
    const re = new RegExp(path + '*')
    if (path !== '/' && current.match(re)) {
      return true
    }
    return false
  }
  return (
    <AppBar position="sticky" className={classes.barBack}>
      <Container maxWidth={props.maxWidth ? props.maxWidth : "lg"}>
        <Toolbar className={classes.toolbar}>
          <Typography variant="h6" noWrap
            component={Link}
            to="/"
            className={classes.title}>{process.env.REACT_APP_TITLE}</Typography>
          <Button id="menuEl" color="inherit" onClick={(e) => handleMenu('menuEl', e)}>{user ? user : ''}</Button>
          <Menu id='menuEl'
            open={Boolean(menuEl)}
            onClose={() => handleClose('menuEl')}
            anchorEl={menuEl}
            className={classes.poporMenu}
            transformOrigin={{ vertical: -40, horizontal: 'right' }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            <MenuItem onClick={() => handleLink('/users')}>我的信息</MenuItem>
            <MenuItem onClick={() => handleLink('/users/password')}>修改密码</MenuItem>
            <MenuItem onClick={handleLogout}>退出</MenuItem>
          </Menu>
        </Toolbar>
      </Container>
      <div className={classes.barBox}>
        <Container maxWidth={props.maxWidth}
          style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
          {links.map((item, index) => (
            <Button key={index}
              className={classes.barItem}
              style={checkActive(item.path) ? { backgroundColor: '#3859FF' } : {}}
              component={Link}
              to={item.path}
            >{item.name}
            </Button>
          ))}
          <Button
            className={classes.barItem}
            onClick={(e) => handleMenu('systemEl', e)}
            aria-describedby={'user-actions'}
            style={history.location.pathname === '/car' ||
              history.location.pathname === '/cities' ||
              history.location.pathname === '/user' ||
              history.location.pathname === '/group' ||
              history.location.pathname === '/cluestatus' ||
              history.location.pathname === '/medium' ||
              history.location.pathname === '/tip' ||
              history.location.pathname === '/feedback'
              ? { backgroundColor: '#3859FF' } : {}}
          >
            {system.name}
          </Button>
          <Menu id="user-actions"
            open={Boolean(systemEl)}
            onClose={() => handleClose('systemEl')}
            anchorEl={systemEl}
            className={classes.poporMenu}
            transformOrigin={{ vertical: -40, horizontal: 'right' }}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
            {system.children.map((item, index) => (
              <MenuItem onClick={() => handleLink(item.path)} key={index}>{item.name}</MenuItem>
            ))}
          </Menu>
        </Container>
      </div>
    </AppBar>
  )
}

export default Topbar
