import React from 'react'
import { useRouteMatch, Link, Switch  } from 'react-router-dom'
import PrivateRoute from '../components/PrivateRoute'

import FeedbackList from './FeedbackList'
import FeedbackCreate from './FeedbackCreate'
import FeedbackUpdate from './FeedbackUpdate'

const Feedback = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <FeedbackList />
      </PrivateRoute>
      <PrivateRoute path={`${path}/create`}>
        <FeedbackCreate />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:id`}>
        <FeedbackUpdate />
      </PrivateRoute>
    </Switch>
  )
}

export default Feedback
