import React from 'react'
import { Container, Typography } from '@material-ui/core'

const PageNotFound = () => {
  return (
    <Container maxWidth="sm">
      <Typography variant="h1" component="h1">404</Typography>
    </Container>
  )
}

export default PageNotFound
