import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { makeStyles } from '@material-ui/styles'
import { Link, useParams } from 'react-router-dom'
import Layout from '../components/Layout'
import FrameForm from '../components/FrameForm'
import { fetchFrameDetail } from '../api'

const useStyles = makeStyles(theme => ({
  actions: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row-reverse',
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },
}))

const FrameUpdate = () => {
  const { id } = useParams()
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({name:false})
  const classes = useStyles()

  useEffect(()=>{
    const fetchData = async() => {
      try {
        const res = await fetchFrameDetail(id)
        setData(res)
      } catch (error) {
        console.log('error')
      }
    }

    fetchData()
  }, [id])

  const handleChange = event => {
    let error = false
    const name = event.target.name
    const value = event.target.value
    if(name === 'name' && (value.length < 2 || value.length > 50)) {
      error = true
    }
    setData({...data, [name]:value})
    setErrors({...errors, [name]:error})
  }

  const breadcrumbs = [
    { name: "框架管理", link: '/frame' },
    { name: data ? data.name : data },
  ]

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <div className={classes.actions}>
        <Button
          size="small"
          variant="contained"
          color="primary"
          endIcon={<ChevronRightIcon />}
          component={Link}
          to={`/template-create/${id}`}>去使用</Button>
      </div>
      <Grid container spacing={2} style={{marginBottom:16}}>
        <Grid item xs={12} sm={6}>
          <Paper style={{padding:"24px"}}>
          <FrameForm
            data={data}
            errors={errors}
            onChange={handleChange}
          />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper style={{padding:16,maxHeight:720,overflow:'auto'}}>
            <img width="100%" src={data.cover} alt="show" />
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default FrameUpdate
