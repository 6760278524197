import React from 'react'
import { useRouteMatch, Switch } from 'react-router-dom'
import PrivateRoute from '../components/PrivateRoute'

import PlanList from './PlanList'
import PlanDetail from './PlanDetail'
import Point from './Point'

const Plan = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <PlanList />
      </PrivateRoute>
      <PrivateRoute path={`${path}/point/:id`}>
        <Point />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:id`}>
        <PlanDetail />
      </PrivateRoute>
    </Switch>
  )
}

export default Plan
