import React, { useState, useEffect } from 'react'
import { Typography, TextField,Grid, ExpansionPanelSummary, Select, MenuItem, FormControl, InputLabel, ExpansionPanel } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { fetchCarList, fetchCities, fetchCueStatusList, fetchDealerList, fetchPlanList } from '../api'
const Inputs = ({ type, name, initData, register, errors, helperText = null, multiline = false, xs = [12, 12], disabled = false,required }) => (
	<Grid item xs={xs[1]} sm={xs[0]} style={{ padding: 20, paddingTop: 0 }}>
		<TextField
			name={type}
			label={name}
			disabled={disabled}
			multiline={multiline}
			rows={multiline ? 4 : 1}
			size='small'
			fullWidth
			required={required}
			variant="outlined"
			defaultValue={initData ? initData[type] : ''}
			inputRef={register({})}
			error={Boolean(errors[type])}
			helperText={errors[type] ? errors[type].message : helperText} />
	</Grid>
)
const Selects = ({ type, label, defaultValue = [], multiple = false, id, name, options = [], onChange }) => {
	const [personName, setPersonName] = useState('');
	const handleChange = event => {
		setPersonName(event.target.value)
		onChange(type, event.target.value)
	};
	console.log(typeof (defaultValue));
	return (
		<FormControl
			fullWidth
			variant="outlined"
			size='small'>
			<InputLabel style={{ background: 'white', padding: '0 2px' }}>{label}</InputLabel>
			<Select
				multiple={multiple}
				style={{ height: 37.28 }}
				value={personName ? personName : defaultValue}
				onChange={handleChange}
			>
				{options.map(item => (
					<MenuItem key={item[id]} value={item[name]}>
						{item[name]}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}
export const CueForm = (props) => {
	let { initData, onSubmit, onChange, formActions } = props
	const [loading, setLoading] = useState(false)
	const [carsItem, setCarsItem] = useState([])
	const [citiesItem, setCitiesItem] = useState([])
	const [statusItem, setStatusItem] = useState([])
	const [dealersItem, setDealersItem] = useState([])
	// const [planItem, setPlanItem] = useState([])
	const { register, errors, handleSubmit } = useForm()
	const [selectForm, setSelectForm] = useState({})
	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await fetchCarList(0, null)
				const cities = await fetchCities(0, null)
				const status = await fetchCueStatusList(0, null)
				const dealers = await fetchDealerList(0, null)
				// const plan = await fetchPlanList(0, null)
				setCarsItem(res.results)
				setCitiesItem(cities.results)
				setStatusItem(status.results)
				setDealersItem(dealers.results)
				// setPlanItem(plan.results)
			} catch (error) {
				console.error(error)
			}
		}
		fetchData().then(() => {
			setLoading(true)
		})
	}, [])
	const onChangeSelect = (e, v) => {
		let data = {
			...selectForm,
			[e]: v
		}
		setSelectForm(data)
		onChange(data)
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			{formActions}
			<ExpansionPanel elevation={0} expanded={true}>
				<ExpansionPanelSummary>
					<Typography style={{ fontSize: 18 }}>基础信息</Typography>
				</ExpansionPanelSummary>
				{loading && <>
					<Grid container item xs={10}>
						<Inputs
							type='plan'
							name='点位计划'
							xs={[4, 12]}
							required
							initData={initData}
							helperText='点位计划编号'
							register={register}
							errors={errors}></Inputs>
						<Inputs type='name'
							name='姓名'
							xs={[4, 12]}
							required
							initData={initData}
							register={register}
							errors={errors}></Inputs>
						<Inputs
							type='mobile'
							name='手机号'
							xs={[4, 12]}
							required
							initData={initData}
							register={register}
							errors={errors}></Inputs>
						<Grid item xs={12} sm={4} style={{ padding: 20, paddingTop: 0 }}>
							<Selects
								type={'car'}
								label='车型'
								id='id'
								name='name'
								defaultValue={initData && initData.car}
								onChange={onChangeSelect}
								options={carsItem}></Selects>
						</Grid>
						<Grid item xs={12} sm={4} style={{ padding: 20, paddingTop: 0 }}>
							<Selects
								type={'status'}
								label='状态'
								id='id'
								name='name'
								defaultValue={initData && initData.status}
								onChange={onChangeSelect}
								options={statusItem}></Selects>
						</Grid>
						<Grid item xs={12} sm={4} style={{ padding: 20, paddingTop: 0 }}>
							<Selects
								type={'city'}
								label='城市'
								id='id'
								name='name'
								defaultValue={initData && initData.city}
								onChange={onChangeSelect}
								options={citiesItem}></Selects>
						</Grid>
						<Grid item xs={12} sm={4} style={{ padding: 20, paddingTop: 0 }}>
							<Selects
								type={'store'}
								label='当前经销商'
								id='id'
								name='name'
								defaultValue={initData && initData.store}
								onChange={onChangeSelect}
								options={dealersItem}></Selects>
						</Grid>
						<Inputs type='ip_address' name='IP地址' xs={[4, 12]} initData={initData} register={register} errors={errors}></Inputs>
						<Inputs type='remark' name='备注' xs={[12, 12]} multiline={true} initData={initData} register={register} errors={errors}></Inputs>
					</Grid>
				</>}
			</ExpansionPanel>
		</form>
	)
}