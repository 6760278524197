import React from 'react'
import Layout from '../components/Layout'
import { useRouteMatch, Switch } from 'react-router-dom'
import PrivateRoute from '../components/PrivateRoute'

import DealerList from './DealerList'
import DealerCreate from './DealerCreate'
import DealerUpdate from './DealerUpdate'

const Dealers = () => {
  const { path,url } = useRouteMatch()
  const breadcrumbs = {
    list: [
      { name: '经销商管理' }
    ],
    create: [
      { name: '经销商管理', link: `${url}` },
      { name: '添加' },
    ],
    update: [
      { name: '经销商管理', link: `${url}` },
      { name: '详情' },
    ]
  }
  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <DealerList />
      </PrivateRoute>
      <PrivateRoute path={`${path}create`}>
        <Layout breadcrumbs={breadcrumbs.create}>
          <DealerCreate />
        </Layout>
      </PrivateRoute>
      <PrivateRoute path={`${path}:id`}>
        <Layout breadcrumbs={breadcrumbs.update}>
          <DealerUpdate />
        </Layout>
      </PrivateRoute>
    </Switch>
  )
}

export default Dealers
