import React from 'react'
import { ExpansionPanelSummary, ExpansionPanelDetails, Typography, Grid, Button, TextField } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Slider from '@material-ui/core/Slider'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ImageBlockEdit from '../components/ImageBlockEdit'
import VideoBlockEdit from '../components/VideoBlockEdit'
import TabsBlockEdit from '../components/TabsBlockEdit'
import TextBlockEdit from '../components/TextBlockEdit'
import FormBlockEdit from '../components/FormBlockEdit'
import GridBlockEdit from '../components/GridBlockEdit'
import CarouselBlockEdit from '../components/CarouselBlockEdit'
import EmptyBlockEdit from '../components/EmptyBlockEdit'
import ExpansionPanel from './ExpansionPanel'

/**
 * 元素编辑
 */
const BlockEditWrap = (props) => {
  const { data, onSave, onRemove } = props
  const handleChange = (event, name=null, value=null) => {
    let styles = {}
    if(data.styles) {
      styles = {...data.styles}
    }
    if(name === null) {
      name = event.target.name
    }
    if(value === null) {
      value = event.target.value
    }
    styles[name] = value
    onSave({...data, styles})
  }
  return (
    <div>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="block-edit-base"
          id="block-edit-base"
        >
          <Typography>内容</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          {data.type === 'text' && <TextBlockEdit {...props} />}
          {data.type === 'image' && <ImageBlockEdit {...props} />}
          {data.type === 'video' && <VideoBlockEdit {...props} />}
          {data.type === 'form' && <FormBlockEdit {...props} />}
          {data.type === 'tabs' && <TabsBlockEdit {...props} />}
          {data.type === 'grid' && <GridBlockEdit {...props} />}
          {data.type === 'empty' && <EmptyBlockEdit {...props} />}
          {data.type === 'carousel' && <CarouselBlockEdit {...props} />}
        </ExpansionPanelDetails>
      </ExpansionPanel>
      {/**}
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="block-edit-styles"
          id="block-edit-styles"
        >
          <Typography>样式</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={2}>
            {/**data.type === 'form' && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  onChange={handleChange}
                  margin="normal"
                  name="button"
                  value={data.styles && data.styles.button}
                  helperText="默认primary, 可选secondary, default"
                  label="按钮配色" />
              </Grid>
            )**/}
            {/**}
            {(data.type === 'image' || data.type === 'empty') && (
              <Grid item xs={12}>
                <Typography variant="body2">宽度(%)</Typography>
                <Box px={1}>
                  <Slider
                    name="width"
                    defaultValue={data.styles ? data.styles.width : 100}
                    onChange={(event, value)=>handleChange(event, "width", value)}
                    color="secondary"
                    aria-labelledby="box-width"
                    valueLabelDisplay="auto"
                    step={5}
                    min={0}
                    max={100}
                    marks={[{value:0,label:'0'},{value:100,label:'100'}]}
                  />
                </Box>
                {/**<TextField
                fullWidth
                margin="normal"
                name="width"
                value={data.styles && data.styles.width}
                onChange={handleChange}
                helperText="默认100%"
                label="宽度" />**/}
            {/**
            </Grid>)}
            {(data.type === 'video' || data.type === 'empty') && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                margin="normal"
                name="height"
                value={data.styles ? data.styles.height : "0"}
                onChange={handleChange}
                label="高度(px)" />
                </Grid>)}
            <Grid item xs>
              <TextField
                fullWidth
                margin="normal"
                name="margin"
                value={data.styles && data.styles.margin}
                onChange={handleChange}
                helperText="上,右,下,左"
                label="外边踞" />
            </Grid>
            <Grid item xs>
              <TextField
                fullWidth
                margin="normal"
                name="padding"
                value={data.styles && data.styles.padding}
                onChange={handleChange}
                helperText="上,右,下,左"
                label="内边踞" />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                margin="normal"
                name="column"
                value={data.styles && data.styles.column ? data.styles.column : '12'}
                onChange={handleChange}
                helperText="区块所占宽度列数, 浮动全宽为12, 屏幕全宽为full, 最小单位为1"
                label="列宽" />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                margin="normal"
                name="backgroundColor"
                value={data.styles && data.styles.backgroundColor}
                onChange={handleChange}
                helperText="#ffffff, rgb(255,255,255), rgba(0,0,0,0.5)"
                label="背景色" />
                </Grid>**/}
            {/**<Grid item xs={12}>
              <TextField
                fullWidth
                margin="normal"
                name="class"
                helperText=".custom, .help"
                value={data.class}
                onChange={(event)=>onSave({...data, class:event.target.value})}
                label="额外class" />
                </Grid>**/}
                {/**}
          </Grid>
        </ExpansionPanelDetails>
        </ExpansionPanel>**/}
      {/**
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="block-edit-actions"
          id="block-edit-actions"
        >
          <Typography>操作</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Button
            variant="outlined"
            color="secondary"
            onClick={onRemove}>删除</Button>
        </ExpansionPanelDetails>
      </ExpansionPanel>
        **/}
    </div>
  )
}


export default BlockEditWrap
