import React from 'react'
import NewBlock from './NewBlock'
import { makeStyles } from '@material-ui/core/styles'
import BlockLayout from './BlockLayout' // 布局

const useStyles = makeStyles(theme => ({
  root: {
    border: 'solid transparent',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    minHeight: '100%',
  },
}))

const Editor2 = (props) => {
  const classes = useStyles()
  const { data, blocklist, edit, onAdd, onSave, onMove, onEdit, onSubmit } = props
  if(!blocklist) {
    return null
  }
  let style = {}
  if(data.bgcolor) {
    style['backgroundColor'] = data.bgcolor
  }
  if(data.bgimage) {
    style['backgroundImage'] = `url(${data.bgimage})`
    style['backgroundSize'] = 'cover'
    style['backgroundPosition'] = 'top'
    style['backgroundRepeat'] = 'norepeat'
  }
  //style['padding'] = 0
  //const handleClick = (row)=> {
  //  onEdit(row.id)
  //}

  console.log('datalist', blocklist)

  return (
    <div
      className={classes.root}
      style={style}>
      {blocklist.map(row => (
        <div key={row.id}>
          {row.layout !== undefined ? (
            <BlockLayout
              mode={data.mode}
              edit={edit === row.id}
              data={row}
              onMove={onMove}
              onAdd={onAdd}
              onEdit={onEdit}
              onSave={onSave}
            />
          ) : (
            <NewBlock
              id={row.id}
              mode={data.mode}
              onSave={onSave}
              //onClick={()=>onEdit(row.id)}
            />
          )}
        </div>
      ))}
      <NewBlock
        mode={data.mode}
        onAdd={onAdd}
      />
    </div>
  )
}

export default Editor2
