import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'
import Layout from '../components/Layout'
import CarForm from '../components/CarForm'
import { fetchCarCreate } from '../api'

const useStyles = makeStyles(theme => ({
  actions: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row-reverse',
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },
}))

const CarCreate = () => {
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({name:false})
  const history = useHistory()
  const classes = useStyles()

  const handleChange = event => {
    let error = false
    const name = event.target.name
    const value = event.target.value
    if(name === 'name' && (value.length < 2 || value.length > 50)) {
      error = true
    }
    setData({...data, [name]:value})
    setErrors({...errors, [name]:error})
  }

  const handleSubmit = async() => {
    try {
      await fetchCarCreate(data)
      history.push('/car')
    } catch {
      alert('操作失败')
    }
  }

  const checkForm = () => {
    let check = true
    if(!data.name || errors.name) {
      check = false
    }
    return check
  }

  const breadcrumbs = [
    { name: "车型管理", link: '/car' },
    { name: "添加" },
  ]

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <div className={classes.actions}>
        <Button
          size="small"
          variant="contained"
          color="primary"
          disabled={!checkForm()}
          onClick={handleSubmit}>保存</Button>
      </div>
      <ExpansionPanel
        expanded={true}
        elevation={0}
        style={{marginBottom:16}}>
				<ExpansionPanelSummary>
					<Typography variant="h6" component="h6">基础信息</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>
          <CarForm
            data={data}
            errors={errors}
            onChange={handleChange}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Layout>
  )
}

export default CarCreate
