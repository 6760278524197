import React from 'react'
import { useHistory } from 'react-router-dom'
import MediumForm from '../components/MediumForm'
import { fetchMediumCreate } from '../api'
import Filtrate from '../components/Filtrate'

const Actions = () => (
  <Filtrate title='媒体管理' sumbitText='保存'></Filtrate>
)

const MediumCreate = () => {
  const history = useHistory()
  const handleSubmit = async (data) => {
    try {
      await fetchMediumCreate(data)
      history.push('/medium')
    } catch(error) {
      alert(error)
    }
  }
  return (
    <MediumForm
      formActions={<Actions />}
      onSubmit={handleSubmit} />
  )
}

export default MediumCreate
