import React, { useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import CarMultiSelectField from './CarMultiSelectField'
import PlanDateField from './PlanDateField'
import MediumItemSelectField from './MediumItemSelectField'

const PlanItemBasicForm = (props) => {
  const { plan, data, onChange } = props
  const is_zone = plan.is_zone === 'true'
  const medium = plan.medium
  const extra = data.extra ? data.extra : {}
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MediumItemSelectField
          medium={medium}
          value={data.position}
          page={data.position_page}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12}>
        <CarMultiSelectField
          fullWidth
          value={data.cars ? data.cars : []}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12}>
        <PlanDateField
          startDate={new Date(plan.start)}
          value={data.dates ? data.dates : []}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          label="统计代码"
          rows="4"
          name="statistics"
          placeholder="请输入百度等平台的统计代码"
          value={data.statistics ? data.statistics : ''}
          onChange={onChange}
        />
      </Grid>
      {is_zone && (
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="金额"
          name="extra.amount"
          value={extra.amount}
          onChange={onChange}
        />
      </Grid>
      )}
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="投放量"
          name="extra.days"
          value={extra.days}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="虚拟线索量"
          name="extra.fake_count"
          value={extra.fake_count}
          onChange={onChange}
        />
      </Grid>
      {!is_zone && (
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="第三方链接"
          name="extra.link"
          value={extra.link}
          onChange={onChange}
        />
      </Grid>
      )}
      {!is_zone && (
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="浏览量/天"
          name="extra.visit_day"
          value={extra.visitbyday}
          onChange={onChange}
        />
      </Grid>
      )}
      {!is_zone && (
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="总浏览量"
          name="extra.visit_total"
          value={extra.visit_total}
          onChange={onChange}
        />
      </Grid>
      )}
      {!is_zone && (
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="预估点击/天"
          name="extra.click_day"
          value={extra.click_day}
          onChange={onChange}
        />
      </Grid>
      )}
      {!is_zone && (
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="总点击"
          name="extra.click_total"
          value={extra.click_total}
          onChange={onChange}
        />
      </Grid>
      )}
      {!is_zone && (
      <Grid item xs={12} sm={4}>
        <TextField
          fullWidth
          label="单位"
          name="extra.unit"
          value={extra.unit}
          onChange={onChange}
        />
      </Grid>
      )}
      <Grid item xs={12}>
        <TextField
          fullWidth
          multiline
          rows={2}
          label="备注"
          name="remark"
          value={data.remark ? data.remark : ''}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  )
}

export default PlanItemBasicForm
