import React from 'react'
import ImagePicker from './ImagePicker'

const ImageBlockEdit = (props) => {
  const { data, onSave } = props

  const handleInput = value => {
    onSave({...data, "content":value})
  }

  return (
    <div>
      <img
        src={data.content}
        alt={data.type}
        width="100%" />
      <ImagePicker
        value={data.content}
        onChange={(value)=>handleInput(value)} />
    </div>
  )
}

export default ImageBlockEdit
