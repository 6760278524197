import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import InboxOutlined from '@material-ui/icons/InboxOutlined'
import Box from '@material-ui/core/Box'
import { fetchTemplatePublish, fetchPlanItemList } from '../api'

const TemplatePublish = (props) => {
  const { data, onSuccess, ...other } = props
  const [open, setOpen] = useState(false)
  const [plan, setPlan] = useState([])
  const [check, setCheck] = useState(data.plans.map(i => i.id))
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
    setCheck(data.plans.map(i => i.id))
  }
  const handleChange = id => event => {
    if(event.target.checked) {
      setCheck([...check, id])
    } else {
      setCheck(check.filter(i => i !== id))
    }
  }
  const handlePublish= async() => {
    const post = {'theme':data.id, 'plans':check}
    try {
      await fetchTemplatePublish(post)
      setOpen(false)
      onSuccess(true)
      alert('发布成功')
    } catch {
      console.log('publish error')
    }
  }
  useEffect(() => {
    const fetchData = async() => {
      try {
        const query = 'notheme=true'
        const res = await fetchPlanItemList(0, 100, query)
        setPlan(res.results)
      } catch {
        console.log('fetch plan items error')
      }
    }
    fetchData()
  }, [data.plans, data.id])

  return (
    <React.Fragment>
      <Button
        variant="contained"
        size="small"
        disableElevation
        color="primary"
        onClick={handleOpen}
        {...other}
      >发布</Button>
      <Dialog
        open={open}
        PaperProps={{ elevation: 0 }}
        onClose={handleClose}
        aria-labelledby="template-publish"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle>选择要发布的点位计划</DialogTitle>
        <DialogContent>
          {(data.plans.length === 0 && plan.length === 0) ? (
            <Box textAlign="center">
              <InboxOutlined color="secondary" fontSize="large" style={{marginBottom:8}} />
              <DialogContentText>无可用点位计划</DialogContentText>
            </Box>
          ) : (
            <div>
          <FormGroup>
            {data.plans.map(item => (
            <FormControlLabel
              key={item.id}
              //label={item.name}
              label={`${item.name}/${item.medium}/${item.position}`}
              control={
                <Checkbox
                  checked={check.indexOf(item.id) !== -1}
                  onChange={handleChange(item.id)}
                />}
            />
            ))}
            {plan.map(item => (
            <FormControlLabel
              key={item.id}
              //label={item.number}
              label={`${item.number}/${item.medium}/${item.position_name}`}
              control={
                <Checkbox
                  checked={check.indexOf(item.id) !== -1}
                  onChange={handleChange(item.id)}
                />}
            />
            ))}
          </FormGroup>
          <DialogContentText>仅显示未关联模板的点位计划</DialogContentText>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary">取消</Button>
          <Button
            disabled={check.length <= 0}
            onClick={handlePublish}
            color="primary">确定</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default TemplatePublish
