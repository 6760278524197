/**
 * 区块内容模型
 */
const blocks = {
  text: {
    type: 'text',
    styles: {},
    content: '<p>示例文字, 点击进行修改</p>'
  },
  image: {
    type: 'image',
    styles: { width: '100%', height: 'auto' },
    content: require("./images/placeholder.png")
  },
  form: {
    type: 'form',
    styles: {}
  },
  tabs: {
    type: 'tabs',
    styles: {},
    children: [
      { id: 0, name: '选项卡1', video: 'https://www.w3schools.com/html/mov_bbb.mp4', image:'' },
      { id: 1, name: '选项卡2', video: 'https://www.w3schools.com/html/mov_bbb.mp4', image:'' }
    ]
  },
  video: {
    type:'video',
    content:'',
    styles:{width:'100%',height:240}
  },
  carousel: {
    type:'carousel',
    styles:{height:200},
    children:[
      {id:0, content:require("./images/placeholder.png")},
      {id:1, content:require("./images/placeholder.png")},
    ]
  },
  empty: {
    type:'empty',
    styles:{width:'100%', height:'50px'}
  },
  layout: {
    type: 'layout1',
    styles: {height: '50px'}
  },
  grid: {
    type:'grid',
    styles:{}
  }
}


export default blocks
