/**
 * 所有只读区块显示
 */
import React from 'react'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Link from '@material-ui/core/Link'
import FormBlock from './FormBlock'
import TextBlock from './TextBlock'
import ImageBlock from './ImageBlock'
import TabsBlock from './TabsBlock'
import GridBlock from './GridBlock'
import VideoBlock from './VideoBlock'
import CarouselBlock from './CarouselBlock'
import EmptyBlock from './EmptyBlock'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    border: 'none',
    minHeight: '100vh',
    overflow: 'hidden',
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default
  }
}))

const BlockShow = (props) => {
  const { data, blocklist, onSubmit, cars } = props
  const classes = useStyles()
  let style = {}
  if(data.bgcolor) {
    style['backgroundColor'] = data.bgcolor
  }
  if(data.bgimage) {
    style['backgroundImage'] = `url(${data.bgimage})`
    style['backgroundSize'] = 'cover'
    style['backgroundPosition'] = 'top'
    style['backgroundRepeat'] = 'no-repeat'
  }

  const makeItemStyles = (item) => {
    let itemStyles = {'padding':0}
    if(item.column === 'full') {
      itemStyles = {
        ...itemStyles,
        width: '100vw',
        maxWidth: '100vw',
        marginLeft: `calc((1280px - 100vw)/2)`,
      }
    }
    return itemStyles
  }

  const getMaxWidth = (row) => {
    let maxWidth = 'sm'
    if (row.width === 'fullwidth') {
      maxWidth = false
    } else if (data.mode === 'pc') {
      maxWidth = 'md'
    }
    return maxWidth
  }


  return (
    <div
      className={classes.root}
      style={style}>
      {blocklist.map(row => (
        <Container
          key={row.id}
          maxWidth={getMaxWidth(row)}
          style={{padding:0}}>
          <Grid container>
            {row.layout !== undefined && row.layout.split('-').map((row2, index) => (
              <Grid item sm={parseInt(row2)} key={index}>
                {Object.keys(row.elements[index]).length !== 0 && row.elements[index].type === 'text' && (<TextBlock data={row.elements[index]} />)}
                {Object.keys(row.elements[index]).length !== 0 && row.elements[index].type === 'image' && (<ImageBlock data={row.elements[index]} />)}
                {Object.keys(row.elements[index]).length !== 0 && row.elements[index].type === 'video' && (<VideoBlock data={row.elements[index]} />)}
                {Object.keys(row.elements[index]).length !== 0 && row.elements[index].type === 'empty' && (<EmptyBlock data={row.elements[index]} />)}
                {Object.keys(row.elements[index]).length !== 0 && row.elements[index].type === 'carousel' && (<CarouselBlock data={row.elements[index]} />)}
                {Object.keys(row.elements[index]).length !== 0 && row.elements[index].type === 'form' && (<FormBlock data={row.elements[index]} cars={cars} onSubmit={onSubmit} />)}
                {Object.keys(row.elements[index]).length !== 0 && row.elements[index].type === 'tabs' && (<TabsBlock data={row.elements[index]} />)}
              </Grid>
            ))}
          </Grid>
        </Container>
      ))}
      <Box align="center" my={1}>
        <Link
          variant="body2"
          rel="noopener"
          color="inherit"
          style={{ opacity: 0.5}}
          href="http://www.beian.miit.gov.cn"
          target="_blank">
          {process.env.REACT_APP_COPYRIGHT}
        </Link>
      </Box>
    </div>
  )
}

export default BlockShow
