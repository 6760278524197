import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { fetchUserCreate } from '../api'
import { useHistory } from 'react-router-dom'

const UserCreate = ({open, setOpen}) => {
  const history = useHistory()
  const [data, setData] = useState({name:'', pass:'', repass:''})
  const [errors, setErrors] = useState({name:false, pass:false, repass:false})

  const handleChange = event => {
    let error = false
    const name = event.target.name
    const value = event.target.value
    if(name === 'name' && (value.length < 2 || value.length > 20)) {
      error = true
    } else if (name === 'pass' && (value.length < 6 || value.length > 20)) {
      error = true
    } else if (name === 'repass' && value !== data.pass) {
      error = true
    }
    setData({...data, [name]:value})
    setErrors({...errors, [name]:error})
  }

  const handleSubmit = async() => {
    try {
      const req = {'username':data.name, 'password':data.pass}
      const res = await fetchUserCreate(req)
      history.push(`/user/${res.id}`)
    } catch (error) {
      alert(error)
      console.log('create user error')
    }
  }
  const handleClose = () => {
    setOpen(false)
  }

  /**
   * 表单submit验证
   */
  const checkForm = () => {
    let check = true
    if(!data.name || errors.name) {
      check = false
    } else if (!data.pass || errors.pass){
      check = false
    } else if (!data.repass || errors.repass) {
      check = false
    }
    return check
  }

  return (
    <React.Fragment>
      {/* <Button variant="contained" color="primary" onClick={handleOpen}>
        添加用户
      </Button> */}
      <Dialog
        fullWidth
        maxWidth="xs"
        PaperProps={{ elevation: 0 }}
        open={open}
        onClose={handleClose}>
        <DialogTitle id="form-dialog-title">添加用户</DialogTitle>
        <DialogContent>
          <TextField
            label="用户名"
            autoFocus
            margin="dense"
            name="name"
            value={data.name}
            fullWidth
            onChange={handleChange}
            error={errors.name}
            helperText={errors.name && "用户名长度为2至20位"}
          />
          <TextField
            label="密码"
            margin="dense"
            type="password"
            name="pass"
            value={data.pass}
            fullWidth
            onChange={handleChange}
            error={errors.pass}
            helperText={errors.pass && "密码长度为6至20, 需包含数字和字母"}
          />
          <TextField
            label="确认密码"
            margin="dense"
            type="password"
            name="repass"
            value={data.repass}
            fullWidth
            onChange={handleChange}
            error={errors.repass}
            helperText={errors.repass && "确认密码不一致"}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">取消</Button>
          <Button
            disabled={!checkForm()}
            onClick={handleSubmit}>提交</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default UserCreate
