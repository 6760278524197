import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

const TipForm = (props) => {
  const { data, errors, onChange } = props
  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          multiline
          rows={4}
          name="title"
          value={data.title ? data.title : ""}
          onChange={onChange}
          variant="outlined"
          label="内容 "
          error={errors.title}
          helperText={errors.title ? errors.title.message : null}
        />
      </Grid>
    </Grid>
  )
}

export default TipForm
