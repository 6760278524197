/**
 * 区块包装
 * 包括边框, 排序按钮, 插入按钮
 */
import React, {useState} from 'react'
import { makeStyles } from '@material-ui/styles'
import SortBar from './SortBar'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    //boxShadow: theme.shadows[10]
    outlineStyle: 'dashed',
    outlineWidth: 'thick',
    ////outlineColor: theme.palette.primary.light,
    outlineColor: theme.palette.grey[300],
  },
  addUp: {
    zIndex: theme.zIndex.speedDial,
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: theme.palette.grey[200],
    width: 30,
    height: 30,
    borderRadius: 30,
    textAlign: 'center',
    lineHeight: '30px'
  },
}))

const BlockWrap = (props) => {
  const classes = useStyles()
  const { data, edit, children, onAdd, onMove, onClick } = props
  const [active, setActive] = useState(false) // 激活状态

  const handleClick = () => {
    onAdd('up', {'width':'default'}, data.id)
  }

  if(!data) {
    return null
  }

  if(!edit && !active) {
    return (
      <div
        onMouseEnter={()=>setActive(true)}
        onMouseLeave={()=>setActive(false)}
        onClick={onClick}
      >
        {children}
      </div>
    )
  }

  return (
    <div
      onMouseEnter={()=>setActive(true)}
      onMouseLeave={()=>setActive(false)}
      className={classes.root}>
      <SortBar onMove={onMove} />
      <div
        aria-controls="add-more"
        className={classes.addUp}
        onClick={handleClick}>+</div>
      {children}
    </div>
  )
}

export default BlockWrap
