import React, {useState} from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/styles'
import ElementShow from './ElementShow.js'
import BlockCreate from './BlockCreate'
import BlockWrap from './BlockWrap' // 编辑框
import blocks from '../blocks.js'

const useStyles = makeStyles(theme => ({
  col: {
    borderWidth: 2,
    borderColor: 'transparent',
    borderStyle: 'dashed',
    '&:hover': {
      //boxShadow: theme.shadows[20]
      borderColor: theme.palette.secondary.light,
      //outlineStyle: 'auto',
      //outlineColor: theme.palette.secondary.main,
    }
  }
}))

const BlockLayout = (props) => {
  const {mode, data, edit, onAdd, onEdit, onMove, onSave, ...other} = props
  const [open, setOpen] = useState(false)
  const [index, setIndex] = useState(0) // 元素索引
  const classes = useStyles()

  let maxWidth = 'sm'
  if (data.width === 'fullwidth') {
    maxWidth = false
  } else if (mode === 'pc') {
    maxWidth = 'md'
}

  /**
   * 选择元素类型
   */
  const handleChoice = (index) => {
    setIndex(index)
    setOpen(true)
  }

  /**
   * 添加内部元素
   */
  const handleCreate = (type) => {
    setOpen(false)
    let copy = {...data}
    copy['elements'][index] = blocks[type]
    onSave(copy)
  }

  const inner = (
    <Grid container>
      {data.layout.split('-').map((row, index) => (
        <Grid item
          sm={parseInt(row)}
          key={index}
          className={classes.col}
          onClick={()=>onEdit(data.id, index)}
        >
          {Object.keys(data.elements[index]).length !== 0 ? (
            <ElementShow
              data={data.elements[index]} />
          ) : (
            <Box
              textAlign="center"
              m={2}
              py={5}
              border={2}
              style={{borderStyle:"dashed"}}
              borderColor="grey.300"
              onClick={()=>handleChoice(index)}
            >
              <AddIcon style={{color:"grey"}} />
            </Box>
          )}
        </Grid>
      ))}
    </Grid>
  )

  return (
    <Container
      maxWidth={maxWidth}
      style={{padding:0}}
      {...other}>
      <BlockWrap
        data={data}
        edit={edit}
        onMove={onMove}
        onAdd={onAdd}
      >
        <BlockCreate
          open={open}
          onClose={()=>setOpen(false)}
          onCreate={handleCreate}
        />
        {inner}
      </BlockWrap>
    </Container>
  )
}

export default BlockLayout
