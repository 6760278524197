import React, { useState, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import { fetchMediumList } from '../api'

const MediumSelectField = (props) => {
  const [data, setData] = useState([])

  useEffect(() => {
    const fetchData = async() => {
      try {
        const res = await fetchMediumList(0, 50)
        setData(res.results)
      } catch {
      }
    }
    fetchData()
  }, [])

  return (
    <TextField
      select
      label="媒体"
      name="medium"
      {...props}
    >
      {data.map(row => (
        <MenuItem key={row.id} value={row.name}>
          {row.name}
        </MenuItem>
      ))}
    </TextField>
  )
}

export default MediumSelectField
