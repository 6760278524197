import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(6)
  },
  title: {
    lineHeight: 2,
    marginBottom: theme.spacing(6)
  },
  actions: {
    margin: '0 auto',
    width: '55%',
    marginTop: theme.spacing(6),
  },
}))

const LoginForm = (props) => {
  const { onSubmit } = props
  const classes = useStyles()
  const [data, setData] = useState({username:'', password:''})
  const [errors, setErrors] = useState({username:false, password:false})
  const handleChange = event => {
    const name = event.target.name
    const value = event.target.value
    let error = false
    if (name === 'username' && (value.length < 2 || value.length > 10)) {
      error = true
    } else if(name === 'password' &&  (value.length < 2 || value.length > 16)) {
      error = true
    }
    setErrors({...errors, [name]:error})
    setData({...data, [name]:value})
  }
  const checkForm = () => {
    let check = true
    if(!data.username || errors.username) {
      check = false
    } else if (!data.password || errors.password){
      check = false
    }
    return check
  }
  const handleSubmit = () => {
    onSubmit(data)
  }
  return (
    <div className={classes.root}>
      <Typography
        variant="h5"
        component="h3"
        className={classes.title}
        gutterBottom>欢迎登录<br />SVW山东线索平台</Typography>
      <TextField
        label="用户名"
        name="username"
        margin="normal"
        onChange={handleChange}
        value={data.username}
        error={errors.username}
        helperText={errors.username && '账户格式不正确'}
        required fullWidth autoFocus />
      <TextField
        label="密码"
        name="password"
        margin="normal"
        type="password"
        value={data.password}
        error={errors.password}
        onChange={handleChange}
        helperText={errors.password && '密码长度不正确'}
        required fullWidth />
      <div className={classes.actions}>
      <Button
        fullWidth
        variant="contained"
        color="primary"
        disabled={!checkForm()}
        onClick={handleSubmit}
        type="submit">登录</Button>
      </div>
    </div>
  )
}

export default LoginForm
