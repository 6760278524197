import React, { useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

import { fetchPlanItemCreate } from '../api'
import PlanItemBasicForm from '../components/PlanItemBasicForm'

const PlanItemCreate = (props) => {
  const { plan, open, onClose } = props
  const [data, setData] = useState({})

  const handleChange = (event, name = null, value = null) => {
    if (!name) {
      name = event.target.name
    }
    if (!value) {
      value = event.target.value
    }
    if (name.match(/extra.*/)) {
      const subname = name.split('.')[1]
      setData({ ...data, extra: { ...data.extra, [subname]: value } })
    } else {
      setData({ ...data, [name]: value })
    }
  }

  const handleSubmit = async () => {
    const req = { ...data, plan: plan.number }
    try {
      await fetchPlanItemCreate(req)
      onClose()
    } catch {
      alert('添加失败')
    }
  }

  const checkForm = () => {
    let check = true
    if(!data.position){
      check = false
    }
    else if(!data.cars) {
      check = false
    }
    return check
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      PaperProps={{ elevation: 0 }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>添加点位计划</DialogTitle>
      <DialogContent>
        <PlanItemBasicForm
          data={data}
          plan={plan}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">取消</Button>
        <Button onClick={handleSubmit}
          disabled={!checkForm()}>添加</Button>
      </DialogActions>
    </Dialog>
  )
}

export default PlanItemCreate
