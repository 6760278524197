import React, { useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Layout from '../components/Layout'
import { makeStyles } from '@material-ui/styles'
import { useHistory, useParams } from 'react-router-dom'
import { fetchPasswordReset } from '../api'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  actions: {
    marginBottom: theme.spacing(2),
    textAlign: 'right'
  },
  input: {
    display: 'block',
    width: 320,
  }
}))

const PasswordReset = () => {
  const [data, setData] = useState({pass:'', repass:''})
  const [errors, setErrors] = useState({pass:false, repass:false})
  const classes = useStyles()
  const { user } = useParams()
  const history = useHistory()
  const breadcrumbs = [{name:'重置密码'}]

  const handleChange = event => {
    const name = event.target.name
    const value = event.target.value
    let error
    if (name === 'pass' && (value.length < 6 || value.length > 20)) {
      error = true
    } else if (name === 'repass' && value !== data.pass) {
      error = true
    }
    setData({...data, [name]:value})
    setErrors({...errors, [name]:error})
  }

  const handleSubmit = async () => {
    try {
      await fetchPasswordReset({user:user, password:data.pass})
      history.goBack()
    } catch {
      alert('重置失败')
    }
  }

  /**
   * 表单submit验证
   */
  const checkForm = () => {
    let check = true
    if (!data.pass || errors.pass){
      check = false
    } else if (!data.repass || errors.repass) {
      check = false
    }
    return check
  }

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <div className={classes.actions}>
        <Button
          variant="contained"
          color="primary"
          disabled={!checkForm()}
          onClick={handleSubmit}>重置</Button>
      </div>
      <Paper className={classes.root}>
        <Typography component="p" variant="body2" color="textSecondary">
          密码要求6～20位字符<br />
          建议至少包含数字、字母、符号其中两种元素<br />
          建议避免你用生日、姓名和简单数字
        </Typography>
        <TextField
          className={classes.input}
          required
          fullWidth
          margin="normal"
          variant="outlined"
          size="small"
          label="新密码"
          name="pass"
          type="password"
          value={data.pass}
          onChange={handleChange}
          error={errors.pass}
          helperText={errors.pass && "密码长度为6至20, 需包含数字和字母"}

        />
        <TextField
          className={classes.input}
          required
          fullWidth
          margin="normal"
          variant="outlined"
          size="small"
          label="确认密码"
          name="repass"
          type="password"
          value={data.repass}
          onChange={handleChange}
          error={errors.repass}
          helperText={errors.repass && "确认密码不一致"}
        />
      </Paper>
    </Layout>
  )
}

export default PasswordReset
