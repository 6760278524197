/**
 * 报名表单
 */
import React, { useState, useEffect } from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { fetchDealerList, fetchCities } from '../api'
import { makeStyles  } from '@material-ui/core/styles'

/**
 * 默认风格
 * 2020.02.27
 */
const Default = (props) => {
  const { data, errors, cities, cars, dealers, handleSubmit, handleChange } = props
  return (
    <div style={{padding:32}}>
      <TextField
        required
        name="name"
        margin="normal"
        fullWidth
        label="姓名"
        value={data.name}
        onChange={handleChange}
        error={errors.name}
        helperText={errors.name ? '请输入真实姓名' : null}
      />
      <TextField
        required
        name="mobile"
        margin="normal"
        fullWidth
        label="电话"
        type="tel"
        value={data.mobile}
        onChange={handleChange}
        error={errors.mobile}
        helperText={errors.mobile ? '请输入正确的手机号码' : null}
      />
      <TextField
        required
        select
        name="city"
        margin="normal"
        fullWidth
        label="城市"
        value={data.city}
        onChange={handleChange}
      >
        <MenuItem value={0}>请选择所在城市</MenuItem>
        {cities.map(city => (
          <MenuItem key={city.id} value={city.name}>
            {city.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        required
        select
        name="car"
        margin="normal"
        fullWidth
        label="意向车型"
        value={data.car}
        onChange={handleChange}
      >
        <MenuItem value={0}>请选择车型</MenuItem>
        {cars && cars.map(car=> (
          <MenuItem key={car.id} value={car.name}>
            {car.name}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        required
        select
        name="store"
        margin="normal"
        fullWidth
        label="经销商"
        value={data.store}
        onChange={handleChange}
      >
        <MenuItem value={0}>请选择经销商</MenuItem>
        {dealers.map(dealer=> (
          <MenuItem key={dealer.id} value={dealer.name}>
            {dealer.name}
          </MenuItem>
        ))}
      </TextField>
      <Button
        fullWidth
        type="submit"
        variant="contained"
        color="primary"
        style={{marginTop:16}}
        onClick={handleSubmit}
      >报名</Button>
    </div>
  )
}

/**
 * 红色风格
 * 2020.02.26
 */
const Redone = (props) => {
  const { data, errors, cities, cars, dealers, handleSubmit, handleChange } = props
  const useStyles = makeStyles({
    root: {
      padding: '0 1.5rem'
    },
    box: {
      padding: "0 1rem",
      border: "2px solid #FFFAE0",
    },
    carBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: "0.5rem 1rem",
      border: "2px solid #FFFAE0",
      marginBottom: "0.8rem",
    },
    label: {
      color: '#FFFAE0',
      fontSize: "0.85rem",
      whiteSpace: 'nowrap'
    },
    inputGroup: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: "0.5rem 0.2rem",
      borderBottom: "2px solid #FFFAE0",
    },
    input: {
      color: '#FFFAE0',
      fontSize: 16,
      marginLeft: 16,
      border: 'none',
      background: 'transparent',
      width: '60%',
      '& option': {
        color: 'black'
      }
    },
    submit: {
      marginTop: '1rem'
    },
    p: {
      marginTop: 0,
      fontSize: '0.65rem',
    }
  })
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.carBox}>
        <label className={classes.label}>意向车型</label>
        <select className={classes.input}
          name="car"
          onChange={handleChange}
          value={data.car}>
          {cars && cars.map(car=> (
            <option key={car.id} value={car.name}>
              {car.name}
            </option>
          ))}
        </select>
      </div>
      <div className={classes.box}>
        <div className={classes.inputGroup}>
          <label className={classes.label}>姓名</label>
          <input className={classes.input} name="name"
            onChange={handleChange}
            value={data.name} />
        </div>
        <div className={classes.inputGroup}>
          <label className={classes.label}>电话</label>
          <input className={classes.input}
            name="mobile"
            value={data.mobile}
            onChange={handleChange}
          />
        </div>
        <div className={classes.inputGroup}>
          <label className={classes.label}>城市</label>
          <select
            className={classes.input}
            name="city"
            value={data.city}
            onChange={handleChange}
          >
            {cities && cities.map(city=> (
              <option key={city.id} value={city.name}>
                {city.name}
              </option>
            ))}
          </select>
        </div>
        <div className={classes.inputGroup}>
          <label className={classes.label}>经销商</label>
          <select
            className={classes.input}
            name="store"
            value={data.store}
            onChange={handleChange}
          >
            <option></option>
            {dealers && dealers.map(dealer=> (
              <option key={dealer.id} value={dealer.name}>
                {dealer.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <img
            onClick={handleSubmit}
            className={classes.submit}
            width="100%"
            alt="submit"
            src="https://www.weiruicar.com/static/theme/005/img/button.png" />
        </div>
        <p className={classes.p}>点击报名即视为接受<a href="https://brand.svw-volkswagen.com/legal/private">隐私政策</a><br />
        后续将由上汽大众授权经销商与您进一步沟通试驾、购车等事宜。</p>
      </div>
    </div>

  )
}

const useStyles = makeStyles({
  speedbutton: {
    position: props => props.editing ? "absolute" : "fixed",
    bottom: "20vh",
    right: 0,
    width: 35,
    '& > img': {
      width: '100%'
    }
  }
})

const FormBlock = (props) => {
  const { cars, onSubmit, editing } = props
  const styles = props.data.styles ? props.data.styles : {}
  const [cities, setCities] = useState([])
  const [dealers, setDealers] = useState([])
  const [data, setData] = useState({
    name: '',
    mobile: '',
    city: 0,
    store: 0,
    car: 0
  })
  const [errors, setErrors] = useState({
    name: false,
    mobile: false,
    city: false,
    store: false,
    car: false
  })
  const classes = useStyles(props)

  useEffect(() => {
    const fetchData = async() => {
      try {
        const res = await fetchCities(0, 100)
        setCities(res.results)
      } catch(error) {
        console.log('fetch city list error')
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    const fetchData = async() => {
      try {
        const city = cities.find(i => i.name === data.city)
        const query = 'city='+city.id
        const res = await fetchDealerList(0, 100, query)
        setDealers(res.results)
      } catch(error) {
        console.log('fetch dealers list error')
      }
    }
    if(data.city) {
      fetchData()
    }
  }, [cities, data.city])

  const handleChange = event => {
    const name = event.target.name
    const value = event.target.value
    let error = false
    if(name === 'name' && (value.length < 2 || value.length > 10)) {
      error = true
    } else if (name === 'mobile' && !value.match(/^1\d{10}$/)) {
      error = true
    }
    setData({...data, [name]:value})
    setErrors({...errors, [name]:error})
  }

  const handleSubmit = event => {
    event.preventDefault()
    onSubmit(data)
  }

  /**
   * 样式主题风格
   */
  let theme = "default"
  if (styles.theme) {
    theme = styles.theme
  }

  const innerProps = {data, errors, cars, dealers, cities, handleChange, handleSubmit}

  return (
    <div id="form">
      {styles.speedbutton === "white01" && (
        <a href="#form" className={classes.speedbutton}>
          <img
            src={require("../images/enroll-button.png")}
            alt="speedbutton" />
        </a>
      )}
      {theme === "default" && <Default {...innerProps} />}
      {theme === "redone" && <Redone {...innerProps} />}
    </div>
  )
}

export default FormBlock
