import React, { useEffect, useState } from 'react'
import { useTheme, makeStyles } from '@material-ui/styles'
import Layout from '../components/Layout'
import PrivateRoute from '../components/PrivateRoute'
import { useRouteMatch, Link, Switch, useHistory } from 'react-router-dom'
import { Grid, Paper, Typography, TextField, Button, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import { fetchProfile, fetchUser, fetchPassword } from '../api'
import { useForm } from 'react-hook-form'

const useStyles = makeStyles({
  inputItem: {
    marginTop: 25,
    width: '100%',
    maxWidth: 500
  },
  title: {
    fontSize: 18,
    marginBottom: 10
  },
  leftTab: {
    padding: '20px 0'
  },
  userBtn: {
    display: 'block',
    textAlign: 'center',
    lineHeight: 2.5,
    textDecoration: 'none',
    fontSize: 16
  },
  itemTitle: {
    padding: 10,
    fontSize: 16,
    display: 'block',
    borderBottom: '1px solid #EBEEF5'
  },
  subBox: {
    paddingTop: 20,
    paddingBottom: 20
  },
  subBtn: {

  },
  tipText: {
    fontSize: 12
  }
})
const Inputs = ({ type, label, name, xs = ['auto', 'auto'], disabled = false, data, errors = null, register = null, required }) => {
  let classes = useStyles()
  return (
    <Grid item xs={xs[0]} sm={xs[1]}>
      <TextField
        name={name}
        label={label}
        className={classes.inputItem}
        size='small'
        type={type}
        variant="outlined"
        fullWidth
        required={required}
        disabled={disabled}
        defaultValue={data ? data : ''}
        inputRef={register({})}
        error={errors && Boolean(errors[type])}
        helperText={errors && errors[type] ? errors[type].message : null} />
    </Grid>
  )
}
// 个人信息
const RenderUser = () => {
  let classes = useStyles()
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({ first_name: false, email: false })

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchProfile()
        setData(res)
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
  }, [])
  // 修改个人信息
  const handleSubmit = async () => {
    try {
      await fetchUser(data.id, {
        first_name: data.first_name,
        email: data.email
      }, 'patch')
    } catch (error) {
      console.error(error)
    }
  }
  const handleChange = event => {
    const name = event.target.name
    const value = event.target.value
    setData({ ...data, [name]: value })

    let error
    if (name === 'email' && !/^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(value)) {
      error = true
    }
    setData({ ...data, [name]: value })
    setErrors({ ...errors, [name]: error })
  }

  return (
    <Paper elevation={0}>
      <Typography className={classes.itemTitle}>个人信息</Typography>
      {data.username && <div style={{ padding: '10px 40px' }}>
        <TextField
          className={classes.inputItem}
          required
          size='small'
          margin="normal"
          label="姓名"
          variant="outlined"
          name="first_name"
          value={data.first_name}
          onChange={handleChange}
        />
        <TextField
          className={classes.inputItem}
          size='small'
          margin="normal"
          label="姓名"
          variant="outlined"
          name="username"
          disabled
          value={data.username}
        />
        <TextField
          className={classes.inputItem}
          required
          size='small'
          margin="normal"
          label="电子邮箱"
          variant="outlined"
          name="email"
          value={data.email}
          onChange={handleChange}
          error={errors.email}
          helperText={errors.email && "请输入正确的邮箱格式"}
        />
        <div className={classes.subBox}>
          <Button
            color={'primary'}
            variant='outlined'
            onClick={handleSubmit}
            className={classes.subBtn}
            type='submit'>提交</Button>
        </div>
      </div>}
    </Paper>
  )
}
// 修改密码
const RenderPassWord = () => {
  const [data, setData] = useState({ password: '', new_password: '', valid_password: '' })
  const [errors, setErrors] = useState({ new_password: false, valid_password: false })
  let classes = useStyles()
  let history = useHistory()
  // 修改密码
  const handleSubmit = async () => {
    try {
      await fetchPassword(data)
      localStorage.clear()
      history.replace("/login")
    } catch (error) {
      alert(error)
      console.error(error);
    }
  }

  const handleChange = event => {
    const name = event.target.name
    const value = event.target.value
    let error
    if (name === 'password' && (value.length < 6 || value.length > 20)) {
      error = true
    }
    else if (name === 'new_password' && (value.length < 6 || value.length > 20)) {
      error = true
    } else if (name === 'valid_password' && value !== data.new_password) {
      error = true
    }
    setData({ ...data, [name]: value })
    setErrors({ ...errors, [name]: error })
  }

  const checkForm = () => {
    let check = true
    if (!data.password || errors.password) {
      check = false
    } else if (!data.new_password || errors.new_password) {
      check = false
    } else if (!data.valid_password || errors.valid_password) {
      check = false
    }
    return check
  }

  return (
    <Paper elevation={0}>
      <Typography className={classes.itemTitle}>修改密码</Typography>
      <div style={{ padding: '10px 40px' }}>
        <TextField
          className={classes.inputItem}
          required
          size='small'
          margin="normal"
          label="原密码"
          variant="outlined"
          name="password"
          type="password"
          value={data.password}
          onChange={handleChange}
          error={errors.password}
          helperText={errors.password && "密码长度为6至20, 需包含数字和字母"}
        />
        <TextField
          className={classes.inputItem}
          required
          size='small'
          margin="normal"
          variant="outlined"
          label="新密码"
          name="new_password"
          type="password"
          value={data.new_password}
          onChange={handleChange}
          error={errors.new_password}
          helperText={errors.new_password && "密码长度为6至20, 需包含数字和字母"}
        />
        <TextField
          className={classes.inputItem}
          required
          size='small'
          variant="outlined"
          margin="normal"
          label="确认密码"
          name="valid_password"
          type="password"
          value={data.valid_password}
          onChange={handleChange}
          error={errors.valid_password}
          helperText={errors.valid_password && "确认密码不一致"}
        />
        <div className={classes.subBox}>
          <Typography className={classes.tipText}>密码要求6～20位字符</Typography>
          <Typography className={classes.tipText}>建议至少包含数字、字母、符号其中两种元素</Typography>
          <Typography className={classes.tipText}>建议避免你用生日、姓名和简单数字</Typography>
        </div>
        <div className={classes.subBox} style={{ paddingTop: 0 }}>
          <Button
            color={'primary'}
            variant='outlined'
            disabled={!checkForm()}
            onClick={handleSubmit}
            className={classes.subBtn}
            type='submit'>提交</Button>
        </div>
      </div>
    </Paper>
  )
}
const renderHelp = () => (
  <Paper elevation={0}>
    <Typography>帮助中心</Typography>
    <Grid>
      <ExpansionPanel square>
        <ExpansionPanelSummary aria-controls="panel2d-content" id="panel2d-header">
          <Typography>Collapsible Group Item #2</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Typography>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex,
            sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing
            elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
          </Typography>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Grid>
  </Paper>
)
const Users = () => {
  const { path } = useRouteMatch()
  const theme = useTheme()
  const classes = useStyles()
  const currentUrl = window.document.location.pathname
  const breadcrumbs = [
    { name: '个人中心' }
  ]
  return (
    <Layout breadcrumbs={breadcrumbs} >
      <Typography className={classes.title}>个人中心</Typography>
      <Grid container xs={12} spacing={3} style={{paddingBottom:20}}>
        <Grid item xs={12} sm={3}>
          <Paper className={classes.leftTab} elevation={0}>
            <Typography
              className={classes.userBtn}
              style={currentUrl === '/users' ? { backgroundColor: theme.palette.background.default } : {}}
              component={Link}
              color='inherit'
              to='/users'>个人中心</Typography>
            <Typography
              className={classes.userBtn}
              component={Link}
              style={currentUrl === '/users/password' ? { backgroundColor: theme.palette.background.default } : {}}
              color='inherit'
              to='/users/password'>修改密码</Typography>
            {/* <Typography className={classes.userBtn} component={Link} to='/users/help'>帮助中心</Typography> */}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Switch>
            <PrivateRoute exact path={path}>
              <RenderUser classes={classes} />
            </PrivateRoute>
            <PrivateRoute path={`${path}/password`}>
              <RenderPassWord classes={classes} />
            </PrivateRoute>
            <PrivateRoute path={`${path}/help`}>
              {renderHelp()}
            </PrivateRoute>
          </Switch>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Users
