import React from 'react'
import TextField from '@material-ui/core/TextField'

const VideoBlockEdit = (props) => {
  const { data, onSave } = props
  const handleChange = event => {
    onSave({...data, content:event.target.value})
  }
  return (
    <TextField
      fullWidth
      type="url"
      margin="normal"
      label="视频地址"
      value={data.content}
      onChange={handleChange}
    />
  )
}

export default VideoBlockEdit
