import React from 'react'

const EmptyBlock = (props) => {
  const { data } = props
  let styles = {}
  styles = {
    width: data.styles ? data.styles.width : '100%',
    height: data.styles ? data.styles.height : '50px'
  }
  return (
    <div style={styles}></div>
  )
}

export default EmptyBlock
