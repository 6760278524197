import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core'

import { fetchPlanItemDetail, fetchPlanItemUpdate } from '../api'
import PlanItemBasicForm from '../components/PlanItemBasicForm'

const PlanItemUpdate = (props) => {
  const { plan, id, open, onClose } = props
  const [data, setData] = useState({})

  useEffect(() => {
    const fetchData = async() => {
      try {
        const res = await fetchPlanItemDetail(id)
        setData(res)
      } catch {}
    }
    if(id) {
      fetchData()
    }
  }, [id])

  const handleChange = (event, name=null, value=null) => {
    if(!name) {
      name = event.target.name
    }
    if(!value) {
      value = event.target.value
    }
    if(name.match(/extra.*/)) {
      const subname = name.split('.')[1]
      setData({...data, extra:{...data.extra, [subname]:value}})
    } else {
      setData({...data, [name]:value})
    }
  }

  const handleSubmit = async() => {
    try {
      await fetchPlanItemUpdate(id, data)
      onClose()
    } catch {
      alert('保存失败')
    }
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      PaperProps={{ elevation: 0 }}
      open={open}
      onClose={onClose}
    >
      <DialogTitle>点位计划信息</DialogTitle>
      <DialogContent>
        <PlanItemBasicForm
          data={data}
          plan={plan}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">取消</Button>
        <Button onClick={handleSubmit}>保存</Button>
      </DialogActions>
    </Dialog>
  )
}

export default PlanItemUpdate
