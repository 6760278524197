import React from 'react'
import Layout from '../components/Layout'
import { useRouteMatch, Switch } from 'react-router-dom'
import PrivateRoute from '../components/PrivateRoute'

import UserList from './UserList'
import UserUpdate from './UserUpdate'

const User = () => {
  const { path } = useRouteMatch()
  const breadcrumbs = {
    list: [
      { name: '用户管理' }
    ],
    create: [
      { name: '用户管理', link: `${path}` },
      { name: '添加' },
    ],
    update: [
      { name: '用户管理', link: `${path}` },
      { name: '详情' },
    ]
  }
  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <UserList />
      </PrivateRoute>
      {/* <PrivateRoute path={`${path}/create`}>
        <Layout breadcrumbs={breadcrumbs.create}>
          <UserCreate />
        </Layout>
      </PrivateRoute> */}
      <PrivateRoute path={`${path}/:id`}>
        <Layout breadcrumbs={breadcrumbs.update}>
          <UserUpdate />
        </Layout>
      </PrivateRoute>
    </Switch>
  )
}

export default User
