import React, { useState, useEffect } from 'react'
import { ExpansionPanel, ExpansionPanelSummary, Typography, ExpansionPanelDetails, Grid, TextField, Select, FormControl, Checkbox, FormControlLabel, InputLabel, MenuItem, Button, Card } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/styles'
import { fetchCarList, fetchMediumPositionList, fetchMediumItemList, fetchPlanItemUpdate } from '../api'
import DeleteButton from '../components/DeleteButton'
const useStyles = makeStyles({
  heads: {
    borderBottom: '1px solid #EBEEF5'
  },
  headsTitle: {
    color: '#1C233F',
    fontSize: 18
  },
  details: {
    lineHeight: 2.5,
    paddingTop: 20
  },
  selects: {
    height: 40,
    width: 180,
  },
  chip: {
    fontSize: 14,
    height: 24,
    marginRight: 4
  },
  inputs: {

  },
  flexInput: {
    display: 'flex',
    flexDirection: 'row',
    margin: '10px 0',
    marginRight: '4%'
  },
  label: {
    color: "#666666",
    width: 160,
    fontSize: 16,
    lineHeight: 2 / 5
  },
  paddings: {
    padding: 20,
    paddingTop: 0
  },
  dateBox: {

  },
  dateHead: {

  },
  dateBtnBox: {
    display: 'flex',
    flexDirection: "row",
    flexWrap: 'wrap',
    width: 210
  },
  dateBtn: {
    height: 20,
    width: 20,
    fontSize: 10,
    minWidth: 0,
    lineHeight: 0,
    borderRadius: 10,
    padding: 0,
    margin: 5
  }
})

const Inputs = ({ type, types, multiline = false, defaultValue, name, xs, disabled = false, register = () => { }, errors = [] }) => {
  const classes = useStyles()
  return (
    <Grid item xs={xs[1]} sm={xs[0]} className={classes.paddings}>
      <TextField
        name={type}
        size='small'
        type={types}
        defaultValue={defaultValue}
        variant="outlined"
        label={name}
        multiline={multiline}
        rows={multiline ? 4 : 1}
        fullWidth
        disabled={disabled}
        inputRef={register({})}
        error={Boolean(errors[type])}
        helperText={errors[type] ? errors[type].message : null} />
    </Grid>
  )
}
const Selects = ({ type, label, useName = false, defaultValue = [], multiple = false, id, name, options = [], onChange }) => {
  const [personName, setPersonName] = useState('');
  const handleChange = event => {
    setPersonName(event.target.value)
    onChange(type, event.target.value)
  };
  return (
    <FormControl
      fullWidth
      variant="outlined"
      size='small'>
      <InputLabel style={{ background: 'white', padding: '0 2px' }}>{label}</InputLabel>
      <Select
        multiple={multiple}
        style={{ height: 37.28 }}
        value={personName ? personName : defaultValue}
        onChange={handleChange}
      >
        {options.map(item => (
          <MenuItem key={item[id]} value={useName ? item[name] : item[id]}>
            {item[name]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
// 基本信息
export const RenderBasic = ({ detail, selects, setSelects, planDetail, setPlanDetail,register, errors }) => {
  const [carsItem, setCarsItem] = useState([])
  const [pstItem, setPstItem] = useState([])
  const [styleItem, setStyleItem] = useState(null)
  const classes = useStyles()
  useEffect(() => {
    const fetchData = async () => {
      const query = `'medium=${detail}`
      try {
        const res = await fetchCarList(0, 100)
        const pst = await fetchMediumPositionList(query)
        setCarsItem(res.results)
        setPstItem(pst)
        if (planDetail.position) {
          fetchItem(planDetail.position)
        }
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
  }, [])
  // 删除模板
  const clearTheme = () => {
    const form = {
      ...planDetail,
      theme: ''
    }
    try {
      fetchPlanItemUpdate(planDetail.id, form)
      setPlanDetail(form)
    } catch (error) {
      console.error(error)
    }
  }
  // 通过广告位置id获取广告形式列表
  const fetchItem = async (item) => {
    try {
      const sty = await fetchMediumItemList(item)
      setStyleItem(sty)
    } catch (error) {
      console.error(error)
    }
  }

  const onChangeSelect = async (type, item) => {
    if (type === 'position') {
      fetchItem(item)
    }
    let forms = {
      ...selects,
      [type]: item
    }
    setSelects(forms)
  }
  return (
    <ExpansionPanel defaultExpanded={true} elevation={0}>
      <ExpansionPanelSummary
        className={classes.heads}
        expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.headsTitle}>基本信息</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container item xs={12} sm={10} className={classes.details}>
          <Grid item container xs={12} sm={8}>
            {detail ? <Inputs disabled={true} defaultValue={detail.is_zone ? '区域计划' : '总部计划'} name={'投放计划'} xs={[6, 6]}></Inputs> : null}
            {detail ? <Inputs disabled={true} defaultValue={detail.medium} name={'媒体'} xs={[6, 6]}></Inputs> : null}
            {/* <Grid item container xs={12} sm={6} className={classes.paddings}>
              <Grid item xs={4}><Typography>总部计划：</Typography></Grid>
              <Grid item xs={8}><Typography>{detail.is_zone ? '区域计划' : '总部计划'}</Typography></Grid>
            </Grid>
            <Grid item container xs={12} sm={6} className={classes.paddings}>
              <Grid item xs={4}><Typography>媒体：</Typography></Grid>
              <Grid item xs={8}><Typography>{detail.medium}</Typography></Grid>
            </Grid> */}
          </Grid>
          <Grid item container xs={12} sm={8}>
            <Grid item xs={12} sm={6} className={classes.paddings}>
              <Selects
                type={'position'}
                label='广告位置'
                id='id'
                name='name'
                defaultValue={planDetail && planDetail.position}
                onChange={onChangeSelect}
                options={pstItem}></Selects>
            </Grid>
            {planDetail && planDetail.style && styleItem ? <Grid item xs={12} sm={6} className={classes.paddings}>
              <Selects
                type='style'
                label='广告形式'
                id='id'
                name='name'
                defaultValue={planDetail && planDetail.style}
                onChange={onChangeSelect}
                options={styleItem}></Selects>
            </Grid> : styleItem ? <Grid item xs={12} sm={6} className={classes.paddings}>
              <Selects
                type='style'
                label='广告形式'
                id='id'
                name='name'
                onChange={onChangeSelect}
                options={styleItem}></Selects>
            </Grid> : null}
          </Grid>
          <Grid item container xs={12} sm={8}>
            <Grid item xs={12} sm={9} className={classes.paddings}>
              <Selects
                type={'cars'}
                label='车型'
                id='id'
                useName={true}
                name='name'
                defaultValue={planDetail && planDetail.cars}
                multiple={true}
                onChange={onChangeSelect}
                options={carsItem}></Selects>
            </Grid>
          </Grid>
          {planDetail && planDetail.theme && <Grid item container xs={12} sm={8}>
            <Inputs disabled={true} defaultValue={planDetail.theme} name={'关联模板'} xs={[6, 12]}></Inputs>
            <Grid item xs={12} sm={6} className={classes.paddings}>
              <DeleteButton title='清空模板' onClick={clearTheme} />
            </Grid>
          </Grid>}
          <Inputs type={'statistics'} defaultValue={planDetail.statistics} name={'统计代码'} multiline={true} xs={[12, 12]} register={register} errors={errors}></Inputs>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
// 点位区域计划 的 更多信息
export const RenderArea = ({ register, errors, planDetail }) => {
  const classes = useStyles()
  const extra = planDetail.extra
  return (
    <ExpansionPanel defaultExpanded={true} elevation={0} style={{ marginBottom: 20 }}>
      <ExpansionPanelSummary
        className={classes.heads}
        expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.headsTitle}>更多信息</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container item xs={12} sm={10} className={classes.details}>
          <Grid item container xs={12}>
            <Inputs type={'sum'} defaultValue={extra && extra.sum} types='number' name={'金额'} xs={[4, 12]} register={register} errors={errors}></Inputs>
            <Inputs type={'quantity'} defaultValue={extra && extra.quantity} types='number' name={'投放量'} xs={[4, 12]} register={register} errors={errors}></Inputs>
            <Inputs type={'clew'} defaultValue={extra && extra.clew} types='number' name={'虚拟线索量'} xs={[4, 12]} register={register} errors={errors}></Inputs>
            <Inputs type={'remark'} defaultValue={extra && extra.remark} name={'备注'} multiline={true} xs={[12, 12]} register={register} errors={errors}></Inputs>
          </Grid>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
// 点位总部计划 的 更多信息
export const RenderSuperior = ({ register, errors, planDetail }) => {
  const [useLink, setUseLink] = useState(true)
  const classes = useStyles()
  const checkedLink = (e) => {
    setUseLink(!useLink)
  }
  const extra = planDetail.extra
  return (
    <ExpansionPanel defaultExpanded={true} elevation={0} style={{ marginBottom: 20 }}>
      <ExpansionPanelSummary
        className={classes.heads}
        expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.headsTitle}>更多信息</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container item xs={12} sm={10} className={classes.details}>
          <Grid container item xs={12}>
            <Grid item xs={12} sm={3}>
              <FormControlLabel
                className={classes.paddings}
                control={<Checkbox color="primary" onChange={checkedLink} />}
                label="外链(Y/N)"
                labelPlacement="end"
              />
            </Grid>
            <Inputs type={'link'} disabled={useLink} defaultValue={extra && extra.link} name={'第三方链接'} xs={[5, 12]} register={register} errors={errors}></Inputs>
          </Grid>
          <Inputs type={'read_day'} defaultValue={extra && extra.read_day} types='number' name={'浏览量/天'} xs={[4, 12]} register={register} errors={errors}></Inputs>
          <Inputs type={'read_all'} defaultValue={extra && extra.read_all} types='number' name={'总浏览量'} xs={[4, 12]} register={register} errors={errors}></Inputs>
          <Inputs type={'click_day'} defaultValue={extra && extra.click_day} types='number' name={'预估点击/天'} xs={[4, 12]} register={register} errors={errors}></Inputs>
          <Inputs type={'click_all'} defaultValue={extra && extra.click_all} types='number' name={'总点击'} xs={[4, 12]} register={register} errors={errors}></Inputs>
          <Inputs type={'quantity'} defaultValue={extra && extra.quantity} types='number' name={'投放量'} xs={[4, 12]} register={register} errors={errors}></Inputs>
          <Inputs type={'clew'} defaultValue={extra && extra.clew} types='number' name={'虚拟线索量'} xs={[4, 12]} register={register} errors={errors}></Inputs>
          <Inputs type={'work'} defaultValue={extra && extra.work} name={'单位'} xs={[8, 12]} register={register} errors={errors}></Inputs>
          <Inputs type={'remark'} defaultValue={extra && extra.remark} name={'备注'} multiline={true} xs={[12, 12]} register={register} errors={errors}></Inputs>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
const getTimeLength = (month) => {
  var date = new Date();
  var year = date.getFullYear();
  var d = new Date(year, month, 0);
  let list = []
  let length = d.getDate()
  for (let i = 0; i < length; i++) {
    let tim = d.setDate(i + 1)
    let weeks = new Date(tim).getDay()
    list.push([null, weeks])
  }
  return list
}

export const RenderTime = (props) => {
  const { getTimeData = () => { } } = props
  const [value, handleDateChange] = useState(new Date());
  const [timeList, setTimeList] = useState([])
  const [standard, setStandard] = useState([])
  const [send, setSend] = useState([])
  const classes = useStyles()
  const year = value.getFullYear()
  const mouth = value.getMonth()
  const date = value.getDate()
  const list = getTimeLength(mouth)
  let times = `${year}${(mouth + 1) > 10 ? (mouth + 1) : '0' + (mouth + 1)}`
  const dateClick = async (i) => {
    let standardIndex = standard.indexOf(i + 1)
    let sendIndex = send.indexOf(i + 1)
    let standardDate = [...standard]
    let sendDate = [...send]
    let list = [...timeList]
    // 按钮点击蓝色，再次点击红色，在点击未选中 
    if (!(standardIndex >= 0) && !(sendIndex >= 0)) {
      standardDate.push(i + 1)
      list.push({ date: `${times}${i + 1 > 10 ? (i + 1) : '0' + (i + 1)}`, type: 1 })
    } else if (standardIndex >= 0 && !(sendIndex >= 0)) {
      standardDate.splice(standardIndex, 1)
      sendDate.push(i + 1)

      let listIndex = timeList.indexOf({ date: `${times}${i + 1 > 10 ? (i + 1) : '0' + (i + 1)}`, type: 1 })
      list.splice(listIndex, 1)
      console.log(listIndex, { date: `${times}${i + 1 > 10 ? (i + 1) : '0' + (i + 1)}`, type: 1 })
      list.push({ date: `${times}${i + 1 > 10 ? (i + 1) : '0' + (i + 1)}`, type: 2 })
    } else if ((sendIndex >= 0)) {
      sendDate.splice(sendIndex, 1)
      let listIndex = timeList.indexOf({ date: `${times}${i + 1 > 10 ? (i + 1) : '0' + (i + 1)}`, type: 2 })
      list.splice(listIndex, 1)
    } else {
      console.log('-----');
    }
    setSend(sendDate)
    setStandard(standardDate)
    setTimeList(list)
    console.log(list)
    getTimeData(list)
  }
  return (
    <ExpansionPanel defaultExpanded={true} elevation={0}>
      <ExpansionPanelSummary
        className={classes.heads}
        expandIcon={<ExpandMoreIcon />}>
        <Typography className={classes.headsTitle}>投放时间</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid>
          {year + '/' + (mouth + 1) + '/' + date}
          <Card elevation={0} className={classes.dateBtnBox}>
            {list.map((item, index) => {
              let isStandard = standard.indexOf(index + 1) >= 0
              let isSend = send.indexOf(index + 1) >= 0
              return (
                <Button key={index}
                  className={classes.dateBtn}
                  onClick={() => dateClick(index)}
                  style={isStandard ? {
                    backgroundColor: '#D5ECFF',
                    color: '#239CFF'
                  } : isSend ? {
                    backgroundColor: '#FFEBF3',
                    color: '#FF3B7C'
                  } : {}}
                >{index + 1}</Button>
              )
            })}
          </Card>
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
