import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'

const GroupForm = (props) => {
  const { data, errors, onChange } = props
  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          name="name"
          value={(data&&data.name) ? data.name : ''}
          onChange={onChange}
          variant="outlined"
          size="small"
          label="名称"
          error={errors.name}
          helperText={errors.name ? errors.name.message : null}
        />
      </Grid>
    </Grid>
  )
}

export default GroupForm
