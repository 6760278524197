import React, { useState, useEffect } from 'react'
import { fetchCueList } from '../api'
import { Typography, Card, CardContent, Table, TableRow, TableHead, TableBody, TableCell } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import moment from 'moment-timezone'

const useStyles = makeStyles(theme => ({
  table: {
    marginTop: theme.spacing(2),
    '& tr > *': {
      padding: '6px 12px',
    },
    '& th': {
      whiteSpace: 'nowrap'
    },
    '& td': {
      fontSize: '0.7rem'
    }
  }
}))

const CueTable = () => {
  const classes = useStyles()
  const [data, setData] = useState([])
  useEffect(() => {
    const fetchData = async() => {
      try {
        const res = await fetchCueList(0, 6)
        setData(res.results)
      } catch {
      }
    }
    fetchData()
  }, [])
  return (
    <Card elevation={0} style={{overflowX:'scroll'}}>
      <CardContent style={{maxHeight:'400px'}}>
        <Typography variant="h6" compoent="h4">最新线索</Typography>
        <Table size="small" className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>用户信息</TableCell>
              <TableCell>投放计划</TableCell>
              <TableCell>意向车型</TableCell>
              <TableCell>经销商</TableCell>
              <TableCell>创建时间</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map(row => (
              <TableRow key={row.id}>
                <TableCell>{row.name}, {row.city}<br />{row.mobile}</TableCell>
                <TableCell>{row.medium}<br />{row.medium_style}</TableCell>
                <TableCell>{row.car}</TableCell>
                <TableCell>{row.store}</TableCell>
                <TableCell>
                  {moment.tz(row.created, 'Asia/Shanghai').format('MM/DD hh:mm')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  )
}

export default CueTable
