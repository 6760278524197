import React, { useState } from 'react'
import { Typography, Grid, Paper, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import { DealerForm } from '../components/DealerForm'
import { fetchDealerCreate } from '../api'
import { useHistory } from 'react-router-dom'
import Filtrate from '../components/Filtrate'

const RenderBtn = () => {
  return (
    <Filtrate title='经销商管理' sumbitText='保存'></Filtrate>
  )
}

const renderActive = () => (
  <Paper>
    <Grid>
      <ExpansionPanel square defaultExpanded={true}>
        <ExpansionPanelSummary>
          <Typography>活动信息</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid>
            <Typography>2019.06.006 12:30</Typography>
            <Typography>标记线索失败</Typography>
          </Grid>
          <Grid></Grid>
          <Grid></Grid>
          <Grid></Grid>
          <Grid></Grid>
          <Grid></Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Grid>
  </Paper>
)


const DealerCreate = () => {
  const history = useHistory()
  const [city, setCity] = useState(null)
  const onChange = (e, v) => {
    console.log(e, v);
    setCity(v)
  }
  const handleSubmit = async (data) => {
    console.log(data);
    if (!city) {
      alert('请选择城市')
      return
    }
    const form = {
      ...data,
      user: {
        username: data.username,
        password: data.password
      },
      city: city,
      sort: data.sort ? data.sort : 0,
      extra: {
        contact1: data.contact1,
        contact2: data.contact2,
        contact3: data.contact3,
      }
    }
    try {
      await fetchDealerCreate(form)
      history.push('/dealers')
    } catch (error) {
      console.error('error', error)
      alert(error)
    }
  }
  return (
    <Grid title=''>
      <DealerForm formActions={<RenderBtn />} onSubmit={handleSubmit} onChangeText={onChange} />
      {false ? renderActive() : null}
    </Grid>
  )
}

export default DealerCreate
