import React, { useState, useEffect } from 'react'
import { fetchDealerSummary } from '../api'
import SummaryCard from './SummaryCard'
import { useHistory } from 'react-router-dom'

const DealerSummaryCard = () => {
  const history = useHistory()
  const [data, setData] = useState()
  useEffect(() => {
    const fetchData = async() => {
      try {
        const res = await fetchDealerSummary()
        setData(res)
      } catch {
      }
    }
    fetchData()
  }, [])

  const handleClick = () => {
    history.push('/dealers')
  }

  return (
    <SummaryCard
      title='经销商'
      total={data ? data.total : 0}
      icon={require('../images/total_dealer.png')}
      color='#FFF4DB'
      onClick={handleClick}
    />
  )
}

export default DealerSummaryCard
