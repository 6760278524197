import React from 'react'

const VideoBlock = (props) => {
  const { data } = props
  let styles = {}
  if(data.margin) {
    styles['margin'] = data.margin
  }
  if(data.padding) {
    styles['padding'] = data.padding
  }
  styles['textAlign'] = 'center'
  return (
    <div style={styles}>
      <video
        width={data.styles.width ? data.styles.width+"%" : '100%'}
        height={data.styles.height ? data.styles.height : 240}
        controls>
        <source src={data.content} type="video/mp4" />
      </video>
    </div>
  )
}

export default VideoBlock
