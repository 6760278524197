import React, { useState, useEffect } from 'react'
import { fetchPlanSummary } from '../api'
import SummaryCard from './SummaryCard'
import { useHistory } from 'react-router-dom'

const PlanSummaryCard = () => {
  const history = useHistory()
  const [data, setData] = useState()
  useEffect(() => {
    const fetchData = async() => {
      try {
        const res = await fetchPlanSummary()
        setData(res)
      } catch {
      }
    }
    fetchData()
  }, [])

  const handleClick = () => {
    history.push('/plan')
  }

  return (
    <SummaryCard
      title='本月计划'
      total={data ? data.this_month : 0}
      icon={require('../images/total_plan.png')}
      extra={data && `上月${data.last_month}, 增幅${data.rise}`}
      color='#DEE5FF'
      onClick={handleClick}
    />
  )
}

export default PlanSummaryCard
