import React from 'react'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputLabel from '@material-ui/core/InputLabel'
import RadioGroup from '@material-ui/core/RadioGroup'
import Radio from '@material-ui/core/Radio'
import ImagePicker from './ImagePicker'
import { makeStyles } from '@material-ui/styles'

const themes = [
  ["default", "默认"],
  ["redone", "红色2019"],
  ["redone_top", "红色2019上"],
  ["redone_left", "红色2019左"],
]

const useStyles = makeStyles(theme => ({
  tab: {
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(1),
    borderBottom: '1px dashed lightgrey'
  },
  actions: {
    marginTop: theme.spacing(2),
    '& *': {
      marginRight: theme.spacing(1)
    }
  }
}))

const TabsBlockEdit = (props) => {
  const classes = useStyles()
  const { data, onSave } = props

  const handleCreate = event => {
    let children = [...data.children]
    children.push({'id':children.length, 'name':'Example Title', 'video':'https://www.w3schools.com/html/mov_bbb.mp4', 'image':''})
    onSave({...data, children})
  }

  const handleChange = (event, id, name=null, value=null) => {
    if(name === null) {
      name = event.target.name
    }
    if(value === null) {
      value = event.target.value
    }
    let children = [...data.children]
    children = children.map(item => {
      if(item.id === id) {
        return {...item, [name]:value}
      } else {
        return item
      }
    })
    onSave({...data, children})
  }

  const handleRemove = id => {
    const children = data.children.filter(item => item.id !== id)
    onSave({...data, children})
  }

  const handleInput = event => {
    let styles = {}
    if(data.styles) {
      styles = {...data.styles}
    }
    styles[event.target.name] = event.target.value
    onSave({...data, styles})
  }

  return (
    <div>
      {data.children && data.children.map(item => (
        <div key={item.id} className={classes.tab}>
          <Typography
            variant="h5"
            component="h5"
          >Tab#{item.id+1}</Typography>
          <TextField
            fullWidth
            margin="normal"
            label="标题"
            name="name"
            value={item.name}
            onChange={(event) => handleChange(event, item.id)}
          />
          <FormControl margin="normal" style={{padding:'1rem 0 0'}}>
            <InputLabel shrink>封面图</InputLabel>
            {item.image && (
              <img
                src={item.image}
                width="100%"
                style={{marginBottom:8}}
                alt="video-cover" />
            )}
            <ImagePicker
              value={item.image}
              onChange={(value) => handleChange(null, item.id, "image", value)} />
          </FormControl>
          <TextField
            fullWidth
            multiline
            rows={2}
            margin="normal"
            label="视频链接"
            name="video"
            value={item.video}
            onChange={(event) => handleChange(event, item.id)}
          />
          <Button
            variant="contained"
            margin="normal"
            color="secondary"
            size="small"
            onClick={()=>handleRemove(item.id)}
          >删除</Button>
        </div>
      ))}
      {data.children && data.children.length < 3 && (
        <div className={classes.actions}>
          <Button
            variant="contained"
            size="small"
            onClick={handleCreate}
          >增加卡片</Button>
        </div>
      )}
      <FormControl component="fieldset" style={{marginTop:16}}>
        <FormLabel component="legend">主题</FormLabel>
        <RadioGroup
          row
          name="theme"
          aria-label="style-theme"
          value={data && data.styles && data.styles.theme ? data.styles.theme : "default"}
          onChange={handleInput}>
          {themes.map(row => (
            <FormControlLabel
              key={row[0]}
              value={row[0]}
              control={<Radio />}
              label={row[1]} />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  )
}

export default TabsBlockEdit
