import React from 'react'
import { makeStyles } from '@material-ui/styles'
import moment from 'moment-timezone'

const DateBox = (props) => {
  const { value, day, onClick, ...other } = props
  if(!day) {
    return <td {...other} />
  }
  const dateString = moment(day).tz('Asia/Shanghai').format('YYYYMMDD')
  let style = {}
  const res = value.find(i => i[0] === dateString)
  if(res && res[1] === 1) {
    style = {
      backgroundColor:'#74b9ff',
      color:'white'
    }
  } else if (res && res[1] === 2) {
    style = {
      backgroundColor:'#fab1a0',
      color:'white'
    }
  }
  return (
    <td
      onClick={()=>onClick(dateString)}
      style={style}
      {...other}
    >{day ? day.getDate() : ''}</td>
  )
}

const useStyles = makeStyles(theme => ({
  calendar: {
    '& table': {
      width: '100%',
    },
    '& th': {
      fontSize: '16px',
      textAlign: 'center',
      color: 'rgb(117, 117, 117)',
    },
    '& td': {
      textAlign: 'center',
      height: '30px',
      lineHeight: '30px',
      fontSize: '16px',
      cursor: 'pointer'
    },
    '& td:hover': {
      backgroundColor: '#eee',
      opacity: 0.8
    }
  },
  title: {
    textAlign: 'center',
    fontSize: '18px',
    lineHeight: '40px',
  },
}))

const Calendar = (props) => {
  const { date, days, value, onClick } = props
  const classes = useStyles()

  return (
    <div className={classes.calendar}>
      <table>
        <caption className={classes.title}>
          {moment(date).tz('Asia/Shanghai').format('YYYY/MM')}
        </caption>
        <thead>
          <tr>
            <th>周一</th>
            <th>周二</th>
            <th>周三</th>
            <th>周四</th>
            <th>周五</th>
            <th>周六</th>
            <th>周日</th>
          </tr>
        </thead>
        <tbody>
          {days.map((row, tr) => (
            <tr key={tr}>
              {row.map((day, td) => (
                <DateBox
                  key={td}
                  value={value}
                  day={day}
                  onClick={onClick}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default Calendar
