import React, { useState, useEffect } from 'react'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import { useHistory, useParams } from 'react-router-dom'
import { fetchTemplateCreate, fetchFrameDetail } from '../api'

const TemplateCreateFull = () => {
  const { id } = useParams()
  const [data, setData] = useState("")
  const [frame, setFrame] = useState({})
  const history = useHistory()

  useEffect(() => {
    const fetchData = async() =>  {
      try {
        const res = await fetchFrameDetail(id)
        setFrame(res)
      } catch {}
    }
    fetchData()
  }, [id])

  const handleChange = event => {
    setData(event.target.value)
  }

  const handleBack = () => {
    history.goBack()
  }

  const handleSubmit = async () => {
    try {
      const req = {
        name: data,
        content: frame.content,
        mode: frame.mode,
        bgimage: frame.bgimage
      }
      const res = await fetchTemplateCreate(req)
      history.push(`/templates/${res.id}`)
    } catch {
      alert('添加失败')
    }
  }

  return (
    <Container maxWidth="sm">
      <Typography
        variant="h4"
        compnent="h2"
        style={{margin:"16px 0"}}>添加模板</Typography>
      <Box mb={2}>
        <TextField
          fullWidth
          label="名称"
          required
          name="name"
          margin="normal"
          helperText='请输入模板名称, 如"2020春节"'
          onChange={handleChange}
        />
      </Box>
      <Box display="flex" justifyContent="flex-start">
        <Button
          variant="contained"
          style={{marginRight:16}}
          onClick={handleBack}
        >返回</Button>
        <Button
          disabled={data.length <= 0}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >下一步</Button>
      </Box>
    </Container>
  )
}

export default TemplateCreateFull
