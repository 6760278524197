import React from 'react'
import { useRouteMatch, Link, Switch  } from 'react-router-dom'
import PrivateRoute from '../components/PrivateRoute'

import CarList from './CarList'
import CarCreate from './CarCreate'
import CarUpdate from './CarUpdate'

const Car = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <CarList />
      </PrivateRoute>
      <PrivateRoute path={`${path}/create`}>
        <CarCreate />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:id`}>
        <CarUpdate />
      </PrivateRoute>
    </Switch>
  )
}

export default Car
