import React, { useState } from 'react'
import Typography from '@material-ui/core/Typography'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import ImageOutlined from '@material-ui/icons/ImageOutlined'
import TextFieldsOutlined from '@material-ui/icons/TextFieldsOutlined'
import RateReviewOutlined from '@material-ui/icons/RateReviewOutlined'
import TabOutlined from '@material-ui/icons/TabOutlined'
import PlayCircleOutlineOutlined from '@material-ui/icons/PlayCircleOutlineOutlined'
import ViewColumnOutlinedIcon from '@material-ui/icons/ViewColumnOutlined'
import Crop32OutlinedIcon from '@material-ui/icons/Crop32Outlined'
import ViewCarouselOutlinedIcon from '@material-ui/icons/ViewCarouselOutlined';
import ViewColumnIcon from '@material-ui/icons/ViewColumn';
import { makeStyles } from '@material-ui/core/styles'

const types = ['text', 'image', 'form', 'tabs', 'video', 'carousel', 'empty']

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: props => props.selected ? 'rgba(255, 222, 236, 0.4)' : 'transparent',
  }
}))

const BlockRadio = (props) => {
  const { type, selected, onChange, disabled } = props
  const classes = useStyles({selected})
  const handleClick = (type) => {
    if(!disabled) {
      onChange(type)
    }
  }
  return (
    <div
      className={classes.root}
      onClick={()=>handleClick(type)}>
      {type === 'text' && <TextFieldsOutlined color="action" />}
      {type === 'image' && <ImageOutlined color="action" />}
      {type === 'tabs' && <TabOutlined color="action" />}
      {type === 'form' && <RateReviewOutlined color="action" />}
      {type === 'video' && <PlayCircleOutlineOutlined color="action" />}
      {type === 'carousel' && <ViewCarouselOutlinedIcon color="action" />}
      {/*type === 'grid' && <ViewColumnOutlinedIcon color="action" />*/}
      {/*type === 'layout' && <ViewColumnIcon color="action" />*/}
      {type === 'empty' && <Crop32OutlinedIcon color="action" />}
      <Typography
        variant="body2"
        component="span"
        color="textSecondary"
      >
        {type === 'text' && '文本'}
        {type === 'image' && '图片'}
        {type === 'form' && '表单'}
        {type === 'tabs' && 'Tabs'}
        {type === 'video' && '视频'}
        {type === 'carousel' && '轮播图'}
        {/*type === 'grid' && '分列'*/}
        {/*type === 'layout' && '布局'*/}
        {type === 'empty' && '空白'}
      </Typography>
    </div>
  )
}

const BlockCreate = (props) => {
  const [type, setType] = useState(null)
  const { open, onClose, onCreate } = props
  const handleChange = (value) => {
    setType(value)
  }
  const handleClick = () => {
    onCreate(type)
  }
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      onClose={onClose}
			PaperProps={{ elevation: 0 }}
      aria-labelledby="add-more"
      open={open}>
      <DialogTitle id="add-more-title">选择区块类型</DialogTitle>
      <DialogContent>
      <Grid container>
        {types.map((item,index) => (
          <Grid item xs={3} key={index}>
            <BlockRadio
              type={item}
              selected={type === item}
              onChange={handleChange} />
          </Grid>
        ))}
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color="primary">取消</Button>
      <Button
        disabled={!type}
        onClick={handleClick}
        color="primary">确定</Button>
    </DialogActions>
    </Dialog>
  )
}

export default BlockCreate
