import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import { CueForm } from '../components/CueForm'
import { fetchCueCreate } from '../api'
import { useHistory } from 'react-router-dom'
import Filtrate from '../components/Filtrate'
const RenderBtn = () => {
  return (
    <Filtrate title='线索管理' sumbitText='保存'></Filtrate>
  )
}
const CueCreate = () => {
  const [select, setSelect] = useState({})
  const history = useHistory()
  const handleChange = (data) => {
    setSelect(data)
  }
  const handleSubmit = async (e) => {
    console.log(select);
    if (!select.car){
      alert('请选择车型')
      return
    }
    if (!select.city){
      alert('请选择城市')
      return
    }
    if (!select.store){
      alert('请选择经销商')
      return
    }
    const data = {
      ...e,
      ...select
    }
    try {
      await fetchCueCreate(data)
      history.goBack()
    } catch (error) {
      alert(error)
      console.error(error)
    }
  }
  return (
    <Grid>
      <CueForm formActions={<RenderBtn />} onSubmit={handleSubmit} onChange={handleChange} />
    </Grid>
  )
}

export default CueCreate
