import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'

const DeleteButton = (props) => {
  const { onClick, title, ...extra } = props
  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  const handleClick = () => {
    setOpen(false)
    onClick()
  }
  return (
    <React.Fragment>
      <Button
        color="secondary"
        onClick={handleOpen}
        {...extra}
      >{title ? title : '删除'}</Button>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={open}
        PaperProps={{ elevation: 0 }}
        onClose={handleClose}>
        <DialogTitle>确定删除?</DialogTitle>
        <DialogContent>
          <DialogContentText>删除后无法恢复</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={handleClose}
          >
            取消
          </Button>
          <Button
            color="secondary"
            onClick={handleClick}
          >删除</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}

export default DeleteButton
