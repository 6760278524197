import React, { useState, useEffect } from 'react'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import { ResponsiveBar } from '@nivo/bar'
import { fetchMediumRank } from '../api'

const MediumRank = () => {
  const theme = useTheme()
  const [data, setData] = useState([])
  useEffect(() => {
    const fetchData = async() => {
      try {
        const res = await fetchMediumRank()
        setData(res)
      } catch {
      }
    }
    fetchData()
  }, [])
  let layout = 'vertical'
  if(useMediaQuery(theme.breakpoints.down('sm'))) {
    layout = 'horizontal'
  }

  return (
    <Card elevation={0}>
      <CardContent style={{height:'400px'}}>
      <Typography variant="h6" compoent="h4">媒体线索排行</Typography>
    <ResponsiveBar
      layout={layout}
      colors="#3267FA"
      data={data}
      keys={['count']}
      indexBy="medium"
      margin={{ top: 30, right: 20, bottom: 50, left: 60 }}
      padding={0.3}
      axisTop={null}
      axisRight={null}
      labelSkipWidth={12}
      labelSkipHeight={12}
      animate={true}
      motionStiffness={90}
      motionDamping={15}
    />
  </CardContent>
</Card>
  )
}

export default MediumRank
