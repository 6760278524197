import React from 'react'
import { CardMedia, Typography, Container, Grid, Card } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import LoginForm from '../components/LoginForm'
import { fetchLogin } from '../api'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'scatch',
    justifyContent: 'space-between',
  },
  title: {
    marginTop: theme.spacing(6),
    color: theme.palette.common.white,
  },
  cover: {
    height: '100%'
  },
  box: {
    marginBottom: theme.spacing(4)
  },
  footer: {
    color: theme.palette.grey[200],
  }
}))

const Login = () => {
  const classes = useStyles()
  const history = useHistory()

  const handleSubmit = async (data) => {
    try {
      const res = await fetchLogin(data)
      if (res) {
        localStorage.setItem('access', res.access)
        localStorage.setItem('refresh', res.refresh)
        history.replace('/')
      }
    } catch (error) {
      alert('登录失败')
      console.error(error)
    }
  }
  return (
    <CardMedia
      image={require('../images/login_bg.png')}
      className={classes.root}>
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          component="h1"
          className={classes.title}
        >{process.env.REACT_APP_TITLE}</Typography>
      </Container>
      <Container maxWidth="md">
      <Card className={classes.box} elevation={0}>
        <Grid container>
          <Grid item xs={false} sm={4} md={6}>
            <CardMedia
              image={require('../images/login_left.png')}
              className={classes.cover}
            />
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            <LoginForm onSubmit={handleSubmit} />
          </Grid>
        </Grid>
        </Card>
      </Container>
      <Typography
        gutterBottom
        variant="body1"
        component="p"
        className={classes.footer}
        align="center">{process.env.REACT_APP_COPYRIGHT}</Typography>
    </CardMedia>
  )
}

export default Login
