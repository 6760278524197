import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import { makeStyles } from '@material-ui/styles'
import { useParams, useHistory } from 'react-router-dom'
import Layout from '../components/Layout'
import TipForm from '../components/TipForm'
import DeleteButton from '../components/DeleteButton'
import { fetchTipDelete, fetchTipDetail, fetchTipUpdate } from '../api'

const useStyles = makeStyles(theme => ({
  actions: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row-reverse',
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },
}))

const TipUpdate = () => {
  const { id } = useParams()
  const [data, setData] = useState({})
  const [errors, setErrors] = useState({title:false})
  const history = useHistory()
  const classes = useStyles()

  useEffect(()=>{
    const fetchData = async() => {
      try {
        const res = await fetchTipDetail(id)
        setData(res)
      } catch (error) {
        console.log('error')
      }
    }

    fetchData()
  }, [id])

  const handleChange = event => {
    let error = false
    const name = event.target.name
    const value = event.target.value
    setData({...data, [name]:value})
    setErrors({...errors, [name]:error})
  }

  const handleSubmit = async() => {
    try {
      await fetchTipUpdate(id, data)
      history.push('/tip')
    } catch {
      alert('操作失败')
    }
  }

  const handleDelete = async() => {
    try {
      await fetchTipDelete(id)
      history.push('/tip')
    } catch(error) {
      console.log(error)
      alert('操作失败')
    }
  }

  const checkForm = () => {
    let check = true
    if(!data.title || errors.title) {
      check = false
    }
    return check
  }

  const breadcrumbs = [
    { name: "小贴士", link: '/tip' },
    { name: "详情" },
  ]

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <div className={classes.actions}>
        <Button
          size="small"
          variant="contained"
          color="primary"
          disabled={!checkForm()}
          onClick={handleSubmit}>保存</Button>
        <DeleteButton
          variant="outlined"
          onClick={handleDelete}>删除</DeleteButton>
      </div>
      <ExpansionPanel
        expanded={true}
        elevation={0}
        style={{marginBottom:16}}>
				<ExpansionPanelSummary>
					<Typography variant="h6" component="h6">基础信息</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>
          <TipForm
            data={data}
            errors={errors}
            onChange={handleChange}
          />
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Layout>
  )
}

export default TipUpdate
