import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    //padding: theme.spacing(2)
  }
}))

const TabPanel = (props) => {
  const classes = useStyles()
  const { children, value, index, ...other } = props
  return (
    <div
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      hidden={value !== index}
      role="tabpanel"
      className={classes.root}
      {...other}
    >
      {value === index && children}
    </div>
  )
}

export default TabPanel
