import React, { useState, useEffect } from 'react'
import Layout from '../components/Layout'
import { Table, TableBody, TableRow, TableCell, Typography } from '@material-ui/core'
import { fetchProfile } from '../api'
import { useHistory } from 'react-router-dom'

const Profile = () => {
  const [data, setData] = useState(null)
  let history = useHistory()

  useEffect(()=>{
    const fetchData = async() => {
      try {
        const res = await fetchProfile()
        setData(res)
        localStorage.setItem('user', res.username)
      } catch (error) {
      }
    }

    fetchData()
  }, [history])

  return (
    <Layout title="我的信息">
      <Typography
        variant="h4"
        gutterBottom>我的信息</Typography>
      {data && (
        <Table>
          <TableBody>
            <TableRow hover>
              <TableCell>用户名</TableCell>
              <TableCell>{data.username}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      )}
    </Layout>
  )
}

export default Profile
