import React from 'react'
import Slider from 'react-slick'

const CarouselBlock = (props) => {
  const { data } = props
  //const height = data.styles && data.styles.height ? data.styles.height : "400"
  return (
    <Slider autoplay={true} infinite={true}>
      {data.children.map(row => (
        <div key={row.id}>
          <img
            width="100%"
            src={row.content}
            alt={`slider${row.id}`} />
            {/**<div style={{
            height:height+"px",
            backgroundImage:`url(${row.content})`,
            backgroundSize:"cover",
            backgroundRepeat:"norepeat",
            backgroundPosition:"center top",
          }} />**/}
      </div>
      ))}
    </Slider>
  )
}

export default CarouselBlock
