import React from 'react'
import { useRouteMatch, Link, Switch  } from 'react-router-dom'
import PrivateRoute from '../components/PrivateRoute'

import CueStatusList from './CueStatusList'
import CueStatusCreate from './CueStatusCreate'
import CueStatusUpdate from './CueStatusUpdate'

const CueStatus = () => {
  const { path } = useRouteMatch()
  return (
    <Switch>
      <PrivateRoute exact path={path}>
        <CueStatusList />
      </PrivateRoute>
      <PrivateRoute path={`${path}/create`}>
        <CueStatusCreate />
      </PrivateRoute>
      <PrivateRoute path={`${path}/:id`}>
        <CueStatusUpdate />
      </PrivateRoute>
    </Switch>
  )
}

export default CueStatus
