import React from 'react'
import Box from '@material-ui/core/Box'

const ImageBlock = (props) => {
  const { data } = props
  let styles = {}
  if(data.margin) {
    styles['margin'] = data.margin
  }
  if(data.padding) {
    styles['padding'] = data.padding
  }
  return (
    <img
      width='100%'
      //height={200}
      //width={data.styles.width ? data.styles.width + "%" : '100%'}
      //height={data.styles.height ? data.styles.height : 'auto'}
      style={styles}
      src={data.content}
      alt={data.type} />

  )
  return (
    <Box
      textAlign="center"
      display="flex"
      alignItems="center"
      justifyContent="center">
    <img
      width='100%'
      height={200}
      //width={data.styles.width ? data.styles.width + "%" : '100%'}
      //height={data.styles.height ? data.styles.height : 'auto'}
      style={styles}
      src={data.content}
      alt={data.type} />
    </Box>
  )
}

export default ImageBlock
