import React, { useState } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  panel: {
    //paddingTop: theme.spacing(1)
  },
  box: {
    position: 'relative',
  },
  icon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }
}))

const TabPanel = (props) => {
  const {data, index, paused, onVideoPlay, onVideoPause} = props
  const classes = useStyles()
  const handleClick = event => {
    if(event.target.paused) {
      event.target.play()
      onVideoPlay()
    } else {
      event.target.pause()
      onVideoPause()
    }
  }
  return (
    <div
      key={data.id}
      className={classes.panel}
      role="tabpanel"
      id={`inner-tabpanel-${data.id}`}
      aria-labelledby={`inner-tab-${data.id}`}>
      {data.id === index && (
        <div className={classes.box}>
          {paused && (
            <img
              src={require("../images/play.png")}
              className={classes.icon}
              alt="plan-icon" />
          )}
          <video
            onClick={handleClick}
            width="100%"
            key={data.id}
            poster={data.image}>
            <source src={data.video} type="video/mp4" />
          </video>
        </div>
      )}
    </div>
  )
}

/**
 * 默认主题
 * 2020/03/07
 */
const Default = (props) => {
  const { data, index, onChange, ...other } = props
  return (
    <div>
      <Tabs
        indicatorColor="primary"
        value={index}
        variant="fullWidth"
        onChange={onChange}
        aria-label="default-tabs">
        {data.children && data.children.map(tab => (
          <Tab
            key={tab.id}
            label={tab.name}
            id={`inner-tabs-${tab.id}`}
            aria-controls={`inner-tabpanel-${tab.id}`} />
        ))}
      </Tabs>
      {data.children && data.children.map(tab=>(
        <TabPanel
          data={tab}
          index={index}
          key={tab.id}
          {...other}
        />
      ))}
    </div>
  )
}

/**
 * 红色2019左
 * 2020.03.07
 */
const RedoneLeft = (props) => {
  const { data, index, onChange, ...other } = props
  const useStyles = makeStyles({
    root: {
      padding: "0 1rem 1rem",
    },
    inner: {
      border: "2px solid #FFFAE0",
      padding: "3rem"
    },
    tabs: {
      display: 'none',
    },
    flexContainer: {
      //justifyContent: 'space-between',
      padding: '1.5rem',
      backgroundImage: `url(${require('../images/back_sp.png')})`,
      backgroundSize: '100% 100%',
      marginRight: '2rem'
    },
    tab: {
      flexBasis: 'initial',
      padding: '0.8rem 1rem',
      color: '#fffae3',
      backgroundColor: 'transparent',
      backgroundImage: `url(${require('../images/tabs_border01.png')})`,
      backgroundSize: '100% 100%',
      fontWeight: '800',
      fontSize: '1.35rem',
      marginBottom: '1.5rem'
    },
    tabWrapper: {
    },
    tabSelected: {
      backgroundColor: "#fffae3",
      color: "#8c1400",
    }
  })
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.inner}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Tabs value={index}
              orientation='vertical'
              variant="fullWidth"
              onChange={onChange}
              classes={{
                indicator:classes.tabs,
                flexContainer:classes.flexContainer
              }}
              aria-label="inner tabs">
              {data.children && data.children.map(tab => (
                <Tab
                  classes={{
                    root:classes.tab,
                    wrapper: classes.tabWrapper,
                    selected:classes.tabSelected
                  }}
                  key={tab.id}
                  label={tab.name}
                  id={`inner-tabs-${tab.id}`}
                  aria-controls={`inner-tabpanel-${tab.id}`}
                />
              ))}
            </Tabs>
          </Grid>
          <Grid item xs={12} sm={8} style={{paddingLeft:'2rem'}}>
            {data.children && data.children.map(tab=>(
              <TabPanel
                value={index}
                data={tab}
                index={index}
                key={tab.id}
                {...other}
              />
            ))}
          </Grid>
        </Grid>
    </div>
    </div>
  )


}

/**
 * 红色2019上导航
 * 2020.03.07
 */
const RedoneTop = (props) => {
  const { data, index, onChange, ...other } = props
  const useStyles = makeStyles({
    root: {
      padding: "0 1rem 1rem",
    },
    inner: {
      border: "2px solid #FFFAE0",
      padding: "0.5rem"
    },
    tabs: {
      display: 'none'
    },
    flexContainer: {
      justifyContent: 'space-between',
      paddingBottom: '0.5rem',
    },
    tab: {
      flexGrow: 0,
      minWidth: '30%',
      minHeight: 0,
      padding: '0px 16px',
      color: '#fffae3',
      backgroundColor: 'transparent',
      backgroundImage: `url(${require('../images/tabs_border01.png')})`,
      backgroundSize: '100% 100%',
      fontWeight: '800',
      fontSize: '1rem'
    },
    tabWrapper: {
    },
    tabSelected: {
      backgroundColor: "#fffae3",
      color: "#8c1400",
    }
  })
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.inner}>
      <Tabs value={index}
        variant="fullWidth"
        onChange={onChange}
        classes={{
          indicator:classes.tabs,
          flexContainer:classes.flexContainer
        }}
        aria-label="inner tabs">
        {data.children && data.children.map(tab => (
          <Tab
            classes={{
              root:classes.tab,
              wrapper: classes.tabWrapper,
              selected:classes.tabSelected
            }}
            key={tab.id}
            label={tab.name}
            id={`inner-tabs-${tab.id}`}
            aria-controls={`inner-tabpanel-${tab.id}`}
          />
        ))}
      </Tabs>
      {data.children && data.children.map(tab=>(
        <TabPanel
          value={index}
          data={tab}
          index={index}
          key={tab.id}
          {...other}
        />
      ))}
    </div>
    </div>
  )

}

/**
 * 红色主题1
 * 20200227
 */
const Redone = (props) => {
  const { data, index, onChange, ...other } = props
  const useStyles = makeStyles({
    root: {
      padding: "0 1rem 1rem",
    },
    inner: {
      border: "2px solid #FFFAE0",
      padding: "0.5rem"
    },
    tabs: {
      display: 'none'
    },
    flexContainer: {
      justifyContent: 'space-between',
      paddingTop: '0.5rem',
    },
    tab: {
      flexGrow: 0,
      minWidth: '30%',
      minHeight: 0,
      padding: '0px 16px',
      color: '#fffae3',
      backgroundColor: 'transparent',
      backgroundImage: `url(${require('../images/tabs_border01.png')})`,
      backgroundSize: '100% 100%',
      fontWeight: '800',
      fontSize: '1rem'
    },
    tabWrapper: {
    },
    tabSelected: {
      backgroundColor: "#fffae3",
      color: "#8c1400",
    }
  })
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.inner}>
      {data.children && data.children.map(tab=>(
        <TabPanel
          value={index}
          data={tab}
          index={index}
          key={tab.id}
          {...other}
        />
      ))}
      <Tabs value={index}
        variant="fullWidth"
        onChange={onChange}
        classes={{
          indicator:classes.tabs,
          flexContainer:classes.flexContainer
        }}
        aria-label="inner tabs">
        {data.children && data.children.map(tab => (
          <Tab
            classes={{
              root:classes.tab,
              wrapper: classes.tabWrapper,
              selected:classes.tabSelected
            }}
            key={tab.id}
            label={tab.name}
            id={`inner-tabs-${tab.id}`}
            aria-controls={`inner-tabpanel-${tab.id}`}
          />
        ))}
      </Tabs>
    </div>
    </div>
  )
}

const TabsBlock = (props) => {
  const { data } = props
  const [index, setIndex] = useState(0)
  const [paused, setPaused] = useState(true)
  const styles = props.data.styles ? props.data.styles : {}
  const handleChange = (event, newValue) => {
    setIndex(newValue)
    setPaused(true)
  }

  /**
   * 样式主题风格
   */
  let theme = "default"
  if (styles.theme) {
    theme = styles.theme
  }

  // 通用props
  const innerProps = {
    data,
    index,
    paused,
    onChange:handleChange,
    onVideoPlay:()=>setPaused(false),
    onVideoPause:()=>setPaused(true)}

  if (theme === 'redone') return <Redone {...innerProps} />
  if (theme === 'redone_top') return <RedoneTop {...innerProps} />
  if (theme === 'redone_left') return <RedoneLeft {...innerProps} />
  return <Default {...innerProps} />
}

export default TabsBlock
