import React from 'react'
import Layout from '../components/Layout'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import CueLineChart from '../components/CueLineChart'
import SummaryCard from '../components/SummaryCard'
import CueSummaryCard from '../components/CueSummaryCard'
import PlanSummaryCard from '../components/PlanSummaryCard'
import ThemeSummaryCard from '../components/ThemeSummaryCard'
import DealerSummaryCard from '../components/DealerSummaryCard'
import CueTable from '../components/CueTable'
import MediumRank from '../components/MediumRank'
import { makeStyles } from '@material-ui/core/styles'
import Filtrate from '../components/Filtrate'

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  }
}))

const Home = () => {
  const classes = useStyles()
  return (
    <Layout>
      {/* <Typography
        gutterBottom
        variant="h5"
        component="h2">仪表盘</Typography> */}
      <Filtrate title='仪表盘'></Filtrate>
      <Grid container spacing={2} className={classes.root}>
        <Grid item xs={12} md={8}>
          <CueLineChart />
        </Grid>
        <Grid container item xs={12} md={4} spacing={1}>
          <Grid item xs={6} sm={3} md={6}>
            <CueSummaryCard />
          </Grid>
          <Grid item xs={6} sm={3} md={6}>
            <PlanSummaryCard />
          </Grid>
          <Grid item xs={6} sm={3} md={6}>
            <ThemeSummaryCard />
          </Grid>
          <Grid item xs={6} sm={3} md={6}>
            <DealerSummaryCard />
          </Grid>
        </Grid>
        <Grid item container alignItems='stretch' spacing={2}>
          <Grid item xs={12} md={6}>
            <MediumRank />
          </Grid>
          <Grid item xs={12} md={6}>
            <CueTable />
          </Grid>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Home
