import moment from "moment"
import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import TablePagination from '@material-ui/core/TablePagination'
import TableContainer from '@material-ui/core/TableContainer'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import { DatePicker } from '@material-ui/pickers'

import InputBase from '@material-ui/core/InputBase'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/styles'

import { Link, useRouteMatch } from 'react-router-dom'
import { fetchPlanList } from '../api'

import Layout from '../components/Layout'
import PlanCreate from './PlanCreate'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    marginBottom:15
  },
  filter: {
    display: 'flex',
    alignItems: 'center'
  },
  input: {
    borderWidth: 1,
    borderRadius: '5px',
    margin: '0 12px 6px 0',
    padding: '1px 12px'
  },
  search: {
    backgroundColor: '#3859FF',
    borderColor: '#3859FF',
    color: '#FEFEFF',
    width: '280px',
    maxWidth: '100%'
  },
  month: {
    backgroundColor: '#D5F3EB',
    borderColor: '#D5F3EB',
    color: '#00BA83'
  },
  classify: {
    backgroundColor: '#E9ECFC',
    borderColor: '#E9ECFC',
    color: '#5D78FF'
  },
  button: {
    borderWidth: 1,
    padding: '4px 12px'
  }
}))

/**
const useStyles = makeStyles(theme => ({
selectstatus: {
  height: 30,
  margin: 5,
  marginRight: 15,
  marginLeft: 0,
  borderRadius: 10,
  backgroundColor: '#D5F3EB',
  color: '#00BA83',
  fontSize: 14,
  lineHeight: 1,
  '& .MuiSelect-root': {
    paddingRight: 25,
    paddingLeft: 10,
  },
  '& .MuiSvgIcon-root':{
    color:'#00BA83'
  }
}
}))

const Screening = ({ setMouth }) => {
const classes = useStyles()
const onChange = (e, v) => {
  const value = `&month=${v.props.value}`
  setMouth(value)
}
const monthList = [
  { id: '201906' },
  { id: '201907' },
  { id: '201908' },
  { id: '201909' },
  { id: '201910' },
  { id: '201911' },
  { id: '201912' },
  { id: '202001' },
  { id: '202002' },
  { id: '202003' },
]
return (
  <Select
    className={classes.selectstatus}
    disableUnderline
    onChange={onChange}
    defaultValue='0'
    IconComponent={() => <svg
      style={{margin:4,marginRight:0}}
      class="MuiSvgIcon-root MuiSelect-icon"
      focusable="false"
      viewBox="0 0 1500 1500"
      aria-hidden="true"
      role="presentation">
      <path d="M560.44430222 794.9767111c-13.66698667 13.66698667-32.21617778 26.35889778-49.78801777 22.45290668-17.57184 2.92864-34.16746667-10.73834667-47.83559112-22.45290668L40.10666667 370.31253333c-21.47669333-21.47783111-21.47669333-56.62264889 0-78.10048s56.62264889-21.47669333 78.09934222 0L511.63249778 696.37688889 906.03406222 292.21205333c21.47783111-21.47669333 56.62151111-21.47669333 78.09934223 0s21.47669333 56.62264889 0 78.09934222l-423.68910223 424.66417778z"></path>
    </svg>}
    name={'status'}>
    <MenuItem value={'0'}>月份</MenuItem>
    {monthList.map((item, index) => (
      <MenuItem key={item.id} value={item.id}>{item.id}</MenuItem>
    ))}
  </Select>
)
}
 **/

const PlanList = () => {
  const [data, setData] = useState({ count: 0, results: [] })
  const [query, setQuery] = useState({})
  const [filter, setFilter] = useState('')
  const [page, setPage] = useState(0)
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  let { url } = useRouteMatch()
  let limit = 10

  const monthes = ['201906', '201907', '201908', '201909', '201910', '201911', '201912', '202001', '202002', '202003', '202004', '202005', '202006']

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchPlanList(page, limit, filter)
        setData(res)
      } catch (error) {
        console.error(error)
      }
    }
    fetchData()
  }, [page, limit, filter])

  const onChangePage = (event, newpage) => {
    setPage(newpage)
  }

  const handleChange = (event, name = null, value=null) => {
    if (!name) {
      name = event.target.name
    }
      if(!value) {
        value = event.target.value
      }
    setQuery({ ...query, [name]: value })
  }

  const handleFilter = () => {
    const keys = Object.keys(query)
    let queryString = ''
    let i
    for (i = 0; i < keys.length; i++) {
      if (query[keys[i]] !== undefined && query[keys[i]] !== '') {
        queryString += keys[i] + '=' + query[keys[i]] + '&'
      }
    }
    setFilter(queryString)
  }

  const breadcrumbs = [
    { name: '投放计划' },
  ]

  return (
    <Layout breadcrumbs={breadcrumbs}>
      <Box display="flex" mb={2} justifyContent="space-between">
        <Typography variant="h6" noWrap component="h2">投放计划</Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(true)}
        >添加投放计划</Button>
      </Box>
      <Box mb={2}>
        <InputBase
          name="search"
          className={`${classes.input} ${classes.search}`}
          placeholder="请输入编号, 媒体名称"
          value={query.search}
          onChange={handleChange}
        />
        <DatePicker
            emptyLabel="投放月份"
            TextFieldComponent={InputBase}
            className={`${classes.input} ${classes.month}`}
            views={["year", "month"]}
            format="YYYY年MM月"
            value={query.month ? moment(query.month, "YYYY-MM") : null}
            onChange={date=>handleChange(null, "month", date.format("YYYY-MM"))}
            cancelLabel="取消"
            okLabel="确定"
        />
        {/*<InputBase
          name="month"
          type="month"
          className={`${classes.input} ${classes.month}`}
          placeholder="请选择时间"
          value={query.month}
          onChange={handleChange}
        />*/}
        {/*<Select
          displayEmpty
          value={query.month}
          onChange={(event) => handleChange(event, "month")}
          input={<InputBase className={`${classes.input} ${classes.month}`} />}>
          <MenuItem>全部日期</MenuItem>
          {monthes.reverse().map(month => (
            <MenuItem key={month} value={month}>{month}</MenuItem>
          ))}
          </Select>*/}
        <Select
          displayEmpty
          value={query.is_zone}
          onChange={(event) => handleChange(event, "is_zone")}
          input={<InputBase className={`${classes.input} ${classes.classify}`} />}>
          <MenuItem>全部类型</MenuItem>
          <MenuItem value="1">区域计划</MenuItem>
          <MenuItem value="0">总部计划</MenuItem>
        </Select>
        <Button
          variant="outlined"
          size='small'
          className={`${classes.button}`}
          variant="outlined"
          color="primary"
          style={{ marginRight: '6px' }}
          onClick={handleFilter}
        >查询</Button>
        <Button
          variant="outlined"
          size='small'
          variant="outlined"
          color="primary"
          className={`${classes.button}`}
          onClick={() => window.location.reload()}
        >重置</Button>
      </Box>
      <TableContainer className={classes.root}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>计划ID</TableCell>
              <TableCell>核销编号</TableCell>
              <TableCell>媒体</TableCell>
              <TableCell>类型</TableCell>
              <TableCell>点位数量</TableCell>
              <TableCell>开始时间</TableCell>
              <TableCell align="center">操作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.results.map(row => (
              <TableRow key={row.id}>
                <TableCell>
                  <Link
                    style={{textDecoration:'none'}}
                    to={`${url}/${row.id}`}
                  >{row.number}</Link></TableCell>
                <TableCell>{row.extra && row.extra.inner ? row.extra.inner : '------'}</TableCell>
                <TableCell>{row.medium}</TableCell>
                <TableCell>
                  {row.is_zone ? '区域计划' : '总部计划'}</TableCell>
                <TableCell>{row.item_count}</TableCell>
                <TableCell>{row.start}</TableCell>
                <TableCell align="center">
                  <Button
                    color="primary"
                    size="small"
                    component={Link}
                    to={`${url}/${row.id}`}
                  >点位计划</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={data.count}
          rowsPerPage={10}
          page={page}
          onChangePage={onChangePage}
        />
      </TableContainer>
      <PlanCreate open={open} setOpen={setOpen} />
    </Layout>
  )
}

export default PlanList
