import React from 'react'
import { Editor } from '@tinymce/tinymce-react'

const mceConfig = {
  height: 200,
  menubar: false,
  inline: true
}

const TextBlock = (props) => {
  const { edit, data, onSave } = props
  const handleChange = event => {
    onSave({
      ...data,
      content: event.target.getContent()
    })
  }
  if(!edit) {
    return (
      <div
        dangerouslySetInnerHTML={{__html:data.content}}
      />
    )
  }
  return (
    <Editor
      init={mceConfig}
      initialValue={data.content}
      onChange={handleChange}
      toolbar="formatselect | align | bold italic | forecolor backcolor"
    />
  )
}

export default TextBlock
