import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import AppBar from '@material-ui/core/AppBar'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#242333',
  },
  inner: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  }
}))

const Topbar = (props) => {
  const classes = useStyles()
  return (
    <AppBar
      position="static"
      className={classes.root}>
      <Container
        className={classes.inner}
        maxWidth={props.maxWidth ? props.maxWidth : "lg"}>
        <Grid container
          alignItems="center"
          justify="space-between">
          <Grid item xs={12} sm>
            <Typography
              variant="h6"
              style={{ opacity: 0.6 }}
              component="h6">
              {process.env.REACT_APP_TITLE}</Typography>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Link
              variant="body2"
              rel="noopener"
              color="inherit"
              style={{ opacity: 0.6 }}
              href="http://www.beian.miit.gov.cn"
              target="_blank">
              {process.env.REACT_APP_COPYRIGHT}
            </Link>
          </Grid>
        </Grid>
      </Container>
    </AppBar>
  )
}

export default Topbar
