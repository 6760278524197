import React from 'react'
import Topbar from './Topbar'
import Footer from './Footer'
import { Link as BaseLink } from 'react-router-dom'
import { Typography, Breadcrumbs, Link, Container } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    display:'flex',
    flexDirection:'column',
    minHeight:'100vh',
    backgroundColor: theme.palette.background.default,
  },
  breadcrumbs: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(1)
  }
}))

const Layout = (props) => {
  const classes = useStyles()
  const { maxWidth, breadcrumbs } = props

  return (
    <React.Fragment>
      <div className={classes.root}>
        <Topbar maxWidth={maxWidth ? maxWidth : "lg"} />
        <Container maxWidth={maxWidth ? maxWidth: "lg"} style={{ flex: 1}}>
          <Breadcrumbs className={classes.breadcrumbs}>
            <Link color="inherit" component={BaseLink} to="/">首页</Link>
            {breadcrumbs && breadcrumbs.map((item,index) =>
              item.link ? (
                <Link
                  key={index}
                  color="inherit"
                  component={BaseLink}
                  to={item.link}
                >{item.name}</Link>
              ) : (
                <Typography
                  key={index}
                  color="textPrimary"
                >{item.name}</Typography>
              )
            )}
          </Breadcrumbs>
          {props.children}
        </Container>
        <Footer maxWidth={maxWidth ? maxWidth : "lg"} />
      </div>
    </React.Fragment>
  )
}

export default Layout
