import React, { useState, useEffect } from 'react'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { fetchDealerLogList } from '../api'
import moment from 'moment-timezone'
import { Box } from '@material-ui/core'

const DealerLogs = (props) => {
  const { id } = props
  const [data, setData] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      const res = await fetchDealerLogList(0, 50, 'store=' + id)
      console.log('-----', res);
      setData(res.results)
    }
    fetchData()
  }, [id])

  if (!data.length) {
    return (
      <Box fontSize={16} width="100%" align="center" py={2} color="darkgrey">暂无数据</Box>
    )
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>名称</TableCell>
          <TableCell>时间</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(row => (
          <TableRow key={row.id}>
            <TableCell>{row.name}</TableCell>
            <TableCell>{moment(row.created).tz('Asia/Shanghai').format('YYYY/MM/DD HH:mm')}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

export default DealerLogs
