import React, { useState, useEffect } from 'react'
import { Typography, Grid, Paper, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from '@material-ui/core'
import { DealerForm } from '../components/DealerForm'
import { useParams, useHistory } from 'react-router-dom'
import { fetchDealerDetail, fetchDealerUpdate, fetchDealerDelete } from '../api'
import DeleteButton from '../components/DeleteButton'
import Filtrate from '../components/Filtrate'
import DealerLogs from './DealerLogs'

const renderActive = () => (
  <Paper>
    <Grid>
      <ExpansionPanel square defaultExpanded={true}>
        <ExpansionPanelSummary>
          <Typography>活动信息</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid>
            <Typography>2019.06.006 12:30</Typography>
            <Typography>标记线索失败</Typography>
          </Grid>
          <Grid></Grid>
          <Grid></Grid>
          <Grid></Grid>
          <Grid></Grid>
          <Grid></Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </Grid>
  </Paper>
)
const RenderBtn = () => {
  const { id } = useParams()
  const history = useHistory()

  const deleteForm = async () => {
    try {
      await fetchDealerDelete(id)
      history.push('/dealers')
    } catch {
      alert('操作失败')
    }
  }
  return (
    <Filtrate title='经销商管理' otherSub={<>
      <DeleteButton
        variant="outlined"
        style={{
          margin: 5,
        }}
        onClick={deleteForm}>
        删除
    </DeleteButton></>} sumbitText='保存'></Filtrate>
  )
}

const DealerUpdate = () => {
  const [oldData, setData] = useState(null)
  const { id } = useParams()
  const [city, setCity] = useState(null)
  const history = useHistory()
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchDealerDetail(id)
        setData(res)
      } catch (error) {
        console.log('error', error)
      }
    }
    fetchData()
  }, [id])

  const onChange = (e, v) => {
    setCity(v)
  }
  const handleSubmit = async (data) => {
    let form = {
      ...data,
      city:city?city:oldData.city,
      sort:data.sort?data.sort:0,
      extra:{
        contact1:data.contact1,
        contact2:data.contact2,
        contact3:data.contact3,
      }
    }
    delete form.user
    delete form.contact1
    delete form.contact2
    delete form.contact3
    try {
      await fetchDealerUpdate(id, form)
      history.push('/dealers')
    } catch (error) {
      alert(error)
      console.error(error)
    }
  }
  return (
    <Grid title=''>
      {oldData && <DealerForm initData={oldData} formActions={<RenderBtn />} onSubmit={handleSubmit} onChangeText={onChange} />}
      {false ? renderActive() : null}

			<ExpansionPanel expanded={true} elevation={0} style={{marginBottom:16}}>
				<ExpansionPanelSummary>
					<Typography style={{ fontSize: 18 }}>操作日志</Typography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails>
          <DealerLogs id={id} />
        </ExpansionPanelDetails>
      </ExpansionPanel>

    </Grid>
  )
}

export default DealerUpdate
