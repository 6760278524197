import React from 'react'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: -30,
    borderRadius: 10,
    padding: 4,
    backgroundColor: '#efefef',
    transform: 'translate(0, -50%)',
  }
}))

const SortBar = (props) => {
  const { onMove } = props
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div onClick={()=>onMove('up')}>^</div>
      <div onClick={()=>onMove('down')}>v</div>
    </div>
  )
}

export default SortBar
