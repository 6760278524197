import React from 'react'
import { TextField, Grid, makeStyles, Card, Typography } from '@material-ui/core'
import { useForm } from 'react-hook-form'
const useStyles = makeStyles(theme => ({
  card: {
    marginBottom: 20
  },
  head: {
    height: 60,
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #EBEEF5'
  },
  title: {
    fontSize: 18,
    lineHeight: 3.2,
    paddingLeft: 20,
    flex: 1
  },
  rightBtn: {
    height: 20,
    width: 20,
    fontSize: 15,
    lineHeight: 1.26,
    backgroundColor: theme.palette.orange.light,
    color: theme.palette.orange.dark,
    cursor: 'pointer',
    textAlign: 'center',
    borderRadius: 10,
    margin: 20
  },
  mainBox: {
    padding: '20px 0'
  }
}))
const Inputs = ({ type, types, name, xs, helperText, initData, disabled = false, register = () => { }, errors = [],required }) => {
  return (
    <Grid item xs={xs[1]} sm={xs[0]} style={{
      padding: 20,
      paddingTop: 0
    }}>
      <TextField
        name={type}
        size='small'
        type={types}
        defaultValue={initData ? initData[type] : ''}
        variant="outlined"
        label={name}
        fullWidth
        required={required}
        disabled={disabled}
        inputRef={register}
        error={Boolean(errors[type])}
        helperText={errors[type] ? errors[type].message : helperText} />
    </Grid>
  )
}
const MediumForm = (props) => {
  const { initData, onSubmit, formActions } = props
  const { register, errors, handleSubmit } = useForm()
  const classes = useStyles()
  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      {formActions}
      <Card className={classes.card} elevation={0}>
        <Grid container className={classes.head}>
          <Grid item xs={3}>
            <Typography className={classes.title}>基本信息</Typography>
          </Grid>
        </Grid>
        <Grid container className={classes.mainBox}>
          <Grid container>
            <Inputs name='名称' required type='name' xs={[4, 12]} initData={initData} register={register} errors={errors}></Inputs>
            <Inputs name='编码' required type='code' xs={[4, 12]} helperText={'请输入不重复的两位数英文字符'} initData={initData} register={register} errors={errors}></Inputs>
            <Inputs name='排序' required type='sort' types='number' xs={[4, 12]} initData={initData} register={register} errors={errors}></Inputs>
          </Grid>
        </Grid>
      </Card>
    </form>
  )
}

export default MediumForm
