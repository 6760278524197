import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'

import { fetchMediumPositionList, fetchMediumItemList } from '../api'

const MediumItemSelectField = (props) => {
  const { medium, value, onChange } = props
  const [position, setPosition] = useState(0)
  const [positions, setPositions] = useState([])
  const [items, setItems] = useState([])

  useEffect(() => {
    setPosition(props.page)
  }, [props.page])

  useEffect(() => {
    const fetchData = async() => {
      const query = `medium__name=${medium}`
      try {
        const res = await fetchMediumPositionList(query)
        setPositions(res)
      } catch {}
    }
    fetchData()
  }, [medium])

  useEffect(() => {
    const fetchData = async() => {
      try {
        const res = await fetchMediumItemList(position)
        setItems(res)
      } catch {}
    }
    if(position) {
      fetchData()
    }
  }, [position])

  const handleChange = event => {
    const value = event.target.value
    setPosition(value)
  }


  if(positions.length <= 0) {
    return null
  }

  return (
    <Box mb={2}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            fullWidth
            select
            label="广告位置*"
            value={position}
            onChange={handleChange}
          >
            {positions.map(row => (
              <MenuItem key={row.id} value={row.id}>
                {row.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {position !== 0 && (
        <Grid item xs={6}>
          <TextField
            fullWidth
            select
            label="广告形式*"
            value={value}
            onChange={(e)=>onChange(e, 'position')}
          >
            {items.map(row => (
              <MenuItem key={row.id} value={row.id}>
                {row.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default MediumItemSelectField
