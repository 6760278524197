/**
 * 图片上传
 * 2020.02.27
 */
import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import ButtonBase from '@material-ui/core/ButtonBase'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/styles'
import { fetchAttach } from '../api'

const useStyles = makeStyles(theme => ({
  uploadButton: {
    display: 'flex',
    width: '100%',
    height: '180px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.grey[100],
    fontSize: 16
  },
  preview: {
    textAlign: 'center',
    '& > img': {
      maxHeight: 180,
      maxWidth: '100%'
    }
  }
}))

const ImagePicker = (props) => {
  const { value, onChange } = props
  const [open, setOpen] = useState(false)
  const [data, setData] = useState('')
  const [preview, setPreview] = useState()
  const classes = useStyles()

  useEffect(() => {
    setData(value ? value : '')
    setPreview(value ? value : null)
  }, [value])

  const handleClose = () => setOpen(false)
  const handleDelete = () => {
    setData("")
    onChange("")
    setOpen(false)
  }
  const handleUpload = event => {
    if(event.target.files && event.target.files[0]) {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = async function(e) {
        setPreview(e.target.result)
        const res = await fetchAttach({source:e.target.result})
        setData(res.source)
      }
      reader.readAsDataURL(file)
    }
  }
  const handleSubmit = () => {
    setOpen(false)
    onChange(data)
  }
  return (
    <>
      <Button
        size="small"
        variant="outlined"
        onClick={()=>setOpen(true)}>选择图片</Button>
      <Dialog
        fullWidth
        maxWidth="sm"
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>选择图片</DialogTitle>
        <DialogContent>
          <input
            type="file"
            accept="image/*"
            onChange={handleUpload}
            id="image-picker-upload"
            style={{display:'none'}}
          />
          <label htmlFor="image-picker-upload">
            {preview ? (
              <div className={classes.preview}>
                <img src={preview} alt="image-preview" />
              </div>
            ) : (
              <ButtonBase
                component="div"
                className={classes.uploadButton}>点击上传</ButtonBase>
            )}
          </label>
          <TextField
            fullWidth
            multiline
            rows={3}
            type="url"
            label="或输入图片地址"
            value={data}
            style={{marginTop:16}}
            helperText="图片大小不得超过2MB"
            onChange={(event)=>setData(event.target.value)} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>取消</Button>
          <Button color="secondary" onClick={handleDelete}>删除</Button>
          <Button color="primary" onClick={handleSubmit}>确定</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ImagePicker
