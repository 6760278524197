import { createMuiTheme } from '@material-ui/core/styles'
const theme = createMuiTheme({
  width: "lg",
  palette:{
    type: 'light',
    primary:{
      light:'#5F7AFF',
      main:'#3859FF',
      dark:'#273EB2'
    },
    secondary:{
      light:'#FF6296',
      main:'#FF3B7C',
      dark:'#B22956'
    },
    purple:{
      light:'#927CFF',
      main:'#775CFF',
      dark:'#5340B2'
    },
    green:{
      light:'#3BC89F',
      main:'#0ABB87',
      dark:'#007825'
    },
    blue:{
      light:'#4FAFFF',
      main:'#239CFF',
      dark:'#186DB2',
      back:'#D5ECFF'
    },
    orange:{
      light:'#FF9763',
      main:'#FF7E3D',
      dark:'#B2582A'
    },
    // purple:{
    //   light:'#FFC64E',
    //   main:'#FFB822',
    //   dark:'#B28017'
    // },
    dark:{
      light:'#46464F',
      main:'#181824',
      dark:'#101019'
    },
    background:{
      paper: '#fff',
      default:'#f5f5fa'
    },
  },
  typography:{
    fontSize:12,
    // h2:{
    //   fontSize:'2.5rem'
    // },
    // h3:{
    //   fontSize:'2rem'
    // },
    // h4:{
    //   fontSize:'1.75rem'
    // },
    // h5:{
    //   fontSize:'1.5rem'
    // },
    // h6:{
    //   fontSize:'1.25rem'
    // },
  }
});

export default theme
