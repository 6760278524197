import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import QRCode from 'qrcode.react'
import { Link } from 'react-router-dom'

const PlanItemQrcode = (props) => {
  const { data, open, onClose } = props
  let link = ''
  if(data && data.link) {
    link = `${window.origin}${data.link}`
  }

	const handleDownload =()=>{
		var Qr=document.getElementById('qrcode');
		let image = new Image();
		image.src = Qr.toDataURL("image/png");
		let a = document.createElement('a')
    let event = new MouseEvent('click')
    a.download = 'qrcode'
    a.href = image.src

    a.dispatchEvent(event)
	}

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      PaperProps={{ elevation: 0 }}
      open={open}
      onClose={onClose}>
      <DialogTitle>预览</DialogTitle>
      <DialogContent style={{textAlign:'center'}}>
        {data && <QRCode id="qrcode" value={link} level="L" size={256} />}
        <Typography component="p" variant="body2">{link}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>取消</Button>
        <Button
          onClick={handleDownload}
        >下载二维码</Button>
        <Button
          component={Link}
          disabled={!data}
          to={data ? data.link : ''}
        >访问链接</Button>
      </DialogActions>
    </Dialog>
  )
}

export default PlanItemQrcode
