import React from 'react'
import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'

const LayoutEdit = (props) => {
  const { data, onSave, onRemove } = props

  const handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    onSave({...data, [name]:value})
  }

  return (
    <div>
      <ExpansionPanel defaultExpanded>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="layout-edit-base"
          id="layout-edit-base"
        >
          <Typography>基础信息</Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Box flex={1}>
            <Box mb={1}>
              <FormControl component="fieldset">
                <FormLabel component="legend">宽度</FormLabel>
                <RadioGroup
                  aria-label="width"
                  name="width"
                  value={data.width}
                  onChange={handleChange}>
                  <FormControlLabel value="fullwidth" control={<Radio />} label="全宽" />
                  <FormControlLabel value="default" control={<Radio />} label="标准" />
                </RadioGroup>
              </FormControl>
            </Box>
            <Divider />
            <Box mt={2}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={onRemove}>删除区块</Button>
            </Box>
          </Box>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}

export default LayoutEdit
