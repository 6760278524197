import React from 'react'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/styles'
import ImagePicker from './ImagePicker'

const useStyles = makeStyles(theme => ({
  tab: {
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(1),
    borderBottom: '1px dashed lightgrey'
  },
  actions: {
    marginTop: theme.spacing(2),
    '& *': {
      marginRight: theme.spacing(1)
    }
  }
}))

const CarouselBlockEdit = (props) => {
  const classes = useStyles()
  const { data, onSave } = props

  const handleCreate = event => {
    let children = [...data.children]
    children.push({'id':children.length, 'content':'https://verseotheweek.files.wordpress.com/2015/09/empty_by_luclamxu.jpg'})
    onSave({...data, children})
  }

  const handleChange = (event, id, name=null, value=null) => {
    let children = [...data.children]
    if(name === null) {
      value = event.target.name
    }
    if(value === null) {
      value = event.target.value
    }
    children = children.map(item => {
      if(item.id === id) {
        return {...item, [name]:value}
      } else {
        return item
      }
    })
    console.log(children)
    onSave({...data, children})
  }

  const handleRemove = id => {
    const children = data.children.filter(item => item.id !== id)
    onSave({...data, children})
  }

  return (
    <div>
      {data.children && data.children.map(item => (
        <div key={item.id} className={classes.tab}>
          <img src={item.content} alt={item.id} width="100%" />
          <ImagePicker
            value={item.content}
            onChange={(value)=>handleChange(null, item.id, "content", value)} />
          <Button
            variant="contained"
            margin="normal"
            color="secondary"
            size="small"
            style={{marginLeft:8}}
            onClick={()=>handleRemove(item.id)}
          >删除</Button>
        </div>
      ))}
      {data.children && data.children.length < 3 && (
        <div className={classes.actions}>
          <Button
            variant="contained"
            size="small"
            onClick={handleCreate}
          >增加图片</Button>
        </div>
      )}
    </div>
  )
}

export default CarouselBlockEdit
