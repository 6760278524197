import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import CardContent from '@material-ui/core/CardContent'
import TablePagination from '@material-ui/core/TablePagination'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import InputBase from '@material-ui/core/InputBase'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/styles'
import Chip from '../components/Chip'
import { fetchFrameList } from '../api'

const useStyles = makeStyles(theme => ({
  input: {
    border: '1px solid rgba(0,0,0,0.23)',
    borderRadius: '5px',
    margin: '0 12px 6px 0',
    padding: '1px 12px',
  }
}))

const FrameGrid = (props) => {
  const [data, setData] = useState({count:0, results:[]})
  const [page, setPage] = useState(0)
  const [query,setQuery] = useState({})
  const [filter, setFilter] = useState('')
  const classes = useStyles()
  const limit = 12
  const { onClickItem, choice=false } = props

  useEffect(()=>{
    const fetchData = async() => {
      try {
        const res = await fetchFrameList(page, limit, filter)
        setData(res)
      } catch {}
    }
    fetchData()
  }, [page, filter])


  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChange = (event, name=null) => {
    if(name === null) {
      name = event.target.name
    }
    const value = event.target.value
    setQuery({...query, [name]:value})
  }

  const handleFilter = () => {
    const keys = Object.keys(query)
    let queryString = ''
    let i
    for(i=0; i<keys.length; i++) {
      if(query[keys[i]] !== undefined && query[keys[i]] !== '') {
        queryString += keys[i] + '=' + query[keys[i]] + '&'
      }
    }
    setFilter(queryString)
  }

  return (
    <React.Fragment>
      <Box mb={2}>
        <InputBase
          name="search"
          className={classes.input}
          style={{width:'280px',maxWidth:'100%'}}
          placeholder="请输入名称"
          value={query.search}
          onChange={handleChange}
        />
        <Select
          displayEmpty
          value={query.mode}
          onChange={(event)=>handleChange(event, "mode")}
          input={<InputBase className={`${classes.input} ${classes.classify}`} />}>
          <MenuItem>全部类型</MenuItem>
          <MenuItem value="pc">PC</MenuItem>
          <MenuItem value="phone">手机</MenuItem>
        </Select>
        <Button
          variant="outlined"
          style={{marginRight:'6px'}}
          onClick={handleFilter}
        >查询</Button>
        <Button
          variant="outlined"
          onClick={()=>window.location.reload()}
        >重置</Button>
      </Box>
    <Grid container spacing={2}>
      {choice && (
        <Grid item xs={6} sm={4} md={3}>
          <Card>
            <CardActionArea
              onClick={()=>onClickItem()}>
              <CardMedia
                style={{height:300}}
                image={require('../images/default_template.jpg')}
                title="empty" />
              <CardContent>
                <Typography variant="h6" component="h4">默认</Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  空白模板内容
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      )}
      {data.results.map(row => (
        <Grid item xs={6} sm={4} md={3} key={row.id}>
          <Card>
            <CardActionArea
              onClick={()=>onClickItem(row)}>
              <CardMedia
                style={{height:300}}
                image={row.cover ? row.cover : require('../images/default_template.jpg')}
                title={row.name}
              />
              <CardContent>
                <Box display="flex" alignItems="center">
                  {row.mode === 'pc' &&
                    <Chip color="primary" label="PC" />}
                  {row.mode === 'phone' &&
                    <Chip color="secondary" label="手机" />}
                  <Typography
                    variant="h6"
                    component="h4">{row.name}</Typography>
                </Box>

                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="p">
                  {row.description}
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </Grid>
    <TablePagination
      rowsPerPageOptions={[limit]}
      component="div"
      count={data.count}
      rowsPerPage={limit}
      page={page}
      onChangePage={handleChangePage}
    />
    </React.Fragment>
  )
}

export default FrameGrid
