import React from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import MediumSelectField from './MediumSelectField'
import PlanTypeField from './PlanTypeField'

const PlanForm = (props) => {
  const { data, onChange } = props

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label="编号"
          variant='outlined'
          size='small'
          name="number"
          value={data ? data.number : ''}
          disabled={true}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <MediumSelectField
          disabled
          size='small'
          fullWidth
          required
          variant='outlined'
          value={data ? data.medium : ''}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          disabled
          fullWidth
          variant='outlined'
          required
          size='small'
          label="开始时间"
          name="start"
          type="date"
          value={data ? data.start : ''}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12}>
        <PlanTypeField
          disabled
          value={data ? data.is_zone : "true"}
          onChange={onChange}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          label="核销编号"
          name="extra_inner"
          size='small'
          variant='outlined'
          value={data.extra ? data.extra.inner : ""}
          onChange={onChange}
        />
      </Grid>
    </Grid>
  )
}

export default PlanForm
